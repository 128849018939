export const EntityTypes = [
  'Alarm',
  'AlarmEvent',
  'AlarmHelper',
  'AlarmPushSubscription',
  'Asset',
  'AssetGroup',
  'Beacon',
  'ContactList',
  'Customer',
  'CustomerAlarmCenterRelation',
  'CustomerUserRelation',
  'Device',
  'Observation',
  'ObservationTrigger',
  'Overlay',
  'StaticResponder',
  'User',
  'Webhook',
  'Zone',
];
