import { IfCanAccess } from '@react-admin/ra-rbac';
import {
  BulkDeleteButton, Button, useGetList, useNotify, useRefresh, useTranslate,
} from 'react-admin';
import GroupsIcon from '@mui/icons-material/Groups';
import * as React from 'react';
import { useState } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  Dialog,
  DialogActions,
  DialogContent, DialogTitle,
  TextField as MuiTextField,
} from '@mui/material';
import { useListContext } from 'ra-core';
import { AssetGroup } from '@x-guard/xgac-types/xgac';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomBulkExportButton } from '../../utils/customExporter';
import { HasRoles } from '../HasRoles';
import { GroupActionAppendReplaceButton } from './AppUserListButtons';
import { groupAndConfigSetter, QrAction } from '../../lib/groupAndConfigSetter';
import { wait } from '../../lib/websockets/webSocketHelpers';

const AssetGroupSettingsDialog = () => {

  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<{ id: string; label: string }[]>([]);
  const [groupAction, setGroupAction] = useState<QrAction.append | QrAction.replace>(QrAction.append);
  const [groupResponseLoading, setGroupResponseLoading] = useState(false);
  const { selectedIds } = useListContext();
  const { data: assetGroupData } = useGetList('asset-groups', {
    pagination: { perPage: 100, page: 1 },
  });
  const groupChoices: { id: string; label: string }[] = assetGroupData ? assetGroupData?.map((item: AssetGroup) => ({ id: item._id, label: item.name })) : [];

  const handleClose = () => {

    setDialogOpen(false);
    setSelectedGroups([]);
    setGroupAction(QrAction.append);

  };

  const handleSettings = async () => {

    setGroupResponseLoading(true);
    await groupAndConfigSetter(
      selectedIds,
      groupAction,
      QrAction.skip,
      selectedGroups.map((group: any) => group.id),
      [],
    );
    await wait(100);
    setGroupResponseLoading(false);
    handleClose();
    notify('resources.assets.text.groups_updated', { type: 'success' });
    refresh();

  };

  return (
    <>
      <Button label={'menu.items.groups'} startIcon={<GroupsIcon/>} onClick={() => setDialogOpen(true)}/>
      <Dialog maxWidth="sm" fullWidth open={dialogOpen} onClose={handleClose}>
        {groupResponseLoading ? (
          <div style={{
            width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <DialogTitle>
              {translate('resources.assets.text.edit_groups')}
            </DialogTitle>
            <DialogContent>
              <MuiAutocomplete
                isOptionEqualToValue={(option, value) => option.id === value.id}
                size={'small'}
                multiple
                renderInput={(params) => <MuiTextField {...params}
                  label={translate('resources.app-users.text.group_title')}/>}
                options={groupChoices}
                value={selectedGroups}
                onChange={(event, newValue) => setSelectedGroups(newValue)}/>
              <GroupActionAppendReplaceButton label={translate('general.text.actions')} value={groupAction}
                setValue={setGroupAction} disableSkip={true}/>
            </DialogContent><DialogActions>
              <Button onClick={handleClose} label="ra.action.close"/>
              <Button onClick={handleSettings} label="ra.action.confirm"/>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );

};

export const AssetListBulkActionButtons = () => {

  return (
    <>
      <HasRoles anyOf={['admin']}>
        <AssetGroupSettingsDialog/>
      </HasRoles>
      <IfCanAccess action="export">
        <CustomBulkExportButton resource="assets"/>
      </IfCanAccess>

      <IfCanAccess action='delete'>
        <HasRoles anyOf={['admin']}>
          <BulkDeleteButton mutationMode='pessimistic'/>
        </HasRoles>
      </IfCanAccess>
    </>
  );

};
