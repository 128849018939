import {
  BooleanField,
  NumberField,
  TextField,
  BooleanInput,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  EditButton,
  ChipField,
  FormDataConsumer,
  AutocompleteArrayInput,
  useTranslate,
} from 'react-admin';
import {
  Datagrid, IfCanAccess,
} from '@react-admin/ra-rbac';
import { JsonInput } from 'react-admin-json-view';
import ReactJson from 'react-json-view';
import * as React from 'react';
import { Typography } from '@mui/material';
import _ from 'lodash';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ListLive } from '@react-admin/ra-realtime';
import { CustomToolbar } from '../../../components/CustomToolBar';
import SearchFields, { FilterResetter } from '../components/fields/Filters';
import TrueIcon from '../../../components/icons/TrueIcon';
import FalseIcon from '../../../components/icons/FalseIcon';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { exporter } from '../../../utils/customExporter';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import DateFieldNullable from '../../../components/fields/DateFieldNullable';
import { noTransformer, transformer } from '../../../lib/webhookExamples';
import { EntityTypes } from '../../../lib/constants/entityTypes';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { LIST_DEBOUNCE } from '../../../config';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

const Grid = Grid2;

const transform = (data: Record<string, any>) => {

  delete data.migrationKey;
  delete data.deliveryStats;
  delete data.enableLogs;
  return {
    ...data,
    webhookQuery: {
      ...data.webhookQuery,
      filter: ((data.webhookQuery.filter && Object.keys(data.webhookQuery.filter).length > 0) ? data.webhookQuery.filter : {}),
    },
    deliveryConfig: {
      ...data.deliveryConfig,
      headers: { ...data.deliveryConfig.headers },
    },
    transformer: data.transformer === 'null' ? null : data.transformer,
  };

};
const transformers = [
  { name: 'legacy', id: 'legacy' },
  { name: 'none', id: 'null' },
];

const DeliveryMethods = [
  { name: 'POST', id: 'POST' },
  { name: 'GET', id: 'GET' },
  { name: 'PUT', id: 'PUT' },
  { name: 'PATCH', id: 'PATCH' },
  { name: 'DELETE', id: 'DELETE' },
];

const DbOperators = [
  { name: 'create', id: 'create' },
  { name: 'update', id: 'update' },
  { name: 'delete', id: 'delete' },
];

export const WebhookList = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Stable}/>
    <ListLive filters={SearchFields} debounce={LIST_DEBOUNCE} exporter={exporter} title="resources.webhooks.text.title">
      <>
        <FilterResetter/>
        <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
          <BooleanField source="enableWebhook" TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>
          <TextField source="webhookQuery.operation"/>
          <ChipField source="webhookQuery.entityTypes"/>
          <TextFieldNullable source="webhookQuery.filter"/>
          <TextField source="deliveryConfig.method"/>
          <TextField source="deliveryConfig.url"/>
          <NumberField source="deliveryStats.numDeliverySucceeded"/>
          <NumberField source="deliveryStats.numDeliveryFailed"/>
          <DateFieldNullable source="deliveryStats.lastDeliveryDate" showTime={true}/>
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
          <IfCanAccess action="edit">
            <EditButton/>
          </IfCanAccess>
          <HasRoles anyOf={['developer_admin']}>
            <AuditlogButton/>
          </HasRoles>
        </Datagrid>
      </>
    </ListLive>
  </>
);

export const WebhookEdit = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta}/>
      <EditWithSuccess transform={transform} title="resources.webhooks.text.title">
        <SimpleForm toolbar={<CustomToolbar/>}>
          <Grid container spacing={2}>
            <Grid xs={8}>
              <Typography variant="h6" gutterBottom>
                {translate('resources.webhooks.text.entity')}
              </Typography>
              <SelectInput source="webhookQuery.operation" choices={DbOperators} validate={required()}
                defaultValue={'create'}/>
              <AutocompleteArrayInput source="webhookQuery.entityTypes"
                autoHighlight
                blurOnSelect={false}
                validate={required()}
                choices={EntityTypes.map((value) => {

                  return { id: value, name: value };

                })}/>
              <JsonInput source="webhookQuery.filter"
                label={translate('resources.webhooks.text.filter_explanation')}
                reactJsonOptions={{
                  name: null,
                  collapsed: false,
                  enableClipboard: false,
                  displayDataTypes: false,
                }}
              />
              <Typography variant="h6" gutterBottom>
                {translate('resources.webhooks.text.delivery')}
              </Typography>
              <SelectInput source="deliveryConfig.method" choices={DeliveryMethods} validate={required()}
                defaultValue={'POST'}/>
              <TextInput source="deliveryConfig.url" validate={required()} sx={{
                display: 'flex',
              }}/>
              <JsonInput source="deliveryConfig.headers"
                reactJsonOptions={{
                  name: null,
                  collapsed: false,
                  enableClipboard: false,
                  displayDataTypes: false,
                }}
              />
              <FormDataConsumer>
                {({ formData }) => (_.isEqual(formData.webhookQuery?.entityTypes, ['Alarm']))
                  && <div><SelectInput source="transformer" choices={transformers} validate={required()} defaultValue="null"/>
                  </div>
                }
              </FormDataConsumer>
              <Typography variant="h6" gutterBottom>
                {translate('resources.webhooks.fields.enableWebhook')}
              </Typography>
              <BooleanInput source="enableWebhook" defaultValue={true}/>
            </Grid>
            <Grid xs={4}>
              <FormDataConsumer>
                {({ formData }) => (_.isEqual(formData.webhookQuery?.entityTypes, ['Alarm'])) && <div>
                  <Typography variant="h6">{translate('resources.webhooks.text.output_preview')}</Typography>
                  <ReactJson src={formData.transformer === 'legacy' ? transformer : noTransformer} collapsed={2} style={{ fontSize: 15 }}/>
                </div>
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const WebhookCreate = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta}/>
      <CreateWithSuccess transform={transform} title="resources.webhooks.text.title">
        <SimpleForm>
          <Grid container spacing={2} width="100%">
            <Grid sm={8}>
              <Typography variant="h6" gutterBottom>
                {translate('resources.webhooks.text.entity')}
              </Typography>
              <SelectInput source="webhookQuery.operation" choices={DbOperators} validate={required()}
                defaultValue={'create'}/>
              <AutocompleteArrayInput source="webhookQuery.entityTypes"
                autoHighlight
                blurOnSelect={false}
                validate={required()}
                choices={EntityTypes.map((value) => {

                  return { id: value, name: value };

                })}/>
              <JsonInput source="webhookQuery.filter"
                label={translate('resources.webhooks.text.filter_explanation')}
                reactJsonOptions={{
                  name: null,
                  collapsed: false,
                  enableClipboard: false,
                  displayDataTypes: false,
                }}
              />
              <Typography variant="h6" gutterBottom>
                {translate('resources.webhooks.text.delivery')}
              </Typography>
              <SelectInput source="deliveryConfig.method" choices={DeliveryMethods} validate={required()}
                defaultValue={'POST'}/>
              <TextInput source="deliveryConfig.url" validate={required()} sx={{
                display: 'flex',
              }}/>
              <JsonInput source="deliveryConfig.headers"
                reactJsonOptions={{
                  name: null,
                  collapsed: false,
                  enableClipboard: false,
                  displayDataTypes: false,
                }}
              />
              <FormDataConsumer>
                {({ formData }) => (_.isEqual(formData.webhookQuery?.entityTypes, ['Alarm']))
                && <div><SelectInput source="transformer" choices={transformers} validate={required()} defaultValue="null"/>
                </div>
                }
              </FormDataConsumer>
              <Typography variant="h6" gutterBottom>
                {translate('resources.webhooks.text.enabling')}
              </Typography>
              <BooleanInput source="enableWebhook" defaultValue={true}/>
            </Grid>
            <Grid xs={4}>
              <FormDataConsumer>
                {({ formData }) => (_.isEqual(formData.webhookQuery?.entityTypes, ['Alarm'])) && <div>
                  <Typography variant="h6">{translate('resources.webhooks.text.output_preview')}</Typography>
                  <ReactJson src={formData.transformer === 'legacy' ? transformer : noTransformer} collapsed={2} style={{ fontSize: 15 }}/>
                </div>
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};
