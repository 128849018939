import { xgacRequest } from '../../utils/xgacRequest';

export const camelCase = (string:string) => {

  return string.replace(/-([a-z])/ig, (all, letter) => {

    return letter.toUpperCase();

  });

};
export const capitalizeFirstLetter = (string: string) => {

  return string.charAt(0).toUpperCase() + string.slice(1);

};

export const translateRaResourceToWSResource = (resource: string) => {

  switch (resource) {

  case 'app-users':
  case 'specials/kiosk-user':
  case 'all-assets':
  case 'specials/bhv-knop/device-list':
    return 'Asset';
  case 'devices/without-app':
    return 'Device';
  case 'users/without-app':
    return 'User';

  default:
    return capitalizeFirstLetter(camelCase(resource)).slice(0, -1);

  }

};

export const translateWSResourceToRaResource = (resource: string) => {

  let returnResource;
  switch (resource) {

  case 'Asset':
    returnResource = 'app-users';
    break;
  case 'Alarm':
    returnResource = 'alarms';
    break;
  case 'AssetGroup':
    returnResource = 'asset-groups';
    break;
  default:
    returnResource = `${resource.toLowerCase()}s`;
    break;

  }
  return `resource/${returnResource}`;

};

export const getFilterForResource = (resource: string) => {

  switch (resource) {

  case 'resource/app-users':
    return { 'app.mode': '/off|on|risk/' };
  default:
    return null;

  }

};

export const guidGenerator = () => {

  const S4 = () => {

    // eslint-disable-next-line no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

  };
  return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);

};

export const getXgacToken = () => {

  return (xgacRequest.defaults.headers.common.Authorization || '').toString().substring(7);

};

export const wait = async (ms: number) => {

  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));

};
