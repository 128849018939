import {
  CSSProperties, Dispatch, SetStateAction,
} from 'react';

export const IntegerInput = (props: {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  style?: CSSProperties;
  min?: number;
  max?: number;
}) => {

  const {
    value, setValue, min, max,
  } = props;

  const decreaseNumber = (e: any) => {

    e.preventDefault();
    e.stopPropagation();
    setValue((prevNumber) => {

      if (prevNumber <= (min || Number.MIN_SAFE_INTEGER)) return prevNumber;
      return prevNumber - 1;

    });

  };

  const increaseNumber = (e: any) => {

    e.preventDefault();
    e.stopPropagation();
    if (value >= (props.max || Number.MAX_SAFE_INTEGER)) return;
    setValue((prevNumber) => prevNumber + 1);

  };

  const handleInputChange = (event: any) => {

    const newValue = parseInt(event.target.value, 10);
    if (!Number.isNaN(newValue) && newValue <= (max || Number.MAX_SAFE_INTEGER)) {

      setValue(newValue);

    }

  };

  return (
    <div className="integer-input" style={{ ...props.style }}>
      <button onClick={decreaseNumber}>-</button>
      <input
        type="number"
        min={min}
        max={max}
        value={value}
        onChange={handleInputChange}
      />
      <button onClick={increaseNumber}>+</button>
    </div>
  );

};
