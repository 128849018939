import {
  TextField,
  BooleanInput,
  ReferenceInput,
  TextInput,
  EditButton,
  SimpleForm,
  ImageField,
  FormDataConsumer,
  required,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  useTranslate,
  useCreatePath,
  useRecordContext,
  email,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import {
  List,
  Datagrid,
  IfCanAccess,
  ListActions,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { MapContainer, Popup, TileLayer } from 'react-leaflet';
import { useEffect, useState } from 'react';
import { Map } from 'leaflet';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ReferenceOneInput } from '@react-admin/ra-relationships';
import { CustomToolbar } from '../../../components/CustomToolBar';
import { AssetFilters } from '../components/fields/Filters';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import { FullExportButton } from '../../../utils/customExporter';
import ReferenceFieldNullable from '../../../components/fields/ReferenceFieldNullable';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { LIST_DEBOUNCE } from '../../../config';
import { PositionForm } from '../../../components/baseForms/PositionForm';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import ReferenceArrayFieldNullable from '../../../components/fields/ReferenceArrayFieldNullable';
import { DownloadAssetHistoryButton } from '../../../components/buttons/DownloadAssetHistoryButton';
import { HasRoles } from '../../../components/HasRoles';
import { AssetListBulkActionButtons } from '../../../components/buttons/AssetListButtons';
import authProvider from '../../../utils/authProvider';
import { PageExplanation } from '../../../components/PageExplanation';
import { PhoneNumberInput } from '../../../components/inputs/PhoneNumberInput';
import { ImageInput } from '../../../components/inputs/ImageInput';
import DateFieldNullable from '../../../components/fields/DateFieldNullable';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

const Grid = Grid2;

const transform = (data: Record<string, any>) => {

  delete data.app;
  delete data.hasApp;
  delete data.share;
  delete data.insideZones;
  delete data.formattedAddress;
  delete data.lastObservationAt;

  if (data.description === null) {

    delete data.description;

  }

  if (data.assetGroups) {

    data.assetGroups = data.assetGroups.map((group: any) => {

      return {
        _id: group,
        _ref: 'AssetGroup',
      };

    });

  }
  if (data.position && data.position.coordinates) {

    if (data.position?.properties?.provider !== 'static') {

      data.position = {
        ...data.position,
        properties: {
          ...data.position.properties,
          provider: 'gps/none',
        },
      };

    } else {

      data.position = {
        ...data.position,
        type: 'Point',
        properties: {
          ...data.position.properties,
          provider: 'static',
          accuracy: 0,
          address: {
            ...data.position.properties.address,
            formattedAddress: [
              `${data.position.properties.address?.streetName || ''} ${data.position.properties.address?.streetNumber || ''}`.trim(),
              data.position.properties.address?.city || null,
              data.position.properties.address?.state || null,
              data.position.properties.address?.countryCode || null,
            ].filter((val) => (val && val.length > 0)).join(', ').trim(),
          },
          dateTime: new Date().toISOString(),
        },
      };

    }

    if (data.position.coordinates && (!data.position.coordinates[0] || !data.position.coordinates[1])) {

      data.position = null;

    }

  } else {

    data.position = null;

  }

  if (_.isEqual(data.position, { properties: { provider: 'gps/none' } }) || _.isEqual(data.position, {
    properties: {
      provider: 'gps/none',
    },
  })) {

    data.position = null;

  }

  return {
    ...data,
    ...(data.user?._id ? { user: { _ref: 'User', _id: data.user._id } } : { user: null }),
    staticResponders: data.staticResponders?.map((responder:any) => (
      {
        ...responder,
        staticResponder: { _id: responder.staticResponder, _ref: 'StaticResponder' },
        priority: responder.priority || null,
      }
    )),
  };

};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExternalIdField = (props: any) => {

  const isCustomerAdmin = authProvider.isCustomerAdmin();
  if (isCustomerAdmin) {

    return null;

  }
  return (
    <TextFieldNullable source="externalId"/>
  );

};

export const AssetList = () => {

  const createPath = useCreatePath();
  const adminPath = createPath({ resource: 'users/without-app', type: 'create' });
  const appUserPath = createPath({ resource: 'app-users', type: 'create' });
  const isCustomerAdmin = authProvider.isCustomerAdmin();
  return (
    <>
      <PageExplanation text={'resources.assets.text.without_app_description'} options={{ adminPath, appUserPath }}/>
      <List
        filters={AssetFilters}
        debounce={LIST_DEBOUNCE}
        title="resources.assets.text.without_app_title"
        actions={isCustomerAdmin ? <FullExportButton resource='assets'/> : <div style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}><ListActions exporter={false}/><FullExportButton resource='assets'/></div>}
      >
        <Datagrid rowClick="toggleSelection" bulkActionButtons={<AssetListBulkActionButtons/>}>
          <ReferenceFieldNullable source="user._id" reference="users" label="resources.assets.fields.user._id"/>
          <TextField source="name" label="general.fields.name"/>
          <ExternalIdField label="resources.assets.fields.externalId"/>
          <ReferenceArrayFieldNullable source="assetGroups" reference="asset-groups" label="resources.asset-groups.name"/>
          <DateFieldNullable source="lastObservationAt" label="resources.assets.fields.lastObservationAt" showTime/>
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
          <IfCanAccess action="edit">
            <HasRoles anyOf={['admin', 'customer_admin']}>
              <EditButton/>
            </HasRoles>
          </IfCanAccess>
          <HasRoles anyOf={['developer_admin']}>
            <AuditlogButton/>
          </HasRoles>
        </Datagrid>
      </List>
    </>
  );

};

const SimpleAssetEditFields = () => {

  const record = useRecordContext();
  const translate = useTranslate();
  if (!record) {

    return null;

  }
  return (
    <>
      <h4 style={{ marginBottom: '10px' }}>"{record.name}" {translate('general.text.settings')}</h4>
      <hr/>
      <Typography variant="h6" gutterBottom marginTop={'10px'}>
        {translate('resources.assets.text.properties')}
      </Typography>
      <Grid container spacing={3}>
        <Grid md={6}>
          <TextInput
            source="name"
            label="general.fields.name"
            fullWidth
            validate={required()}
          />
          <TextInput
            source="properties.email"
            label="resources.assets.fields.email"
            fullWidth
            validate={email()}
          />
        </Grid>
        <Grid md={6}>

          <ReferenceOneInput reference="users" target="_id" source="userId" fullWidth>
            <TextInput source="username" fullWidth label="resources.users.fields.username" disabled/>
          </ReferenceOneInput>
          <PhoneNumberInput source="properties.phoneNumber" fullWidth colorVariant="normal"/>
        </Grid>
        <Grid md={12}>
          <Typography variant="h6" gutterBottom>
            {translate('menu.items.groups')}
          </Typography>
          <ReferenceArrayInput source="assetGroups" reference="asset-groups" label="menu.items.groups">
            <AutocompleteArrayInput fullWidth label="menu.items.groups"/>
          </ReferenceArrayInput>
        </Grid>
      </Grid>

    </>
  );

};

export const AssetEdit = () => {

  const translate = useTranslate();
  const [map, setMap] = useState<Map | null>(null);

  useEffect(() => {

    map?.scrollWheelZoom.disable();
    map?.on('focus', () => {

      map.scrollWheelZoom.enable();

    });
    map?.on('blur', () => {

      map.scrollWheelZoom.disable();

    });

  }, [map]);

  const isCustomerAdmin = authProvider.isCustomerAdmin();
  if (isCustomerAdmin) {

    return (
      <EditWithSuccess transform={transform} title="resources.assets.text.without_app_title" actions={false}>
        <SimpleForm toolbar={<CustomToolbar noDelete/>}>
          <SimpleAssetEditFields/>
        </SimpleForm>
      </EditWithSuccess>
    );

  }

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable} />
      <EditWithSuccess transform={transform} title="resources.assets.text.without_app_title">
        <SimpleForm toolbar={<CustomToolbar/>}>
          <HasRoles anyOf={['admin']}>
            <DownloadAssetHistoryButton/>
          </HasRoles>
          <Typography variant="h6" gutterBottom>
            {translate('resources.assets.text.general')}
          </Typography>
          <ReferenceInput source="user._id" reference="users" sort={{ field: 'name', order: 'ASC' }}>
            <AutocompleteInput autoHighlight={true} label="resources.assets.fields.user._id" />
          </ReferenceInput>
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <TextInput source="externalId" label="resources.assets.fields.externalId"/>
          <TextInput source="description" label="resources.assets.fields.description"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.assets.text.properties')}
          </Typography>
          <PhoneNumberInput source="properties.phoneNumber" label="resources.assets.fields.phoneNumber"/>
          <PhoneNumberInput source="properties.smsNumber" label="resources.assets.fields.smsNumber"/>
          <TextInput source="properties.email" label="resources.assets.fields.email"/>
          <ImageInput source="properties.image" sx={{ width: '50%' }} label="resources.assets.fields.image" defaultValue={undefined}>
            <ImageField source="src" title="title"/>
          </ImageInput>
          <Typography variant="h6" gutterBottom>
            {translate('menu.items.groups')}
          </Typography>
          <ReferenceArrayInput source="assetGroups" reference="asset-groups" label="menu.items.groups"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.assets.fields.available')}
          </Typography>
          <BooleanInput source="available" label="resources.assets.fields.available"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.assets.text.response')}
          </Typography>
          <ArrayInput source="staticResponders" label="resources.assets.fields.staticResponders.name">
            <SimpleFormIterator disableReordering>
              <ReferenceInput name="static_responders" source="staticResponder" reference="static-responders">
                <AutocompleteInput validate={required()} autoHighlight={true} label="resources.assets.fields.staticResponders.staticResponder"/>
              </ReferenceInput>
              <NumberInput source="priority" label="resources.assets.fields.staticResponders.priority"/>
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput name="invisibleResponder" source="invisibleResponder" label="resources.assets.fields.invisibleResponder"/>
          <BooleanInput name="allowContactOnOwnAlarms" source="allowContactOnOwnAlarms"
            label="resources.assets.fields.allowContactOnOwnAlarms"/>
          <Typography variant="h6" gutterBottom>
            {translate('general.fields.position.name')}
          </Typography>
          <BooleanInput source="position.properties.provider"
            format={(v) => (v === 'static')}
            parse={(v) => (v ? 'static' : 'device')}
            label={translate('resources.assets.fields.position.provider')}
          />

          <Grid container spacing={2} width="100%">
            <Grid xs={6}>
              <FormDataConsumer>
                {({ formData }) => {

                  if (formData.position?.properties?.provider === 'static') return (<PositionForm source="position"/>);
                  if (formData.position) return (<PositionForm source="position" disabled={true}/>);
                  return null;

                }}
              </FormDataConsumer>
            </Grid>
            <Grid xs={6}>
              <FormDataConsumer>
                {({ formData }) => (
                  formData.position?.properties?.address?.formattedAddress
                && formData.position?.coordinates
                && formData.position?.coordinates[0]
                && formData.position?.coordinates[1]
                && formData.position?.coordinates[0] > -90 && formData.position?.coordinates[0] < 90
                && formData.position?.coordinates[1] > -90 && formData.position?.coordinates[1] < 90
                )
              && (
                <>
                  <MapContainer
                    ref={(ref: any) => setMap(ref)}
                    center={[formData.position.coordinates[1], formData.position.coordinates[0]]}
                    zoom={17}
                    minZoom={4}
                    zoomControl={false}
                    style={{ height: 500, width: '100%', marginTop: 8 }}
                    maxZoom={19}
                  >
                    <TileLayer
                      maxZoom={19}
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Popup position={[formData.position.coordinates[1], formData.position.coordinates[0]]}
                      closeButton={false}
                      closeOnClick={false}
                      autoClose={false}
                      closeOnEscapeKey={false}>
                      {formData.position.properties.address.formattedAddress}
                      <br/>
                      <a target="_blank" href={`https://www.google.com/maps/place/${formData.position.coordinates[1]},${formData.position.coordinates[0]}/@${formData.position.coordinates[1]},${formData.position.coordinates[0]},14z/`} rel="noreferrer">
                        {formData.position.coordinates[1]},{formData.position.coordinates[0]}
                      </a>
                    </Popup>
                  </MapContainer>
                </>
              )}
              </FormDataConsumer>
            </Grid>
          </Grid>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const AssetCreate = () => {

  const translate = useTranslate();
  return (<>
    <StabilityAlert stability={StabilityLevel.Stable} />
    <CreateWithSuccess transform={transform} title="resources.assets.text.without_app_title">
      <SimpleForm >
        <Typography variant="h6" gutterBottom>
          {translate('resources.assets.text.general')}
        </Typography>
        <ReferenceInput source="user._id" reference="users" sort={{ field: 'name', order: 'ASC' }} >
          <AutocompleteInput autoHighlight={true} label="resources.assets.fields.user._id"/>
        </ReferenceInput>
        <TextInput source="name" validate={required()} label="general.fields.name"/>
        <TextInput source="externalId" label="resources.assets.fields.externalId"/>
        <TextInput source="description" label="resources.assets.fields.description"/>
        <Typography variant="h6" gutterBottom>
          {translate('resources.assets.text.properties')}
        </Typography>
        <PhoneNumberInput source="properties.phoneNumber" label="resources.assets.fields.phoneNumber"/>
        <PhoneNumberInput source="properties.smsNumber" label="resources.assets.fields.smsNumber"/>
        <TextInput source="properties.email" label="resources.assets.fields.email"/>
        <ImageInput source="properties.image" sx={{ width: '50%' }} label="resources.assets.fields.image" defaultValue={undefined}>
          <ImageField source="src" title="title"/>
        </ImageInput>
        <Typography variant="h6" gutterBottom>
          {translate('resources.asset-groups.name')}
        </Typography>
        <ReferenceArrayInput source="assetGroups" reference="asset-groups" label="menu.items.groups"/>
        <Typography variant="h6" gutterBottom>
          {translate('resources.assets.fields.available')}
        </Typography>
        <BooleanInput source="available" label="resources.assets.fields.available"/>
        <Typography variant="h6" gutterBottom>
          {translate('resources.assets.text.response')}
        </Typography>
        <ArrayInput source="staticResponders" label="resources.assets.fields.staticResponders.name">
          <SimpleFormIterator disableReordering>
            <ReferenceInput name="static_responders" source="staticResponder" reference="static-responders">
              <AutocompleteInput validate={required()} autoHighlight={true} label="resources.assets.fields.staticResponders.staticResponder"/>
            </ReferenceInput>
            <NumberInput source="priority" label="resources.assets.fields.staticResponders.priority"/>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput name="invisibleResponder" source="invisibleResponder" label="resources.assets.fields.invisibleResponder"/>
        <BooleanInput name="allowContactOnOwnAlarms" source="allowContactOnOwnAlarms"
          label="resources.assets.fields.allowContactOnOwnAlarms"/>
        <Typography variant="h6" gutterBottom>
          {translate('general.fields.position.name')}
        </Typography>
        <BooleanInput source="position.properties.provider"
          format={(v) => (v === 'static')}
          parse={(v) => (v ? 'static' : 'device')}
          label={translate('resources.assets.fields.position.provider')}
        />
        <FormDataConsumer>
          {({ formData }) => (formData.position?.properties?.provider === 'static')
            && <PositionForm source="position"/>
          }
        </FormDataConsumer>
      </SimpleForm>
    </CreateWithSuccess>
  </>
  );

};
