// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button, DataProvider, useRecordContext } from 'react-admin';
import { fetchRelatedRecords, useRedirect, useTranslate } from 'ra-core';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { AssetTableInDialog } from '../AssetTableInDialog';
import { assetExporter } from '../../utils/customExporter';
import { xgacDataProvider } from '../../dataProviders/xgacDataProvider';

export const AssetsInDialogButton = (props: { label: string; hasExporter?: boolean }) => {

  const record = useRecordContext();
  const translate = useTranslate();
  const redirect = useRedirect();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<any[]>([]);
  if (!record) return (<></>);

  const openDialog = () => {

    setDialogOpen(true);
    setLoading(true);
    if (record.assets?.length > 0) {

      xgacDataProvider.getMany('all-assets', { ids: record.assets, meta: { sort: 'name' } }).then((returnedAssets) => {

        setAssets(returnedAssets.data);
        setLoading(false);

      });

    } else {

      setAssets([]);
      setLoading(false);

    }

  };

  const exportAssets = async () => {

    await assetExporter(assets, fetchRelatedRecords(xgacDataProvider as any as DataProvider));

  };

  return (<>
    <Button
      variant={'contained'}
      onClick={record?.assets?.length ? openDialog : () => redirect('edit', 'asset-groups', record.id)}
      label={translate('general.text.show_persons', { length: record?.assets?.length })}
    />
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="lg" fullWidth>
      <DialogTitle className="flex-in-between">
        <>
          {`${translate('resources.asset-groups.text.title_single')} ${record.name}`}
        </>
        <div>
          {props.hasExporter && (
            <Button
              label="ra.action.export"
              startIcon={<FileDownloadIcon/>}
              onClick={exportAssets}
              variant="contained"
              sx={{ marginRight: '10px' }}
            />
          )}
          <Button
            label="resources.asset-groups.text.add_person"
            startIcon={<AddIcon/>}
            onClick={() => redirect('edit', 'asset-groups', record.id)}
            variant="contained"
          />
        </div>
      </DialogTitle>
      <AssetTableInDialog assets={assets} loading={loading}/>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)} label={translate('general.text.close')}/>
      </DialogActions>
    </Dialog>
  </>);

};
