import { PropsWithChildren } from 'react';
import authProvider from '../utils/authProvider';

export const HasRoles = ({ children, anyOf }: PropsWithChildren & {
  anyOf: string[];
}) => {

  const roles = authProvider.getRoles();

  let proceed = false;
  for (const neededRole of anyOf) {

    if (roles.includes(neededRole)) {

      proceed = true;
      break;

    }

  }

  if (!proceed) {

    return null;

  }

  return (
    <div style={{
      width: '100%',
    }}>
      {children}
    </div>
  );

};
