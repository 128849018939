import {
  InputProps, Labeled, useInput, useRecordContext, useSaveContext,
} from 'react-admin';
import { useEffect, useState } from 'react';
import { useTranslate } from 'ra-core';
import { useDebounce } from 'use-debounce';
import { IntegerInput } from '../../apps/bhvk/components/inputs/integerInput';

export const RaIntegerInput = (props: InputProps & { needsSaving?: boolean; style?: any }) => {

  const { field } = useInput({ source: props.source, defaultValue: props.defaultValue });
  const [innerFieldValue, setInnerFieldValue] = useState(field.value || 0);
  const [debouncedValue] = useDebounce(innerFieldValue, 600);
  const translate = useTranslate();
  const record = useRecordContext();

  const { save } = useSaveContext();

  useEffect(() => {

    if (!props.needsSaving && innerFieldValue !== field.value) {

      field.onChange(debouncedValue);

    }

  }, [innerFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    field.onChange(debouncedValue);
    if (field.value === null || field.value === undefined || field.value === '') {

      return;

    }
    if (record && props.needsSaving && debouncedValue !== field.value) {

      if (save && record) {

        save({
          ...record,
          position: {
            ...record.position,
            properties: {
              ...record.position.properties,
              address: {
                ...record.position.properties.address,
                floor: innerFieldValue,
              },
            },
          },
        });

      }

    }

  }, [debouncedValue, field.value, record]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{
      marginLeft: '15px',
      ...props.style,
    }}>
      <Labeled
        label={['string', 'undefined'].includes(typeof props.label) ? translate(props.label as string || 'general.fields.position.address.floor') : props.label}
        color="black"
      >
        <IntegerInput value={innerFieldValue} setValue={setInnerFieldValue}/>
      </Labeled>
    </div>
  );

};
