import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  FileInputProps,
  FileInput,
  FileInputClasses,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import { useTheme } from '@mui/material';

export type ImageInputProps = FileInputProps & {
  disableRemoveIcon?: boolean;
};

const PREFIX = 'RaImageInput';

const StyledFileInput = styled(FileInput, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  [`& .${FileInputClasses.dropZone}`]: {
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    cursor: 'pointer',
    border: '1px dashed',
    textAlign: 'center',
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  [`& .${FileInputClasses.removeButton}`]: {
    paddingTop: theme.spacing(1),
    display: 'inline-block',
    position: 'relative',
    '& button': {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      minWidth: theme.spacing(2),
      opacity: 0,
    },
    '&:hover button': {
      opacity: 1,
    },
    '& .RaImageField-image': {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      margin: 0,
    },
  },
}));

export const ImageInput = (props: ImageInputProps) => {

  const translate = useTranslate();
  const theme = useTheme();

  return <StyledFileInput
    helperText={false}
    labelMultiple="ra.input.image.upload_several"
    labelSingle="ra.input.image.upload_single"
    removeIcon={() => <span style={{
      display: 'inline-block',
      fontSize: '12px',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: theme.palette.error.main,
      borderRadius: '5px',
      padding: '2px 8px',
    }}>{translate('ra.input.image.remove_current')}</span>}
    {...props}
  />;

};
