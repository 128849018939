import queryString from 'query-string';
import { GetListParams, Identifier } from 'react-admin';
import _ from 'lodash';
import {
  CreateParams, DeleteManyParams, DeleteParams, GetManyParams, GetOneParams, UpdateParams,
} from 'ra-core';
import { ATS_API_URL } from '../config';
import { httpClient } from '../utils/httpClient';
import { updateComparison } from './updateComparison';
import { getCurrentCustomer } from '../lib/currentCustomer';

const apiUrl = ATS_API_URL;

function translateResource(resource: string) {

  if (resource === 'customer-links') return 'customer-link';
  if (resource === 'process-templates') return 'process-template';
  if (resource === 'task-templates') return 'task-template';

  return resource;

}

export const atsDataProvider = {

  getList: async (resource: string, params: GetListParams) => {

    resource = translateResource(resource);
    if (!params.pagination) params.pagination = { page: 1, perPage: 10 };
    if (!params.sort) params.sort = { field: 'id', order: 'ASC' };
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filter = params.filter;
    const customer = JSON.parse(sessionStorage.getItem('currentCustomer') || '');

    const query = {
      sort: `${((order === 'DESC') ? '-' : '')}${field}`,
      offset: (page - 1) * perPage,
      limit: perPage,
      xgacCustomerId: customer.value,
      ...filter,
    };
    const url = `${apiUrl}/v2/manage/${resource}?${queryString.stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json.result.map((record: Record<string, unknown>) => (
        {
          id: record._id,
          ...record,
        }
      )),
      total: json.total,
    }));

  },
  getOne: async (resource: string, params: GetOneParams) => {

    resource = translateResource(resource);

    const result = await httpClient(`${apiUrl}/v2/manage/${resource}/${params.id}`);
    let areUUIDS = true;
    if (result.json.xgacGroups && _.isEqual(result.json.xgacGroups, [-2])) {

      result.json = { ...result.json, groupType: 'all', xgacGroups: [] };
      areUUIDS = false;

    }

    if (result.json.xgacGroups && _.isEqual(result.json.xgacGroups, [-1])) {

      result.json = { ...result.json, groupType: 'none', xgacGroups: [] };
      areUUIDS = false;

    }

    if (result.json.xgacGroups && areUUIDS) {

      result.json = { ...result.json, groupType: 'some' };

    }
    if (result.json.notXgacGroups?.length > 0) {

      result.json = { ...result.json, groupType: 'not-in' };

    }

    return {
      data: {
        id: result.json._id,
        ...result.json,
        ...(result.json.xgacCustomerId ? { customer: { _ref: 'Customer', _id: result.json.xgacCustomerId } } : {}),
      },
    };

  },

  getMany: async (resource: string, params: GetManyParams) => {

    resource = translateResource(resource);
    const currentCustomer = getCurrentCustomer();
    let query = `xgacCustomerId=${currentCustomer?.value}`;
    for (const id of params.ids) {

      query += `&id[]=${id}`;

    }

    const url = `${apiUrl}/v2/manage/${resource}?${query}`;
    const result = await httpClient(url);

    return {
      data: result.json.result.map((record: Record<string, unknown>) => ({
        ...record,
      })),
    };

  },
  create: async (resource: string, params: CreateParams) => {

    resource = translateResource(resource);

    const currentCustomer = getCurrentCustomer();
    const body = {
      ...params.data,
      xgacCustomerId: params.data.xgacCustomerId || currentCustomer?.value,
    };

    return httpClient(`${apiUrl}/v2/manage/${resource}`, {
      method: 'POST',
    }, body).then(({ json }) => ({
      data: { ...params.data, id: json._id },
    }));

  },
  update: async (resource: string, params: UpdateParams) => {

    resource = translateResource(resource);

    params.data = updateComparison(params.data, params.previousData);

    return httpClient(`${apiUrl}/v2/manage/${resource}/${params.id}`, {
      method: 'PATCH',
    }, { ...params.data }).then(({ json }) => ({ data: { ...json, id: json._id } }));

  },
  delete: (resource: string, params: DeleteParams) => {

    resource = translateResource(resource);
    return httpClient(`${apiUrl}/v2/manage/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));

  },

  deleteMany: async (resource: string, params: DeleteManyParams): Promise<any> => {

    resource = translateResource(resource);
    const deletedIds: Identifier[] = [];
    for (const currentId of params.ids) {

      try {

        await httpClient(`${apiUrl}/v2/manage/${resource}/${currentId}`, {
          method: 'DELETE',
        });
        deletedIds.push(currentId);

      } catch (e) {

        console.log({ message: `failed to delete ${currentId}`, e });

      }

    }

    return { data: deletedIds };

  },

  simulate: async (processTemplateId: string, assetId: string) => {

    const currentCustomer = getCurrentCustomer()?.value || '';

    const result = await httpClient(
      `${apiUrl}/v2/manage/simulate/alarm/${processTemplateId}`,
      { method: 'POST' },
      { xgacAssetId: assetId, xgacCustomerId: currentCustomer },
    );
    return result;

  },
};
