import { useTranslate } from 'ra-core';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import L, { Map } from 'leaflet';
import MapIcon from '@mui/icons-material/Map';
import GoogleIcon from '@mui/icons-material/Google';
import * as React from 'react';
import {
  DialogContent,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useRefresh } from 'react-admin';
import { AnyGeometry, DeviceType, PointGeometry } from '@x-guard/xgac-types/xgac';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { GoogleCoordinatesInput } from './inputs/GoogleCoordinatesInput';
import { CustomConfirmDialog } from '../../../components/CustomConfirmDialog';
import { StepDialogCard } from '../cards/stepDialogCard';
import { IntegerInput } from './inputs/integerInput';
import { reverseCoordinates } from '../../../utils/reverseGeoCode';
import { httpClient } from '../../../utils/httpClient';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import { XGAC_MAIN_API_URL } from '../../../config';

const apiUrl = XGAC_MAIN_API_URL;

export const AddButtonOrGatewayDialog = (props: {
  addType: 'gateway' | 'button' | null;
  setAddType: Dispatch<SetStateAction<null | 'gateway' | 'button'>>;
  setChosenFloors: Dispatch<number[]>;
  setSelectedBuilding: Dispatch<SetStateAction<AnyGeometry[] | null>>;
  cancelLastClicked: Date | null;
  map: Map | null;
  chosenFloors: number[];
}) => {

  const { addType, setAddType, map } = props;
  const [locationInputDialogOpen, setLocationInputDialogOpen] = useState(false);
  const [mapSelector, setMapSelector] = useState(false);
  const [chosenLocation, setChosenLocation] = useState<PointGeometry | null>(null);
  const [innerAddType, setInnerAddType] = useState<'gateway' | 'button' | null>(null);
  const [numberOfItems, setNumberOfItems] = useState(1);
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const refresh = useRefresh();
  const currentCustomer = getCurrentCustomer();

  const handleClose = () => {

    setAddType(null);
    setInnerAddType(null);
    setChosenLocation(null);
    setMapSelector(false);
    setNumberOfItems(1);
    setLocationInputDialogOpen(false);

  };

  const handlePlace = (passedPosition?: PointGeometry) => {

    const usedLocation = passedPosition || chosenLocation;
    setLoading(true);
    const deviceDtos = [];
    for (let i = 0; i < numberOfItems; i++) {

      let offsetLocation = usedLocation;
      if (offsetLocation && i > 0) {

        offsetLocation = {
          ...offsetLocation,
          coordinates: [offsetLocation.coordinates[0] + (Math.random() * 0.00002), offsetLocation.coordinates[1] + (Math.random() * 0.00002)],
        };

      }
      if (offsetLocation) {

        deviceDtos.push({
          type: innerAddType === 'gateway' ? DeviceType.LoraGateway : DeviceType.Lora,
          position: {
            ...offsetLocation,
            properties: {
              ...offsetLocation.properties,
              address: {
                ...offsetLocation.properties.address,
                floor: props.chosenFloors.length === 1 ? props.chosenFloors[0] : 0,
              },
            },
          },
        });

      }

    }
    const addDevicesDto = {
      customer: {
        _id: currentCustomer?.value,
        _ref: 'Customer',
      },
      devices: deviceDtos,
    };

    httpClient(`${apiUrl}/specials/bhv-knop/add-devices`, {
      method: 'POST',
    }, addDevicesDto).then(() => {

      refresh();
      if (props.chosenFloors.length > 1) {

        props.setChosenFloors([]);

      }
      setLoading(false);
      handleClose();

    }).catch(() => {

      setLoading(false);
      handleClose();

    });

  };

  useEffect(() => {

    if (props.cancelLastClicked) {

      handleClose();

    }

  }, [props.cancelLastClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!map) {

      return () => {};

    }
    if (mapSelector) {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      L.DomUtil.addClass(map._container, 'crosshair-cursor-enabled');

      map?.on('click', async (e) => {

        setLoading(true);
        const coordinates = e.latlng;
        const { place, parsedGoogleInput } = await reverseCoordinates(coordinates);

        if (place && parsedGoogleInput) {

          const newLocation = {
            type: 'Point',
            coordinates: [coordinates.lng, coordinates.lat],
            properties: {
              address: {
                formattedAddress: place.formatted_address,
                streetName: parsedGoogleInput.route?.long_name,
                streetNumber: parsedGoogleInput.street_number?.long_name || '0',
                city: parsedGoogleInput.locality?.long_name,
                state: parsedGoogleInput.administrative_area_level_1?.long_name,
                countryCode: parsedGoogleInput.country?.short_name || 'NL',
              },
            },
          } as PointGeometry;
          setChosenLocation(newLocation);
          handlePlace(newLocation);

        }

        setMapSelector(false);

      });

    }
    return () => {

      map?.off('click');

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      L.DomUtil.removeClass(map._container, 'crosshair-cursor-enabled');

    };

  }, [mapSelector]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <CustomConfirmDialog
        open={addType !== null && !mapSelector}
        handleClose={handleClose}
        dialogTitle={addType === 'gateway'
          ? translate('resources.buttons.text.create_dialog_title_gateway')
          : translate('resources.buttons.text.create_dialog_title_button')}
        icon1={{
          title: 'resources.zones.text.create_dialog_where_map',
          subTitle: '',
          action: () => {

            setInnerAddType(addType);
            setMapSelector(true);

          },
          icon: <MapIcon/>,
        }}
        icon2={{
          title: 'resources.zones.text.create_dialog_where_input',
          subTitle: '',
          action: () => {

            setInnerAddType(addType);
            setAddType(null);
            setLocationInputDialogOpen(true);

          },
          icon: <GoogleIcon/>,
        }}
      />
      <StepDialogCard
        title="resources.zones.text.create_dialog_where_input"
        open={locationInputDialogOpen}
        onClose={handleClose}
        onNext={() => {

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          props.setSelectedBuilding(chosenLocation);
          handlePlace(undefined);

        }}
        nextLabel={innerAddType === 'gateway' ? 'resources.buttons.text.place_gateway' : 'resources.buttons.text.place_button'}
        nextDisabled={!chosenLocation}
        content={
          <>
            <DialogContent>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '14px',
                  marginBottom: '5px',
                  fontWeight: 'bold',
                }}>
                {translate('general.fields.position.address.name')}
              </Typography>
              <GoogleCoordinatesInput
                value={chosenLocation}
                setValue={setChosenLocation}
                label={translate('resources.buttons.text.building_dialog.0.input_label')}
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '5px',
                    width: '100%',
                    height: '40px',
                    backgroundColor: '#F2F2F2 !important',
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  width: '60%',
                }}
                inputProps={{
                  endAdornment: <InputAdornment position={'end'}><SearchIcon/></InputAdornment>,
                }}
                country={['nl', 'be']}
              />
              <div style={{
                marginTop: '10px',
              }}>
                <span>{translate(innerAddType === 'gateway' ? 'resources.buttons.text.how_many_gateways' : 'resources.buttons.text.how_many_buttons')}</span>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}>
                  <IntegerInput value={numberOfItems} setValue={setNumberOfItems} min={1}/>
                  <span
                    style={{
                      marginLeft: '10px',
                      fontSize: '15px',
                    }}>
                    {translate(innerAddType === 'gateway' ? 'general.text.gateways' : 'general.text.emergency_buttons')}
                  </span>
                </div>
              </div>
            </DialogContent>
          </>
        }/>
      <StepDialogCard open={loading} title={''} content={
        <DialogContent sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
          <CircularProgress/>
        </DialogContent>
      } onClose={() => {}}/>
    </>
  );

};
