export const alarmTypes = [
  { id: 'panic', name: 'resources.alarms.text.type_options.panic' },
  { id: 'assistance', name: 'resources.alarms.text.type_options.assistance' },
  { id: 'test', name: 'resources.alarms.text.type_options.test' },
  { id: 'outage', name: 'resources.alarms.text.type_options.outage' },
  { id: 'fire', name: 'resources.alarms.text.type_options.fire' },
  { id: 'medical', name: 'resources.alarms.text.type_options.medical' },
  { id: 'mandown', name: 'resources.alarms.text.type_options.mandown' },
  { id: 'other', name: 'resources.alarms.text.type_options.other' },
];

export const alarmSeverities = [
  { id: 'red', name: 'resources.alarms.text.severity_options.red' },
  { id: 'orange', name: 'resources.alarms.text.severity_options.orange' },
  { id: 'green', name: 'resources.alarms.text.severity_options.green' },
];

export const timeGranularityType = [
  { name: 'resources.report-triggers.text.granularity_types.minute', id: 'minute' },
  { name: 'resources.report-triggers.text.granularity_types.hour', id: 'hour' },
  { name: 'resources.report-triggers.text.granularity_types.day', id: 'day' },
  { name: 'resources.report-triggers.text.granularity_types.week', id: 'week' },
  { name: 'resources.report-triggers.text.granularity_types.month', id: 'month' },
  { name: 'resources.report-triggers.text.granularity_types.quarter', id: 'quarter' },
  { name: 'resources.report-triggers.text.granularity_types.year', id: 'year' },
];

export const timeGranularityTypeForFlooring = [
  { name: 'resources.report-triggers.text.granularity_types.hour', id: 'hour' },
  { name: 'resources.report-triggers.text.granularity_types.day', id: 'day' },
  { name: 'resources.report-triggers.text.granularity_types.week', id: 'week' },
  { name: 'resources.report-triggers.text.granularity_types.month', id: 'month' },
];

export const loraDeviceTypes = [
  { name: 'Adeunis', id: 'adeunis.arf8123aa' },
  { name: 'IoTracker', id: 'iotracker.v2' },
  { name: 'Milesight', id: 'milesight.ws101' },
  { name: 'Sensative', id: 'sensative.1301001' },
];

export const appModeOptions = [
  { name: 'general.text.app_mode.on', id: 'on' },
  { name: 'general.text.app_mode.off', id: 'off' },
  { name: 'general.text.app_mode.risk', id: 'risk' },
];

export const daysOfWeek = [
  { id: 1, name: 'resources.static-responders.text.days.monday' },
  { id: 2, name: 'resources.static-responders.text.days.tuesday' },
  { id: 3, name: 'resources.static-responders.text.days.wednesday' },
  { id: 4, name: 'resources.static-responders.text.days.thursday' },
  { id: 5, name: 'resources.static-responders.text.days.friday' },
  { id: 6, name: 'resources.static-responders.text.days.saturday' },
  { id: 7, name: 'resources.static-responders.text.days.sunday' },
];
