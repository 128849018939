import {
  ReactElement,
} from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Icon, Typography,
} from '@mui/material';
import { useTranslate } from 'ra-core';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';

const Grid = Grid2;

export type CustomConfirmDialogButton = {
    title: string;
    subTitle: string;
    action: () => void;
    icon: ReactElement;
};
const ButtonInDialog = (props: { passedButton: CustomConfirmDialogButton }) => {

  const translate = useTranslate();

  return (
    <Button
      onClick={props.passedButton.action}
      color="primary"
      className="custom-confirm-dialog-button"
    >
      <div>
        <div style={{ width: '100%' }}>
          <Icon sx={{
            width: '80px',
            height: '80px',
            '& .MuiSvgIcon-root': {
              width: '80px',
              height: '80px',
            },
          }}>
            {props.passedButton.icon}
          </Icon>
        </div>
        <Typography variant="h6" fontSize="20px" component="div" marginBottom="10px">
          {translate(props.passedButton.title)}
        </Typography>
        <Typography variant="body1" fontSize="13px" component="div">
          {translate(props.passedButton.subTitle)}
        </Typography>
      </div>
    </Button>
  );

};
export const CustomConfirmDialog = (props: {
  open: boolean;
  handleClose: () => void;
  dialogTitle: string;
  dialogSubTitle?: string;
  icon1: CustomConfirmDialogButton;
  icon2: CustomConfirmDialogButton;
}) => {

  const translate = useTranslate();
  const {
    open, handleClose, dialogTitle, dialogSubTitle, icon1, icon2,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle className="flex-in-between">
        <Typography variant="body1" fontSize="20px">{translate(dialogTitle)}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {dialogSubTitle
          && (
            <DialogContentText sx={{ marginTop: '10px' }}>
              <Typography variant="body1" fontSize="14px" component="div" sx={{ color: 'black' }}>
                {translate(dialogSubTitle)}
              </Typography>
            </DialogContentText>
          )
        }
        <Grid container spacing={2} marginTop='10px'>
          <Grid xs={6} sx={{ borderRight: '1px solid #CCCCCC' }}>
            <ButtonInDialog passedButton={icon1} />
          </Grid>
          <Grid xs={6}>
            <ButtonInDialog passedButton={icon2} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

};
