export const googleAddressComponentsDecoder = (addressComponents: any[]) => {

  const newObject: any = {};
  for (const component of addressComponents) {

    newObject[component.types[0]] = component;

  }
  return newObject;

};
