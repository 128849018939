import { Alert, AlertColor } from '@mui/material';
import { useTranslate } from 'react-admin';

export enum StabilityLevel {
  Unstable,
  Beta,
  Stable,
}

const stabilityMap: Record<StabilityLevel, {
  color: AlertColor;
  text: string;
}> = {
  [StabilityLevel.Unstable]: {
    color: 'error',
    text: 'general.text.stability.unstable',
  },
  [StabilityLevel.Beta]: {
    color: 'warning',
    text: 'general.text.stability.beta',
  },
  [StabilityLevel.Stable]: {
    color: 'success',
    text: 'general.text.stability.stable',
  },
};

export const StabilityAlert = ({ stability, annotations }: { stability: StabilityLevel; annotations?: string[] }) => {

  const translate = useTranslate();

  if (stability === StabilityLevel.Stable) return null;

  return (
    <Alert severity={stabilityMap[stability].color}>
      {translate(stabilityMap[stability].text)}
      {annotations
        ? <p>
          <strong>{translate('general.text.stability.annotations_title')}</strong>
          <ol style={{ marginTop: 0 }}>{annotations.map((annotation) => <li>{annotation}</li>)}</ol>
        </p> : null}
    </Alert>
  );

};
