import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing, ErrorBoundary } from '@sentry/react';
import './index.scss';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { VITE_XGAC_DASHBOARD_MODE, XGAC_SENTRY_DSN } from './config';
import XGACApp from './apps/xgac/App';
import BHVKApp from './apps/bhvk/App';

if (window.location.href.match('localhost') === null) {

  Sentry.init({
    dsn: XGAC_SENTRY_DSN,
    beforeSend: (event, hint) => {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = hint.originalException?.response;
      if (response && [401, 404].includes(response.status)) {

        return null;

      }

      if (hint.originalException === 'Error: Not authorized') {

        return null;

      }
      if (hint.originalException === 'Error: No Token') {

        return null;

      }

      return event;

    },
    integrations: [new BrowserTracing(
      {
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      },
    ),
    new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
  });

}

const appComponentMap: Record<string, any> = {
  default: XGACApp,
  bhvk: BHVKApp,
};

const App = appComponentMap[VITE_XGAC_DASHBOARD_MODE];

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary showDialog={true}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);
