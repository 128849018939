import { AnyGeometry, PointGeometry } from '@x-guard/xgac-types/xgac';
import { Dispatch, SetStateAction } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { SxProps, TextField } from '@mui/material';
import { XGAC_MAPS_KEY } from '../../../../config';
import { googleAddressComponentsDecoder } from '../../../../lib/GoogleAddressComponentsDecoder';

export const GoogleCoordinatesInput = (props: {
  value: PointGeometry | AnyGeometry | null;
  setValue: SetStateAction<any>;
  setName?: Dispatch<SetStateAction<string>>;
  inputProps?: any;
  sx?: SxProps;
  variant?: 'standard' | 'filled' | 'outlined';
  label?: string;
  size?: 'small' | 'medium';
  country?: string[];
  setHasError?: Dispatch<SetStateAction<boolean>>;
}) => {

  const { ref } = usePlacesWidget({
    apiKey: XGAC_MAPS_KEY,
    options: {
      types: [],
      componentRestrictions: props.country ? { country: props.country } : undefined,
      fields: ['formatted_address', 'geometry', 'name', 'address_components'],
    },
    onPlaceSelected: (place) => {

      if (!place?.geometry || !place.geometry.location) {

        props.setHasError?.(true);
        return;

      }

      props.setHasError?.(false);

      const newCoordinates: [number, number] = [place.geometry.location.lng(), place.geometry.location.lat()];
      const parsedGoogleInput = googleAddressComponentsDecoder(place.address_components);
      let formattedAddress = place.formatted_address;
      if (!formattedAddress.includes(place.name)) {

        formattedAddress = `${place.name}, ${formattedAddress}`;

      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props.setValue({
        type: 'Point',
        coordinates: newCoordinates,
        properties: {
          ...(props.value?.properties ?? {}),
          address: {
            ...(props.value?.properties?.address ?? {}),
            formattedAddress,
            streetName: parsedGoogleInput.route?.long_name,
            streetNumber: parsedGoogleInput.street_number?.long_name || null,
            city: parsedGoogleInput.locality?.long_name,
            state: parsedGoogleInput.administrative_area_level_1?.long_name,
            countryCode: parsedGoogleInput.country?.short_name || 'NL',
          },
        },
      } as PointGeometry);

      if (props.setName) {

        props.setName(place.name);

      }

    },
  });

  return (
    <TextField
      label={props.label ? undefined : 'Location'}
      inputRef={ref}
      fullWidth
      InputProps={props.inputProps}
      // eslint-disable-next-line no-nested-ternary
      size={props.size ? props.size : props.label ? undefined : 'small'}
      color="info"
      placeholder={props.label ? props.label : undefined}
      variant={props.variant}
      sx={{ marginTop: '5px', ...props.sx }}
      defaultValue={props.value?.properties?.address?.formattedAddress}
    />
  );

};
