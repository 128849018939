import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  CreateButton,
  EditButton,
  FormDataConsumer,
  Labeled,
  NumberInput,
  ReferenceArrayInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useNotify,
  useRefresh,
  useSaveContext,
  useTranslate,
} from 'react-admin';
import React from 'react';
import { Datagrid, IfCanAccess, List } from '@react-admin/ra-rbac';
import { Customer } from '@x-guard/xgac-types/xgac';
import { useRedirect } from 'ra-core';
import { locales } from '../../../lib/constants/localesOptions';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import ResponsePartnerBooleanField from '../../../components/fields/ResponsePartnerBooleanField';
import LengthField from '../../../components/fields/LengthField';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import SearchFields from '../components/fields/Filters';
import { LIST_DEBOUNCE } from '../../../config';
import { FullExportButton } from '../../../utils/customExporter';
import { AlarmCenterField } from '../../../components/fields/AlarmCenterField';
import authProvider from '../../../utils/authProvider';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { HasRoles } from '../../../components/HasRoles';
import { TimeGranularityInput } from '../../../components/inputs/timeGranularityInput';
import { LoraConfigInput } from '../../../components/inputs/LoraConfigInput';
import { loraDeviceTypes } from '../../../lib/constants/selectChoices';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { CustomToolbar } from '../../../components/CustomToolBar';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

const transform = (data: Record<string, any>) => {

  delete data.migrationFinalised;
  delete data.children;
  delete data.parents;
  delete data.responsePartner;

  if (data.preferences && data.useDefaultMergeConfig) {

    data.preferences.mergeConfig = null;

  }

  if (data.preferences && !data.hasAlarmAgeLimit) {

    data.preferences.alarmAgeLimit = null;

  }

  delete data.hasAlarmAgeLimit;
  delete data.useDefaultMergeConfig;
  delete data.mergeConfig;

  if (data.billingContracts) {

    data.billingContracts = data.billingContracts.map((contract: { name: string }) => contract.name);

  }

  if (data.responsePartners) {

    data.responsePartners = data.responsePartners.map((partner: any) => {

      if (partner._id) {

        return partner;

      }

      return { _id: partner, _ref: 'Customer' };

    });

  }
  if (!data.alarmCenter.enabled && !data.alarmCenter.queuName) {

    delete data.alarmCenter;

  } else {

    return {
      name: data.name,
      locale: data.locale,
      alarmCenter: data.alarmCenter,
      billingContracts: data.billingContracts,
    };

  }
  return {
    ...data,
  };

};

export const CustomerList = () => {

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <List filters={SearchFields} debounce={LIST_DEBOUNCE} title="resources.customers.text.title"
        actions={<TopToolbar><CreateButton/><FullExportButton resource="customers"/></TopToolbar>}>
        <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
          <TextField source="name" label="general.fields.name"/>
          <TextField source="locale" />
          <ResponsePartnerBooleanField source="responsePartner" label="Is response partner"/>
          <LengthField source="responsePartners"/>
          <AlarmCenterField />
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
          <IfCanAccess action="edit">
            <EditButton/>
          </IfCanAccess>
          <HasRoles anyOf={['developer_admin']}>
            <AuditlogButton/>
          </HasRoles>
        </Datagrid>
      </List>
    </>
  );

};

const DisabledMergeConfig = () => {

  return (
    <>
      <BooleanInput source="mergeConfig.defaults.enabled" label="resources.customers.fields.mergeConfig.enabled" disabled defaultValue={true}/>
      <Labeled label="resources.customers.fields.mergeConfig.onlyMergeWhenSame">
        <>
          <div className="flex-in-between">
            <BooleanInput source="mergeConfig.defaults.name" label="resources.alarms.fields.name" disabled defaultValue={false}/>
            <BooleanInput source="mergeConfig.defaults.severity" label="resources.alarms.fields.severity" disabled defaultValue={true}/>
            <BooleanInput source="mergeConfig.defaults.type" label="resources.alarms.fields.type" disabled defaultValue={true}/>
          </div>
        </>
      </Labeled>
      <NumberInput source="mergeConfig.defaults.maxTimeBetweenAlarmsMst"
        disabled
        defaultValue={900}
        label="resources.customers.fields.mergeConfig.maxTimeBetweenAlarmsMs"
      />
      <NumberInput source="mergeConfig.defaults.retriggerAtsAfterMs"
        disabled
        defaultValue={300}
        label="resources.customers.fields.mergeConfig.retriggerAtsAfterMs"
      />
    </>
  );

};

const AlarmMergeConfig = () => (
  <>
    <BooleanInput source="preferences.mergeConfig.enabled" label="resources.customers.fields.mergeConfig.enabled" defaultValue={true}/>
    <Labeled label="resources.customers.fields.mergeConfig.onlyMergeWhenSame">
      <>
        <div className="flex-in-between">
          <BooleanInput source="preferences.mergeConfig.onlyMergeWhenSame.name" defaultValue={false} label="resources.alarms.fields.name"/>
          <BooleanInput source="preferences.mergeConfig.onlyMergeWhenSame.severity" defaultValue={true} label="resources.alarms.fields.severity"/>
          <BooleanInput source="preferences.mergeConfig.onlyMergeWhenSame.type" defaultValue={true} label="resources.alarms.fields.type"/>
        </div>
      </>
    </Labeled>
    <NumberInput source="preferences.mergeConfig.maxTimeBetweenAlarmsMs"
      defaultValue={900000} format={(value) => value / 1000}
      parse={(value) => value * 1000}
      label="resources.customers.fields.mergeConfig.maxTimeBetweenAlarmsMs"
    />
    <NumberInput source="preferences.mergeConfig.retriggerAtsAfterMs"
      defaultValue={300000} format={(value) => value / 1000}
      parse={(value) => value * 1000}
      label="resources.customers.fields.mergeConfig.retriggerAtsAfterMs"
    />
  </>
);

const CustomSaveButton = () => {

  const { saving } = useSaveContext();
  return (
    <Toolbar>
      <SaveButton disabled={saving} />
    </Toolbar>
  );

};
export const CustomerCreate = () => {

  const isAdmin = authProvider.isAdmin();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data: Customer & {
    id: string;
  }) => {

    notify('resources.customers.text.create_success');
    const newCustomer = {
      label: data.name,
      value: data.id,
    };
    localStorage.setItem('lastSavedCustomer', JSON.stringify(newCustomer));
    sessionStorage.setItem('currentCustomer', JSON.stringify(newCustomer));
    redirect('list', 'customer-alarm-center-relations');
    window.location.reload();

  };

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Unstable} />
      <CreateWithSuccess transform={transform} mutationOptions={{ onSuccess }} actions={false} title="resources.customers.text.title" >
        <SimpleForm toolbar={<CustomSaveButton/>}>
          <TextInput source="name" label="general.fields.name"/>
          <SelectInput source="locale" choices={locales} defaultValue='nl' validate={required()} />
          {isAdmin && (
            <>
              <BooleanInput source="alarmCenter.enabled" defaultValue={false} label={`Is ${translate('resources.customers.fields.alarmCenter_name')}`}/>
              <HasRoles anyOf={['developer_admin']}>
                <ArrayInput source="billingContracts">
                  <SimpleFormIterator>
                    <TextInput source="name" label=""
                      validate={regex(/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/, 'resources.customers.text.uuid_validation')}
                      fullWidth
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </HasRoles>
              <FormDataConsumer>
                {({ formData }) => (formData.alarmCenter?.enabled)
                  && <TextInput source="alarmCenter.queueName" label='resources.customers.text.alarmcenter_queueName' />
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData }) => (!formData.alarmCenter || !formData.alarmCenter.enabled)
                  && <>
                    <ReferenceArrayInput source="responsePartners" reference="responsePartners">
                      <AutocompleteArrayInput label="resources.customers.fields.responsePartner_name" source="name" autoHighlight={true} blurOnSelect={false}/>
                    </ReferenceArrayInput>
                  </>
                }
              </FormDataConsumer>
              <BooleanInput
                source="preferences.disableAutomaticGenerationOfButtonZones"
                label={'resources.customers.fields.disableAutomaticGenerationOfButtonZones'}
              />
              <HasRoles anyOf={['developer_admin']}>
                <BooleanInput source="useDefaultMergeConfig" label="resources.customers.fields.mergeConfig.use_default" defaultValue={true}/>
                <FormDataConsumer>
                  {({ formData }) => {

                    if (formData.useDefaultMergeConfig) {

                      return <DisabledMergeConfig />;

                    }
                    return <AlarmMergeConfig/>;

                  }}
                </FormDataConsumer>

                <BooleanInput source="hasAlarmAgeLimit"/>
                <FormDataConsumer>
                  {({ formData }) => (formData.hasAlarmAgeLimit

                && <TimeGranularityInput source="preferences.alarmAgeLimit" label="resources.customers.fields.alarmAgeLimit"/>
                  )}
                </FormDataConsumer>
              </HasRoles>
            </>
          )}
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};
export const CustomerEdit = () => {

  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const OnSuccess = () => {

    notify('resources.customers.text.update_success');
    refresh();

  };

  const isAdmin = authProvider.isAdmin();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Unstable} />
      <EditWithSuccess transform={transform} mutationOptions={{ onSuccess: OnSuccess }} title="resources.customers.text.title">
        <SimpleForm toolbar={<CustomToolbar/>}>
          <TextInput source="name" label="general.fields.name" />
          <SelectInput source="locale" choices={locales} defaultValue='nl' validate={required()} />
          {isAdmin && (
            <>
              <BooleanInput source="alarmCenter.enabled" defaultValue={false} label={`Is ${translate('resources.customers.fields.alarmCenter_name')}`}/>
              <HasRoles anyOf={['developer_admin']}>
                <ArrayInput source="billingContracts">
                  <SimpleFormIterator>
                    <TextInput source="name" label=""
                      validate={regex(/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/, 'resources.customers.text.uuid_validation')}
                      fullWidth
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </HasRoles>
              <FormDataConsumer>
                {({ formData }) => (formData.alarmCenter?.enabled)
                  && <TextInput source="alarmCenter.queueName" label='resources.customers.text.alarmcenter_queueName' />
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData }) => (!formData.alarmCenter || !formData.alarmCenter.enabled)
                  && <>
                    <ReferenceArrayInput source="responsePartners" reference="responsePartners">
                      <AutocompleteArrayInput label="resources.customers.fields.responsePartner_name" source="name" autoHighlight={true} blurOnSelect={false}/>
                    </ReferenceArrayInput>
                  </>
                }
              </FormDataConsumer>
              <BooleanInput source="useDefaultMergeConfig" defaultValue={true} label="resources.customers.fields.mergeConfig.use_default"/>
              <FormDataConsumer>
                {({ formData }) => {

                  if (formData.useDefaultMergeConfig) {

                    return <DisabledMergeConfig />;

                  }
                  return <AlarmMergeConfig/>;

                }}
              </FormDataConsumer>
              <BooleanInput
                source="preferences.disableAutomaticGenerationOfButtonZones"
                label={'resources.customers.fields.disableAutomaticGenerationOfButtonZones'}
              />
              <HasRoles anyOf={['developer_admin']}>
                <BooleanInput source="hasAlarmAgeLimit"/>
                <FormDataConsumer>
                  {({ formData }) => (formData.hasAlarmAgeLimit

                    && <TimeGranularityInput source="preferences.alarmAgeLimit" label="resources.customers.fields.alarmAgeLimit"/>
                  )}
                </FormDataConsumer>
              </HasRoles>
              <ArrayInput source="loraConfig">
                <SimpleFormIterator>
                  <SelectInput source="type" defaultValue={null} choices={loraDeviceTypes}
                    emptyText="resources.devices.text.no_lora_config"
                    label="resources.devices.fields.loraConfig.type"
                    validate={required()}/>
                  <LoraConfigInput/>
                </SimpleFormIterator>
              </ArrayInput>
            </>
          )}
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};
