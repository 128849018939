import { memo } from 'react';
import { BhvKnopMarketingWebsiteFrame } from './bhvKnopMarketingWebsiteFrame';

export const BhvKnopOrderFrame = memo(() => {

  return <BhvKnopMarketingWebsiteFrame
    page={window.location.pathname}
    onMessage={(event) => {

      window.history.pushState({}, '', event.data.currentPath);

    }}
    frameId={'orderFrame'}
    enableEmail={true}
    onLoad={() => {

      const ids = sessionStorage.getItem('conceptDevices');
      if (ids) {

        const parsedIds = JSON.parse(ids);
        sessionStorage.removeItem('conceptDevices');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('orderFrame')?.contentWindow.postMessage({
          prepareOrder: { buttonIds: parsedIds.conceptButtons, gatewayIds: parsedIds.conceptGateways },
        }, '*');

      }

    }}
  />;

});
