import {
  ArrayInput,
  Datagrid,
  EditButton,
  email,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from 'react-admin';
import * as React from 'react';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { ListLive } from '@react-admin/ra-realtime';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import SearchFields, { FilterResetter } from '../components/fields/Filters';
import { LIST_DEBOUNCE } from '../../../config';
import { exporter } from '../../../utils/customExporter';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import LengthField from '../../../components/fields/LengthField';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import { CustomToolbar } from '../../../components/CustomToolBar';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { PhoneNumberInput } from '../../../components/inputs/PhoneNumberInput';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

export const ContactListList = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Stable} />
    <ListLive filters={SearchFields} debounce={LIST_DEBOUNCE} exporter={exporter} title="resources.contact-lists.text.title">
      <>
        <FilterResetter />
        <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
          <TextField source="name" label="general.fields.name"/>
          <TextFieldNullable source="description" label="general.fields.description"/>
          <LengthField source="contacts" label="resources.contact-lists.text.contacts_number" />
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
          <IfCanAccess action="edit">
            <EditButton/>
          </IfCanAccess>
          <HasRoles anyOf={['developer_admin']}>
            <AuditlogButton/>
          </HasRoles>
        </Datagrid>
      </>
    </ListLive>
  </>
);

export const ContactListEdit = () => {

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <EditWithSuccess title="resources.contact-lists.text.title">
        <SimpleForm toolbar={<CustomToolbar/>}>
          <TextInput source="name" label="general.fields.name" validate={required()}/>
          <TextInput source="description" label="general.fields.description"/>
          <ArrayInput source="contacts">
            <SimpleFormIterator disableReordering>
              <TextInput source="name" label="general.fields.name" validate={required()}/>
              <TextInput source="email" label="resources.assets.fields.email" validate={email()}/>
              <PhoneNumberInput source="phone" label="resources.assets.fields.phoneNumber"/>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const ContactListCreate = () => {

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <CreateWithSuccess title="resources.contact-lists.text.title">
        <SimpleForm>
          <TextInput source="name" label="general.fields.name" validate={required()}/>
          <TextInput source="description" label="general.fields.description"/>
          <ArrayInput source="contacts">
            <SimpleFormIterator disableReordering>
              <TextInput source="name" label="general.fields.name" validate={required()}/>
              <TextInput source="email" label="resources.assets.fields.email" validate={email()}/>
              <PhoneNumberInput source="phone" label="resources.assets.fields.phoneNumber"/>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};
