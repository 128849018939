import {
  SaveButton,
  DeleteWithConfirmButton,
  Toolbar,
  useResourceContext,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import { IfCanAccess } from '@react-admin/ra-rbac';
import authProvider from '../utils/authProvider';

export const CustomToolbar = (props: any) => {

  function capitalizeFirstLetter(string: string) {

    return string.charAt(0).toUpperCase() + string.slice(1, -1);

  }

  const translate = useTranslate();
  const resource = useResourceContext() || '';
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext(props);
  const translatedResource = translate(`resources.${resource}.name`, { _: '' });

  if (!record) return null;
  const nameToUse = record.name || translatedResource || record.id;

  const onSuccess = () => {

    notify(`${capitalizeFirstLetter(resource)} ${translate('general.text.deleted')}`);
    redirect(props.redirect ?? `/${resource}`);

  };
  const isAdmin = authProvider.isAdmin();

  const customMessage = `${translate('ra.action.delete')} ${nameToUse}`;
  return (
    <Toolbar {...props} sx={{ justifyContent: 'space-between', maxWidth: props.narrow ? '1000px' : undefined }}>
      {(resource === 'customers' && isAdmin) && (
        <IfCanAccess action="delete">
          <DeleteWithConfirmButton confirmTitle={customMessage} mutationOptions={{ onSuccess }} sx={{ mr: '8px' }}/>
        </IfCanAccess>
      )}
      {resource !== 'customers' && (
        <IfCanAccess action="delete">
          <DeleteWithConfirmButton
            redirect={props.customRedirect || undefined}
            confirmTitle={customMessage}
            mutationOptions={{ onSuccess }}
            sx={{ mr: '8px' }}
            disabled={props.noDelete}
          />
        </IfCanAccess>
      )}
      <SaveButton alwaysEnable={!!props.alwaysEnable}/>
    </Toolbar>
  );

};

export const CreateToolbarRight = (props: any) => {

  return (
    <Toolbar {...props} sx={{ justifyContent: 'end', maxWidth: props.narrow ? '1000px' : undefined }}>
      <SaveButton/>
    </Toolbar>
  );

};
