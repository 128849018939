import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Datagrid,
  EditButton,
  FormDataConsumer,
  List,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextField,
  useTranslate,
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import _, { get } from 'lodash';
import { Typography } from '@mui/material';
import * as React from 'react';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import ReferenceFieldNullable from '../../../components/fields/ReferenceFieldNullable';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { alarmTypes } from '../../../lib/constants/selectChoices';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import ReferenceArrayFieldNullable from '../../../components/fields/ReferenceArrayFieldNullable';

const customerLinkTransform = (data: Record<string, any>) => {

  switch (data.groupType) {

  case 'all':
    data.xgacGroups = [-2];
    data.notXgacGroups = [];
    break;
  case 'none':
    data.xgacGroups = [-1];
    data.notXgacGroups = [];
    break;
  case 'some':
    data.notXgacGroups = [];
    break;
  case 'not-in':
    data.xgacGroups = [-2];
    break;
  default:
    break;

  }

  return {
    alarmLevels: data.alarmLevels,
    processTemplate: data.processTemplate.id,
    xgacGroups: data.xgacGroups,
    notXgacGroups: data.notXgacGroups,
    alarmTypes: data.alarmTypes,
  };

};

const ChipFieldNullable = (props: { values: string[] | null; emptyText?: string }) => {

  if (!props.values || !props.values[0]) return <TextFieldNullable source="value" record={{ value: null }} emptyText={props.emptyText}/>;
  return (<ul style={{ margin: '0' }}>
    {props.values.map((item: any) => (
      <ChipField source="item" record={{ item }} />
    ))}
  </ul>);

};

const AlarmTypesField = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext();
  if (!record) return <TextFieldNullable/>;
  const chipFieldValues: Array<string> = [];
  const selectedValues = get(record, props.source);
  Object.keys(selectedValues).forEach((key) => {

    chipFieldValues.push(translate(`resources.alarms.text.type_options.${selectedValues[key]}`) || selectedValues[key]);

  });
  return <ChipFieldNullable values={chipFieldValues} emptyText="general.text.all" />;

};

const AlarmLevelField = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext();
  if (!record) return <TextFieldNullable/>;
  const alarmValues: Array<string> = [];
  const alarmLevels = get(record, props.source);
  Object.keys(alarmLevels).forEach((key) => {

    if (alarmLevels[key]) {

      alarmValues.push(translate(`resources.customer-links.fields.level.${key}`) || key);

    }

  });
  return <ChipFieldNullable values={alarmValues} />;

};

const groupTypes = [
  { id: 'all', name: 'resources.customer-links.text.group_types.all' },
  { id: 'none', name: 'resources.customer-links.text.group_types.none' },
  { id: 'some', name: 'resources.customer-links.text.group_types.some' },
  { id: 'not-in', name: 'resources.customer-links.text.group_types.not_in' },
];

const CustomerLinkGroupField = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext();
  const groups = get(record, props.source);
  const allGroups = _.isEqual(groups, [-2]);
  const noGroups = _.isEqual(groups, [-1]);
  const notInGroups = get(record, 'notXgacGroups');

  if (notInGroups && notInGroups.length > 0) {

    return <>
      {translate('general.text.not_in')}: <ReferenceArrayFieldNullable reference="asset-groups" source="notXgacGroups"/>
    </>;

  }
  if (allGroups) {

    return <TextField record={{ allGroups: translate('general.text.all') }} source="allGroups"/>;

  }

  if (noGroups) {

    return <TextField record={{ noGroups: translate('general.text.none') }} source="noGroups"/>;

  }

  return <ReferenceArrayFieldNullable reference="asset-groups" source="xgacGroups"/>;

};
export const CustomerLinkList = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Beta} />
    <List title="resources.customer-links.text.title">
      <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
        <AlarmLevelField source="alarmLevels"/>
        <AlarmTypesField source="alarmTypes"/>
        <ReferenceFieldNullable source="processTemplate.id" reference="process-templates" />
        <CustomerLinkGroupField source="xgacGroups" label="resources.customer-links.fields.groups" />
        <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
        <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
        <EditButton/>
      </Datagrid>
    </List>
  </>
);

export const CustomerLinkEdit = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta} />
      <EditWithSuccess title="resources.customer-links.text.title" transform={customerLinkTransform}>
        <SimpleForm>
          <Typography variant='body1'>{translate('resources.customer-links.fields.alarmLevels')}</Typography>
          <div className="flex-in-between">
            <BooleanInput source="alarmLevels.green" label="resources.customer-links.fields.level.green"/>
            <BooleanInput source="alarmLevels.orange" label="resources.customer-links.fields.level.orange" />
            <BooleanInput source="alarmLevels.red" label="resources.customer-links.fields.level.red" />
          </div>
          <ReferenceInput source="processTemplate.id" reference="process-templates" perPage={100}>
            <AutocompleteInput validate={required()} autoHighlight={true} filterToQuery={(searchText) => ({ search: searchText })}/>
          </ReferenceInput>
          <AutocompleteArrayInput
            source="alarmTypes"
            disableCloseOnSelect
            size={'medium'}
            choices={alarmTypes}
            label="resources.report-triggers.fields.config.alarmTypes"

            helperText="resources.customer-links.text.types_placeholder"
          />
          <RadioButtonGroupInput source="groupType" choices={groupTypes} defaultValue="all"/>
          <FormDataConsumer>
            {({ formData }) => {

              if (formData.groupType === 'some') {

                return (
                  <ReferenceArrayInput source="xgacGroups" reference="asset-groups" shouldUnregister>
                    <AutocompleteArrayInput label="resources.customer-links.fields.groups" autoHighlight={true}
                      blurOnSelect={false} shouldUnregister/>
                  </ReferenceArrayInput>);

              }
              if (formData.groupType === 'not-in') {

                return (
                  <ReferenceArrayInput source="notXgacGroups" reference="asset-groups" shouldUnregister>
                    <AutocompleteArrayInput label="resources.customer-links.fields.groups" autoHighlight={true}
                      blurOnSelect={false} shouldUnregister/>
                  </ReferenceArrayInput>);

              }
              return null;

            }}
          </FormDataConsumer>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const CustomerLinkCreate = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta} />
      <CreateWithSuccess title="resources.customer-links.text.title" transform={customerLinkTransform}>
        <SimpleForm>
          <Typography variant='body1'>{translate('resources.customer-links.fields.alarmLevels')}</Typography>
          <div className="flex-in-between">
            <BooleanInput source="alarmLevels.green" label="resources.customer-links.fields.level.green"/>
            <BooleanInput source="alarmLevels.orange" label="resources.customer-links.fields.level.orange" />
            <BooleanInput source="alarmLevels.red" label="resources.customer-links.fields.level.red" />
          </div>
          <ReferenceInput source="processTemplate.id" reference="process-templates" perPage={100}>
            <AutocompleteInput validate={required()} autoHighlight={true} filterToQuery={(searchText) => ({ search: searchText })}/>
          </ReferenceInput>
          <AutocompleteArrayInput
            source="alarmTypes"
            choices={alarmTypes}
            size="medium"
            disableCloseOnSelect
            label="resources.report-triggers.fields.config.alarmTypes"
            helperText="resources.customer-links.text.types_placeholder"
          />
          <RadioButtonGroupInput source="groupType" choices={groupTypes} defaultValue="all"/>
          <FormDataConsumer>
            {({ formData }) => {

              if (formData.groupType === 'some') {

                return (
                  <ReferenceArrayInput source="xgacGroups" reference="asset-groups" shouldUnregister>
                    <AutocompleteArrayInput label="resources.customer-links.fields.groups" autoHighlight={true}
                      blurOnSelect={false} shouldUnregister/>
                  </ReferenceArrayInput>);

              }
              if (formData.groupType === 'not-in') {

                return (
                  <ReferenceArrayInput source="notXgacGroups" reference="asset-groups" shouldUnregister>
                    <AutocompleteArrayInput label="resources.customer-links.fields.groups" autoHighlight={true}
                      blurOnSelect={false} shouldUnregister/>
                  </ReferenceArrayInput>);

              }
              return null;

            }}
          </FormDataConsumer>
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};
