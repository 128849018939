import {
  Card, CardContent,
} from '@mui/material';
import { useCheckAuth, useTranslate } from 'react-admin';
import Grid2 from '@mui/material/Unstable_Grid2';
import ReactMarkdown from 'react-markdown';

const Grid = Grid2;

export const HomePage = () => {

  const translate = useTranslate();
  const checkAuth = useCheckAuth();

  checkAuth();
  return <>
    <Grid container spacing={2} style={{ marginTop: '5px' }}>
      <Grid xs={12}>
        <Card>
          <CardContent>
            <ReactMarkdown>{translate('home.text.block_one')}</ReactMarkdown>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={6}>
        <Card style={{ height: '100%' }}>
          <CardContent>
            <ReactMarkdown>{translate('home.text.block_two')}</ReactMarkdown>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={6}>
        <Card style={{ height: '100%' }}>
          <CardContent>
            <ReactMarkdown>{translate('home.text.block_three')}</ReactMarkdown>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>;

};
