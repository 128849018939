import * as turf from '@turf/turf';

import _ from 'lodash';
import { FeatureCollection, Polygon } from '@turf/turf';
import { LatLngBounds } from 'leaflet';

const getPointsFromGrid = (grid: FeatureCollection, polygon: Polygon) => {

  const points = turf.explode(grid);
  const uniquePoints = {
    ...points,
    features: _.uniqBy(points.features, (feature) => feature.geometry.coordinates.join(',')),
  };
  const pointsIn = turf.pointsWithinPolygon(uniquePoints, polygon);
  const scaledPolygon = turf.transformScale(polygon, 1.1);
  const pointsInScaled = turf.pointsWithinPolygon(uniquePoints, scaledPolygon as any);
  const pointsJustOutside = _.difference(pointsInScaled.features, pointsIn.features);
  if (pointsJustOutside.length > 0) {

    pointsJustOutside.forEach((point) => {

      // move the point to the edge of the polygon
      const line = turf.lineString([point.geometry.coordinates, turf.centerOfMass(polygon).geometry.coordinates]);
      const intersection = turf.lineIntersect(polygon, line);
      if (intersection.features.length > 0) {

        point.geometry.coordinates = intersection.features[0].geometry.coordinates;
        pointsIn.features.push(point);

      }

    });

  }

  const uniquePointsIn = {
    ...pointsIn,
    features: _.uniqBy(pointsIn.features, (feature) => `${feature.geometry.coordinates[0].toFixed(5)}, ${feature.geometry.coordinates[1].toFixed(5)}`),
  };
  return uniquePointsIn;

};
export const pointsInPolygon = (polygon: Polygon, mapBounds: LatLngBounds, distance: number) => {

  // make a bbox out of the map bounds
  const bbox = turf.bbox({
    type: 'FeatureCollection',
    features: [
      turf.point([mapBounds.getSouthWest().lng, mapBounds.getSouthWest().lat]),
      turf.point([mapBounds.getNorthEast().lng, mapBounds.getNorthEast().lat]),
    ],
  });
  let chosenGrid;
  if (turf.area(polygon) < 20000) {

    const hexGrid = turf.hexGrid(bbox, distance / 1000, { mask: polygon, triangles: true });
    const triangleGrid = turf.triangleGrid(bbox, distance / 1000, { mask: polygon });
    const squareGrid = turf.squareGrid(bbox, distance / 1000, { mask: polygon });

    // choose the grid with the most points
    const gridPoints = [
      getPointsFromGrid(hexGrid, polygon),
      getPointsFromGrid(triangleGrid, polygon),
      getPointsFromGrid(squareGrid, polygon),
    ].sort((a, b) => b.features.length - a.features.length);

    if (gridPoints[0].features.length > 0 && gridPoints[0].features.length === gridPoints[1].features.length) {

      const sameLength = gridPoints.filter((grid) => grid.features.length === gridPoints[0].features.length);
      const maxDistanceBetweenPoints = sameLength.map((grid) => {

        // get the distance between the two furthest points
        let maxDistance = 0;
        for (let i = 0; i < grid.features.length; i++) {

          for (let j = i + 1; j < grid.features.length; j++) {

            const calculatedDistance = turf.distance(grid.features[i], grid.features[j]);
            if (calculatedDistance > maxDistance) {

              maxDistance = calculatedDistance;

            }

          }

        }
        return {
          grid,
          maxDistance,
        };

      });

      const maxDistanceGrid = maxDistanceBetweenPoints.sort((a, b) => b.maxDistance - a.maxDistance)[0];
      chosenGrid = maxDistanceGrid.grid;

    } else {

      chosenGrid = gridPoints[0];

    }

  } else {

    const hexGrid = turf.hexGrid(bbox, distance / 1000, { mask: polygon, triangles: true });
    chosenGrid = getPointsFromGrid(hexGrid, polygon);

  }
  const pointsIn = chosenGrid;
  if (pointsIn.features.length === 0) {

    const center = turf.centerOfMass(polygon);
    // randomly shift the center
    const randomShift = Math.random() * (turf.area(polygon) / 100);
    const randomAngle = Math.random() * 360;
    turf.transformTranslate(center, randomShift, randomAngle, { units: 'meters', mutate: true });
    if (!turf.booleanPointInPolygon(center, polygon)) {

      const randomPoint = turf.pointOnFeature(polygon);
      return {
        features: [
          randomPoint,
        ],
      };

    }

    return {
      features: [
        center,
      ],
    };

  }

  return pointsIn;

};
