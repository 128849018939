import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import authProvider from '../utils/authProvider';

const LoginPage = () => {

  const navigate = useNavigate();

  const { searchParams } = new URL(window.location.href);
  const code = searchParams.get('code');

  const href = window.location.href;
  const isHashed = href.includes('hashed');

  const params = useParams();
  useEffect(() => {

    if (isHashed) {

      authProvider.login({
        code: null,
        credentials: {
          username: params.username,
          key: params.key,
        },
      });

      return;

    }
    // try to login
    if (code) {

      authProvider.login({
        code,
      }).then(() => {

        navigate('/');

      });

    } else {

      // redirect
      authProvider.login({
        code: null,
      });

    }

  });

  return <div>Redirecting...</div>;

};

export default LoginPage;
