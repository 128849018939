import _ from 'lodash';

export const updateComparison = (data: any, previousData?: any) => {

  if (previousData) {

    for (const key of Object.keys(data)) {

      if (_.isEqual(data[key], previousData[key])) {

        delete data[key];

      }

    }

  }
  return data;

};
