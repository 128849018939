import {
  memo,
} from 'react';
import { useParams } from 'react-router-dom';
import { BhvKnopMarketingWebsiteFrame } from './bhvKnopMarketingWebsiteFrame';

export const BhvKnopHelpFrame = memo(() => {

  const { category, article } = useParams();

  return <BhvKnopMarketingWebsiteFrame
    page={category ? `help/${category}${article ? `/${article}` : ''}` : 'help'}
    disableCustomerId={true}
    onMessage={(event) => {

      // set history without redirecting
      window.history.pushState({}, '', event.data.currentPath);

    }}
  />;

});
