import React, {
  FC, ReactNode, useEffect, useMemo, useRef,
} from 'react';

import { Icon } from '@mdi/react';
import './slideTo';

import { useMap } from 'react-leaflet';
import {
  Marker, DivIcon, LatLngExpression, LeafletEventHandlerFn, Polyline,
} from 'leaflet';
import { renderToString } from 'react-dom/server';
import { mdiMapMarker } from '@mdi/js';
import { Position, areSamePosition, latLng } from './Position';
import './leaflet.scss';
import { OMS } from './Oms';

export type MarkerVariant = 'alarmPosition' | 'currentPosition' | 'quickResponse' | 'nationalQuickResponse';

type CustomMarkerProps = {
  position: Position;
  variant: MarkerVariant;
  oms?: OMS;
  label?: ReactNode;
  inactive: boolean;
  selected: boolean;
  onClick?: LeafletEventHandlerFn;
};

export interface SlidableMarker extends Marker {
  slideTo: (position: LatLngExpression, options?: {
    duration?: number;
    keepAtCenter?: boolean;
  }) => void;
}

export const CustomMarker: FC<CustomMarkerProps> = (props) => {

  const {
    position,
    label,
    oms,
    inactive,
    selected,
    onClick,
  } = props;

  const ref = useRef<SlidableMarker>();
  const prevPosition = useRef<Position>(position);
  const map = useMap();

  // Create icon
  const icon = useMemo(() => {

    const iconPath = mdiMapMarker;

    let iconColor = 'rgba(200, 200, 200, 0.8)';

    if (!inactive) {

      iconColor = '#1a72ba';

    }

    if (selected) {

      iconColor = '#4dbd74';

    }

    return new DivIcon({
      html: renderToString(
        <div className={'leaflet-xgac-marker-container'}>
          {label && (
            // eslint-disable-next-line no-nested-ternary
            <div className={`leaflet-xgac-marker-label ${selected ? 'selected' : inactive ? 'inactive' : ''}`}>
              <div>
                {label}
              </div>
            </div>
          )}
          <Icon color={iconColor} path={iconPath} style={{ position: 'relative', left: -16 }}/>
        </div>,
      ),
      iconSize: [32, 32],
      iconAnchor: [0, 32],
    });

  }, [label, selected, inactive]);

  // Slide once the position changes
  useEffect(() => {

    if (ref.current && !areSamePosition(prevPosition.current, position)) {

      const line = new Polyline([[position.latitude, position.longitude], [prevPosition.current.latitude, prevPosition.current.longitude]]);

      line.addTo(map);

      prevPosition.current = position;

      ref.current.slideTo(latLng(position), {
        duration: 300,
        keepAtCenter: false,
      });

    }

  }, [prevPosition, ref, position]); // eslint-disable-line react-hooks/exhaustive-deps

  // Create marker
  useEffect(() => {

    const marker = new Marker(latLng(position), {
      icon,
      riseOnHover: true,
      riseOffset: 1005,
      zIndexOffset: selected ? 1000 : 0,
    }).addTo(map);

    marker.on('click', (event) => {

      if (onClick) {

        onClick(event);

      }

    });
    oms?.addMarker(marker);

    ref.current = marker as SlidableMarker;

    return () => {

      oms?.removeMarker(marker);
      marker.remove();

    };

    // Map has a stable identity, position updates are handled by another hook, and icon updates are not supported

  }, [oms, selected, inactive]);// eslint-disable-line react-hooks/exhaustive-deps

  return null;

};
