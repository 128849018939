export const androidPermissions = [
  'location',
  'locationInUse',
  'physical',
  'motion',
  'microphone',
  'bluetooth',
  'agenda',
  'batteryOptimization',
  'callPhone',
  'readPhoneState',
  'camera',
  'overlay',
  'contact',
];

export const iosPermissions = [
  'location',
  'locationInUse',
  'motion',
  'microphone',
  'notification',
  'bluetooth',
  'agenda',
  'camera',
  'contact',
];
