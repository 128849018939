export const locales = [
  { name: 'none', id: ' ' },
  { name: 'nl', id: 'nl' },
  { name: 'de', id: 'de' },
  { name: 'fr', id: 'fr' },
  { name: 'en', id: 'en' },
];

export const localeDefaults = [
  { name: 'nl', id: 'nl' },
  { name: 'de', id: 'de' },
  { name: 'fr', id: 'fr' },
  { name: 'en', id: 'en' },
];
