export function preTranslateResource(resource: string) {

  if (resource === 'tags') return 'beacons';
  if (resource === 'users/without-app') return 'users';
  if (resource === 'assets/without-app') return 'assets';
  if (resource === 'devices/without-app') return 'devices';
  if (resource === 'app-users') return 'assets';
  if (resource === 'service-accounts') return 'users';
  return resource;

}

export function afterTranslateResource(resource:string) {

  if (resource === 'responsePartners') return 'customers';
  if (resource === 'service-accounts') return 'users';
  if (resource === 'all-assets') return 'assets';

  return resource;

}
