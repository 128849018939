import { TextField, useRecordContext, useTranslate } from 'react-admin';
import { get } from 'lodash';
import * as React from 'react';

export const TranslatedField = (props: any) => {

  const record = useRecordContext();
  const translate = useTranslate();
  let value = get(record, props.source);
  // '_' is the default value, in case no translation is found.
  if (props.preValue) value = translate(`${props.preValue}${value}`, { _: value }) || value;

  return <TextField {...props} source="value" record={{ value }}/>;

};
