import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import _ from 'lodash';
import React from 'react';
import { UploadedCSV } from '../lib/constants/customTypes';
import { httpClient } from './httpClient';
import { DEVICE_REGISTRATION_API_URL, XGAC_MAIN_API_URL } from '../config';
import { wait } from '../lib/websockets/webSocketHelpers';

const xgacMainApiUrl = XGAC_MAIN_API_URL;
const deviceRegistrationApiUrl = DEVICE_REGISTRATION_API_URL;

// this is the empty csv, which is downloaded when the user clicks on the download csv button
export const downloadEmptyCSV = () => {

  const defaultCSV: UploadedCSV[] = [
    {
      value_external_id: '',
      value_personal_available: 'FALSE',
      value_personal_firstname: '',
      value_personal_lastname: '',
      value_personal_phone_country: '',
      value_personal_phone_number: '',
      value_personal_locale: 'nl-NL',
      value_personal_timezone: 'europe/amsterdam',
      value_user_email: '',
      value_user_username: '',
      locked_external_id: 'FALSE',
      locked_personal_available: 'FALSE',
      locked_personal_firstname: 'FALSE',
      locked_personal_lastname: 'FALSE',
      locked_personal_phone_country: 'FALSE',
      locked_personal_phone_number: 'FALSE',
      locked_user_email: 'FALSE',
      locked_personal_timezone: 'FALSE',
      locked_personal_locale: 'FALSE',
      download_after_import: 'FALSE',
      register_after_import: 'FALSE',
    },
  ];

  jsonExport(defaultCSV, {
    rowDelimiter: ';',
  }, (err: any, csv: any) => {

    downloadCSV(csv, 'example_csv');

  });

};

// When a user uploads a csv, it is validated here
export const validateUploadedCsv = async (uploadedQrs: UploadedCSV[]) => {

  const errors: string[] = [];

  // Booleans in the csv can be "TRUE" or "FALSE"
  const validateBoolean = (value: string, fieldName: string, index: number) => {

    if (value !== 'TRUE' && value !== 'FALSE') {

      errors.push(`(Row ${index + 1}) Invalid value for ${fieldName}: ${value}, should be TRUE or FALSE`);

    }

  };

  const usernameCheckPromises: Promise<any>[] = [];
  const fileUserNames: string[] = [];

  uploadedQrs.forEach((qr, index) => {

    validateBoolean(qr.value_personal_available, 'value_personal_available', index);
    validateBoolean(qr.locked_external_id, 'locked_external_id', index);
    validateBoolean(qr.locked_personal_available, 'locked_personal_available', index);
    validateBoolean(qr.locked_personal_firstname, 'locked_personal_firstname', index);
    validateBoolean(qr.locked_personal_lastname, 'locked_personal_lastname', index);
    validateBoolean(qr.locked_personal_phone_country, 'locked_personal_phone_country', index);
    validateBoolean(qr.locked_personal_phone_number, 'locked_personal_phone_number', index);
    validateBoolean(qr.locked_user_email, 'locked_user_email', index);
    validateBoolean(qr.locked_personal_timezone, 'locked_personal_timezone', index);
    validateBoolean(qr.locked_personal_locale, 'locked_personal_locale', index);
    validateBoolean(qr.download_after_import, 'download_after_import', index);
    validateBoolean(qr.register_after_import, 'register_after_import', index);

    if (qr.value_personal_phone_country !== '') {

      if (qr.value_personal_phone_country.length !== 2 || Number.isNaN(parseInt(qr.value_personal_phone_number, 10))) {

        errors.push(`(Row ${index + 1}) Invalid value for value_personal_phone_country: ${qr.value_personal_phone_country} , should be 2 characters`);

      }

    }

    if (qr.value_personal_phone_number !== '') {

      if (Number.isNaN(parseInt(qr.value_personal_phone_number, 10))) {

        errors.push(`(Row ${index + 1}) Invalid value for value_personal_phone_number: ${qr.value_personal_phone_number}, should be a number`);

      }

    }
    if (qr.value_user_email !== '') {

      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(qr.value_user_email)) {

        errors.push(`(Row ${index + 1}) Invalid value for value_user_email: ${qr.value_user_email}, should be an email`);

      }

    }
    if (qr.value_user_username !== '') {

      if (fileUserNames.includes(qr.value_user_username)) {

        errors.push(`(Row ${index + 1}) Duplicate value for value_user_username: ${qr.value_user_username}`);

      } else {

        fileUserNames.push(qr.value_user_username);

      }

    }
    if (qr.register_after_import.toLowerCase() === 'true' && [
      qr.value_user_username,
      qr.value_personal_firstname,
      qr.value_user_email,
      qr.value_personal_phone_number,
      qr.value_personal_phone_country,
    ].includes('')) {

      errors.push(`(Row ${index + 1}) Not all values for registration are filled in`);

    }

    if (qr.register_after_import.toLowerCase() === 'true') {

      usernameCheckPromises.push(httpClient(`${xgacMainApiUrl}/users/check-username`, {
        method: 'POST',
      }, qr.value_user_username).catch((error) => {

        if (error.status === 409) {

          errors.push(`(Row ${index + 1}) Username ${qr.value_user_username} already exists`);

        }

      }));

    }

  });

  await Promise.allSettled(usernameCheckPromises);

  return errors;

};

export const RegisterUsers = async (
  usersToRegister: any[],
  setProgress?: React.Dispatch<React.SetStateAction<any>>,
  setTotalToRegister?: React.Dispatch<React.SetStateAction<any>>,
  downloadFiles = true,
) => {

  const registerUserPromises: Promise<any>[] = [];
  const registeredUsers: { username: string; name: string; email: string; phone_number: string }[] = [];
  const sequences: { username: string; sequenceId: string }[] = [];
  // send the registrations
  for (const qrCode of usersToRegister) {

    registerUserPromises.push(httpClient(
      `${deviceRegistrationApiUrl}/v3/deployment/code/activate`,
      { method: 'POST' },
      {
        code: qrCode.code,
        platform: 'android',
        uniqueId: Date.now().toString(36) + Math.random().toString(36).substring(2),
        variables: qrCode.variables.map((variable: any) => {

          return {
            key: variable.key,
            value: variable.value,
          };

        }),
      },
    ).then((response) => {

      // add the registration users to a list.
      registeredUsers.push({
        username: _.find(qrCode.variables, { key: 'register_username' }).value.data,
        name: `${_.find(qrCode.variables, { key: 'register_personal_firstname' }).value.data} ${
          _.find(qrCode.variables, { key: 'register_personal_lastname' }).value.data}`,
        email: _.find(qrCode.variables, { key: 'register_user_email' }).value.data,
        phone_number: _.find(qrCode.variables, { key: 'register_personal_phone_country' }).value.data
        + _.find(qrCode.variables, { key: 'register_personal_phone_number' }).value.data,
      });
      sequences.push({ username: _.find(qrCode.variables, { key: 'register_username' }).value.data, sequenceId: response.json.sequenceId });

    }));

  }

  await Promise.allSettled(registerUserPromises);

  if (registeredUsers.length > 0 && downloadFiles) {

    jsonExport(registeredUsers, {
    }, (err: any, csv: any) => {

      downloadCSV(csv, 'registered_users');

    });

  }

  if (sequences.length > 0) {

    const totalRegistrations = sequences.length;

    const registrationErrors: { failed: string }[] = [];

    // recursive. runs until all registrations are done, or 5000 seconds
    const checkSequences = async (sequencesToTry: { username: string; sequenceId: string }[], iteration: number) => {

      if (setProgress) {

        setProgress(totalRegistrations - sequencesToTry.length);

      }
      const registerStatusChecks = [];
      const retrySequences: { username: string; sequenceId: string }[] = [];

      for (const sequence of sequencesToTry) {

        if (registerStatusChecks.length < 10) {

          registerStatusChecks.push(httpClient(
            `${deviceRegistrationApiUrl}/v1/deployment/queue/sequence/${sequence.sequenceId}`,
            { method: 'GET' },
          ).then((response) => {

            if (response.json.error) {

              registrationErrors.push({ failed: sequence.username });

            }
            if (response.json.running && !response.json.error) {

              retrySequences.push(sequence);

            }

          }));

        } else {

          retrySequences.push(sequence);

        }

      }
      await Promise.allSettled(registerStatusChecks);
      if (retrySequences.length > 0 && iteration < 5000) {

        await wait(1000);
        await checkSequences(retrySequences, iteration + 1);

      }

    };

    if (setTotalToRegister) {

      setTotalToRegister(totalRegistrations);

    }
    await wait(2000);
    await checkSequences(sequences, 0);

    // download the errors, if there are any
    if (registrationErrors.length > 0 && downloadFiles) {

      jsonExport(registrationErrors, {
      }, (err: any, csv: any) => {

        downloadCSV(csv, 'registration_errors');

      });

    }

  }

};
