import { useRecordContext } from 'ra-core';
import { Button } from '@mui/material';
import { useRedirect, useTranslate } from 'react-admin';

export const GoToButtonsButton = () => {

  const redirect = useRedirect();
  const translate = useTranslate();
  let disabled = false;
  const record = useRecordContext();
  if (!record) disabled = true;

  const goToPage = () => {

    redirect(`/flic-buttons?hub=${record?.id}`);

  };
  return (
    <Button onClick={goToPage} disabled={disabled}>{translate('resources.flic-hubs.go_to_buttons')}</Button>
  );

};
