import { Link, useRecordContext, useTranslate } from 'react-admin';
import { Chip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SmsIcon from '@mui/icons-material/Sms';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import * as React from 'react';
import { ReactElement } from 'react';
import { get } from 'lodash';
import { getAlarmColor } from '../../../../utils/getAlarmColor';

const ContactListChip = (props: { icon: ReactElement; contactLists: { _ref: string; _id: string }[] }) => {

  const translate = useTranslate();

  return <Link to={{ pathname: '/contact-lists', search: `filter=${JSON.stringify({ _id: props.contactLists.map((list) => list._id).join() })}` }}>
    <Chip sx={{ margin: '3px' }} icon={props.icon} label={props.contactLists.length}
      clickable title={translate('resources.report-triggers.text.go_contactlist')}
    />
  </Link>;

};
export const ChannelField = (props: { source: string; label?: string }) => {

  const record = useRecordContext();
  const translate = useTranslate();
  const sourceChannels = get(record, props.source);
  const chips = [];
  for (const channel of sourceChannels) {

    if (channel.type === 'email') {

      if (channel.address?.length > 0) {

        chips.push(<Chip sx={{ margin: '3px' }} icon={<EmailIcon fontSize={'small'}/>} label={channel.address.length}
          title={channel.address.join('\n')}/>);

      }
      if (channel.contactLists?.length > 0) {

        chips.push(<ContactListChip icon={<ContactMailIcon fontSize={'small'}/>} contactLists={channel.contactLists}/>);

      }

    }
    if (channel.type === 'sms') {

      if (channel.numbers?.length > 0) {

        chips.push(<Chip sx={{ margin: '3px' }} icon={<SmsIcon fontSize={'small'}/>} label={channel.numbers.length}
          title={channel.numbers.join('\n')}/>);

      }

      if (channel.contactLists?.length > 0) {

        chips.push(<ContactListChip icon={<ContactPhoneIcon fontSize={'small'}/>} contactLists={channel.contactLists}/>);

      }

    }
    if (channel.type === 'alarm') {

      const color = getAlarmColor(channel.config?.severity);

      chips.push(<Chip sx={{ margin: '3px' }} icon={<GppMaybeIcon fontSize={'small'} sx={{ color: `${color} !important` }}/>}
        label={translate('general.text.alarm')}
        title={channel.config.name}
      />);

    }

  }
  return (
    <ul style={{ margin: '0' }}>
      {chips}
    </ul>
  );

};
