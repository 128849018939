import React from 'react';
import Select, { createFilter } from 'react-select';

import MenuList from './menuList';
import Option from './option';

import './reactSelect.scss';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '30px',
    boxShadow: null,
    overflow: 'hidden',
    fontSize: '14px',
    width: 300,
    borderRadius: 0,
    borderWidth: 0,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    minHeight: '30px',
    padding: '0 6px',
    overflow: 'hidden',
    borderRadius: 0,
    boxShadow: null,
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '30px',
    overflow: 'hidden',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    maxWidth: '150px',
  }),
};

const ReactSelect = (props: any) => {

  return (
    <Select
      {...props}
      styles={customStyles}
      className={'react-select'}
      filterOption={createFilter({ ignoreAccents: false })}
      // use to debug styling:
      // menuIsOpen={true}
      components={{
        MenuList,
        Option,
      }}
    />
  );

};

export default ReactSelect;
export * from 'react-select';
