export const transformer = {
  type: 'alarm',
  timestamp: '2022-11-11T13:21:54.619Z',
  properties: {
    level: '-15',
  },
  message: 'Test Melding',
  object: {
    type: 'asset',
    info: {
      customer: {
        id: '288d35ef-ff9a-4307-aeb8-7276fe4ab9c3',
        name: 'X-Guard',
      },
      properties: [
        {
          code: 'email',
          value: 'info@x-guard.nl',
        },
        {
          code: 'phone',
          value: '+31600000000',
        },
        {
          code: 'allowContact',
          value: true,
        },
        {
          code: 'sms',
          value: '+31600000000',
        },
      ],
      id: '90830166-8a1a-4389-a456-51f067b329b1',
      resourceId: '90830166-8a1a-4389-a456-51f067b329b1',
      name: 'Xander Guard',
    },
  },
  position: {
    latitude: 52.2850746393941,
    longitude: 6.780644303071974,
    floor: 0,
    accuracy: 35,
    speed: 0,
    altitude: 17.1,
    address: 'Welbergweg 50. 7556 PE, Hengelo OV',
    source: {
      type: 'gps/zonal',
    },
  },
  id: 'bf3dd6f4-e895-44fa-bf55-eac9efcd8302',
};

export const noTransformer = {
  // Can be one of the following: "create", "update", "remove"
  operation: 'create',
  document: {
    ack: {
      ackedBy: {
        _id: 'bac6381a-284b-4d16-b83a-4c76c8615d37',
        _ref: 'User',
      },
      ackedAt: null,
      value: true,
      comment: 'acknowledged',
      needed: true,
    },
    name: 'Test Melding',
    type: 'test',
    updatedAt: '2022-11-03T07:29:12.735Z',
    asset: {
      insideZones: [
        {
          _ref: 'Zone',
          _id: '9afd9cd5-859b-4848-8339-127fdd10c39b',
        },
        {
          _ref: 'Zone',
          _id: 'b561d98b-59d9-4eb8-828a-be3bc429f0c9',
        },
        {
          _id: 'c5ae4fbc-e413-416f-999e-b52ad106daa8',
          _ref: 'Zone',
        },
      ],
      customer: {
        _ref: 'Customer',
        _id: '288d35ef-ff9a-4307-aeb8-7276fe4ab9c3',
      },
      name: 'Xander Guard',
      lastObservationAt: '2022-11-03T07:25:49.541Z',
      app: {
        // "on", "off", "risk"
        mode: 'on',
      },
      propertiesOwnership: 'inherit',
      updatedAt: '2022-11-03T07:29:12.735Z',
      position: {
        coordinates: [
          6.780647502832087,
          52.28510287489096,
        ],
        properties: {
          altitudeAccuracy: 2,
          accuracy: 8.3,
          provider: 'gps/zonal',
          heading: 319.98,
          speedAccuracy: 1.4040000000000001,
          headingAccuracy: 9.35,
          dateTime: '2022-11-03T07:25:48.267Z',
          speed: 0,
          address: {
            countryCode: 'NL',
            floor: 0,
            state: 'Overijssel',
            streetNumber: '50',
            city: 'Hengelo',
            formattedAddress: 'X-Guard B.V. - Welbergweg 50. 7556 PE, Hengelo OV',
            streetName: 'Welbergweg',
          },
          altitude: 16.4,
        },
        type: 'Point',
      },
      assetGroups: [],
      available: true,
      properties: {
        smsNumber: '+31600000000',
        phoneNumber: '+31600000000',
        locale: 'en',
        email: 'info@x-guard.nl',
      },
      _id: '214847df-661d-4b39-8c47-14ed9eafab12',
      telluId: 84413545,
      user: {
        _ref: 'User',
        _id: '43a01f04-a0a2-445c-8223-9eb29f546ad4',
      },
      createdAt: '2022-11-02T16:15:41.554Z',
      invisibleResponder: false,
      staticResponders: [],
      share: {
        type: 'parents',
        extra: [],
      },
    },
    position: {
      properties: {
        // In km/h
        speed: 0,
        heading: 319.98,
        speedAccuracy: 1.4040000000000001,
        headingAccuracy: 9.35,
        dateTime: '2022-11-03T07:25:48.267Z',
        // In meters
        altitude: 16.4,
        altitudeAccuracy: 2,
        accuracy: 8.3,
        // Can be: "gps/zonal", "gps/reverse", "gps/cache", "ips", "observation"
        provider: 'gps/zonal',
        address: {
          countryCode: 'NL',
          city: 'Hengelo',
          state: 'Overijssel',
          floor: 0,
          streetNumber: '50',
          streetName: 'Welbergweg',
          formattedAddress: 'X-Guard B.V. Links - Welbergweg 50. 7556 PE, Hengelo OV',
        },
      },
      coordinates: [
        6.780647502832087,
        52.28510287489096,
      ],
      type: 'Point',
    },
    // The ref of the alarmcenter once the alarm is escalated.
    alarmCenter: null,
    createdAt: '2022-11-03T07:29:12.735Z',
    customer: {
      _ref: 'Customer',
      _id: '288d35ef-ff9a-4307-aeb8-7276fe4ab9c3',
    },
    procedure: '1. De gebruiker heeft een systeem testmelding verstuurd waarvoor geen verdere actie nodig is. '
      + '2. Gebruiker dient commentaarveld in webportaal in te (laten) vullen.',
    share: {
      type: 'parents',
      extra: [],
    },
    meta: {
      observationTrigger: {
        actions: [
          {
            type: 'alarm',
            config: {
              type: 'other',
              procedure: '1. De gebruiker heeft een systeem testmelding verstuurd waarvoor geen verdere actie nodig is.'
                + '2. Gebruiker dient commentaarveld in webportaal in te (laten) vullen.',
              name: 'Test Alarm',
              // Can be one of the following: "green", "orange", "red"
              severity: 'green',
            },
          },
        ],
        telluId: 81415263,
        createdAt: '2022-11-02T16:13:39.585Z',
        _id: '39602819-8ee4-419b-a02c-20b5d6a505e0',
        updatedAt: '2022-11-02T16:13:39.585Z',
        share: {
          type: 'parents',
          extra: [],
        },
        customer: {
          _ref: 'Customer',
          _id: '288d35ef-ff9a-4307-aeb8-7276fe4ab9c3',
        },
        filter: {
          operands: [],
          type: 'operator/and',
        },
        description: 'Groene knop ingedrukt',
        status: 'active',
        eventCodes: [],
        name: 'Alarm - Test melding',
      },
      observation: {
        beacons: [
          {
            dateTime: '2022-11-03T07:29:12.363Z',
            rssi: -55,
            valid: false,
            code: '21596.4642',
            calc: null,
            beacon: null,
          },
          {
            rssi: -72,
            dateTime: '2022-11-03T07:29:12.364Z',
            calc: 28,
            beacon: {
              _ref: 'Beacon',
              _id: '568855a2-7bbe-4bb6-848f-5acb4f7923c3',
            },
            code: '21010.14911',
            valid: false,
          },
          {
            beacon: null,
            rssi: -73,
            valid: false,
            code: '21010.51154',
            dateTime: '2022-11-03T07:29:12.364Z',
            calc: null,
          },
        ],
        customer: {
          _id: '288d35ef-ff9a-4307-aeb8-7276fe4ab9c3',
          _ref: 'Customer',
        },
        user: {
          _id: '43a01f04-a0a2-445c-8223-9eb29f546ad4',
          _ref: 'User',
        },
        meta: {
          dateTime: '2022-11-03T07:29:12.365Z',
        },
        asset: {
          user: {
            _ref: 'User',
            _id: '43a01f04-a0a2-445c-8223-9eb29f546ad4',
          },
          invisibleResponder: false,
          createdAt: '2022-11-02T16:15:41.554Z',
          available: true,
          name: 'Xander Guard',
          customer: {
            _ref: 'Customer',
            _id: '288d35ef-ff9a-4307-aeb8-7276fe4ab9c3',
          },
          app: {
            mode: 'on',
          },
          telluId: 84413545,
          _ref: 'Asset',
          updatedAt: '2022-11-03T07:25:49.579Z',
          _id: '214847df-661d-4b39-8c47-14ed9eafab12',
          insideZones: [
            {
              _id: '9afd9cd5-859b-4848-8339-127fdd10c39b',
              _ref: 'Zone',
            },
            {
              _ref: 'Zone',
              _id: 'b561d98b-59d9-4eb8-828a-be3bc429f0c9',
            },
            {
              _id: 'c5ae4fbc-e413-416f-999e-b52ad106daa8',
              _ref: 'Zone',
            },
          ],
          propertiesOwnership: 'inherit',
          position: {
            properties: {
              heading: 319.98,
              altitudeAccuracy: 2,
              provider: 'gps/zonal',
              headingAccuracy: 9.35,
              speedAccuracy: 1.4040000000000001,
              altitude: 16.4,
              dateTime: '2022-11-03T07:25:48.267Z',
              speed: 0,
              address: {
                streetNumber: '50',
                streetName: 'Welbergweg',
                formattedAddress: 'X-Guard B.V. - Welbergweg 50. 7556 PE, Hengelo OV',
                floor: 0,
                city: 'Hengelo',
                state: 'Overijssel',
                countryCode: 'NL',
              },
              accuracy: 8.3,
            },
            coordinates: [
              6.780647502832087,
              52.28510287489096,
            ],
            type: 'Point',
          },
          assetGroups: [],
          properties: {
            phoneNumber: '+31600000000',
            email: 'info@x-guard.nl',
            locale: 'en',
            smsNumber: '+31600000000',
          },
          lastObservationAt: '2022-11-03T07:25:49.541Z',
          staticResponders: [],
          share: {
            type: 'parents',
            extra: [],
          },
        },
        deltas: {
          steps: {
            context: {
              start: '2022-11-03T07:29:12.467Z',
              value: 36,
              end: '2022-11-03T07:29:12.503Z',
            },
            ips: {
              end: '2022-11-03T07:29:12.541Z',
              value: 64,
              start: '2022-11-03T07:29:12.477Z',
            },
          },
          main: {
            value: 200,
            end: '2022-11-03T07:29:12.608Z',
            start: '2022-11-03T07:29:12.408Z',
          },
        },
        _id: '10bee8ab-1df4-43d8-8ce1-e72ccbcc64d4',
        validPosition: {
          value: false,
          reason: 'No valid beacon found',
        },
        event: 'BEACON',
        device: {
          _id: '018303b5-2941-4ed6-bae6-e966930dc808',
        },
      },
    },
    _id: '723422a2-6b33-4639-a74a-2aee856564ec',
    // Can be one of the following: "green", "orange", "red"
    severity: 'green',
  },
  entity: 'Alarm',
};
