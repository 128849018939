import {
  AutocompleteArrayInput,
  CreateButton,
  EditButton,
  ReferenceArrayInput,
  required, SaveButton,
  SimpleForm,
  TextField, Toolbar,
  TopToolbar,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Datagrid, IfCanAccess } from '@react-admin/ra-rbac';
import { ListLive } from '@react-admin/ra-realtime';
import * as React from 'react';
import { AssetRef } from '@x-guard/xgac-types/xgac';
import { Typography } from '@mui/material';
import { LIST_DEBOUNCE } from '../../../config';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { AssetsInDialogButton } from '../../../components/buttons/assetsInDialogButton';
import SearchFields from '../components/fields/Filters';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { CreateToolbarRight } from '../../../components/CustomToolBar';
import { GrayTextInputWithUpperLabel } from '../components/inputs/GrayTextInputWithUpperLabel';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { AssetGroupDeleteButton } from '../../../components/buttons/assetGroupDeleteButton';

const transform = (data: any) => {

  return {
    ...data,
    assets: data.assets.map((record: string) => ({ _ref: 'Asset', _id: record } as AssetRef)),
  };

};

export const GroupList = () => {

  const translate = useTranslate();
  return (
    <>
      <h3>{translate('menu.items.groups')}</h3>
      <ListLive
        filters={SearchFields} debounce={LIST_DEBOUNCE} title="menu.items.groups"
        actions={<TopToolbar><CreateButton/></TopToolbar>}
        sort={{ field: 'name', order: 'ASC' }}
        filter={{
          name: '!BHV-Knoppen',
        }}
      >
        <>
          <Datagrid rowClick={false} bulkActionButtons={false}>
            <TextField source="name" label="general.fields.name"/>
            <AssetsInDialogButton label="resources.asset-groups.fields.asset_count"/>
            <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
            <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
            <IfCanAccess action="edit" resource="asset-groups">
              <EditButton/>
            </IfCanAccess>
          </Datagrid>
        </>
      </ListLive>
    </>
  );

};

export const GroupCreate = () => {

  const translate = useTranslate();

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable} />
      <CreateWithSuccess transform={transform} title="menu.items.groups">
        <SimpleForm toolbar={<CreateToolbarRight narrow/>} sx={{ maxWidth: '1000px' }}>
          <h4>{translate('resources.asset-groups.text.create_title')}</h4>
          <Typography variant="body1">{translate('resources.groups.text.bhvk-explanation')}</Typography>
          <hr/>
          <GrayTextInputWithUpperLabel source="name" validate={required()} label="general.fields.name"/>
          <ReferenceArrayInput source="assets" reference="app-users" fullWidth>
            <AutocompleteArrayInput
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              inputProps={{ style: { backgroundColor: '#f1f1f1', borderRadius: '5px' } }} sx={{
                '& fieldset': {
                  border: 'none',
                },
              }}
              label={'general.text.persons'}/>
          </ReferenceArrayInput>
        </SimpleForm>
      </CreateWithSuccess>
    </>);

};

const GroupEditFields = () => {

  const translate = useTranslate();
  const record = useRecordContext();

  return (
    <>
      <h4 style={{ marginBottom: '10px' }}>"{record?.name}" {translate('general.text.settings')}</h4>
      <Typography variant="body1">{translate('resources.groups.text.bhvk-explanation')}</Typography>
      <hr/>
      <GrayTextInputWithUpperLabel source="name" validate={required()} label="general.fields.name"/>
      <ReferenceArrayInput source="assets" reference="app-users" fullWidth>
        <AutocompleteArrayInput
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          inputProps={{ style: { backgroundColor: '#f1f1f1', borderRadius: '5px' } }} sx={{
            '& fieldset': {
              border: 'none',
            },
          }}
          label={'general.text.persons'}/>
      </ReferenceArrayInput>
    </>
  );

};

export const GroupEdit = () => {

  return (
    <>
      <EditWithSuccess transform={transform} title="menu.items.groups" actions={false}>
        <SimpleForm toolbar={
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <AssetGroupDeleteButton/>
            <SaveButton sx={{ ml: '8px' }}/>
          </Toolbar>}
        sx={{ maxWidth: '1000px' }}>
          <GroupEditFields/>
        </SimpleForm>
      </EditWithSuccess>
    </>);

};
