import { useNotify, useRecordContext, useTranslate } from 'react-admin';
import { get } from 'lodash';
import * as React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';
import { ReactElement } from 'react';
import TextFieldNullable from './TextFieldNullable';

export const ContactField = (props: any) => {

  const record = useRecordContext(props);
  const email = get(record, 'properties.email');
  const notify = useNotify();
  const translate = useTranslate();
  const phoneNumber = get(record, 'properties.phoneNumber');

  const copyValues = (value: string) => {

    copy(value);
    notify('general.text.copied');

  };

  if (!email && !phoneNumber) return <TextFieldNullable/>;
  const contactArray: ReactElement[] = [];
  if (email) {

    contactArray.push(
      <div style={{ display: 'flex', alignItems: 'start' }}>
        <span className="privacyBlur">
          <span>
            {email}
          </span>
        </span>
        <IconButton onClick={() => copyValues(email)} sx={{
          display: 'inline', height: 'fit-content', marginTop: 'auto', marginBottom: 'auto',
        }} size="small">
          <Tooltip title={translate('general.text.copy')}>
            <ContentCopyIcon sx={{ fontSize: '12px' }}/>
          </Tooltip>
        </IconButton>
      </div>,
    );

  }
  if (phoneNumber) {

    contactArray.push(
      <div style={{ display: 'flex', alignItems: 'start' }}>
        <span className="privacyBlur">
          <span>
            {phoneNumber}
          </span>
        </span>
        <IconButton onClick={() => copyValues(phoneNumber)} sx={{
          display: 'inline', height: 'fit-content', marginTop: 'auto', marginBottom: 'auto',
        }} size="small">
          <Tooltip title={translate('general.text.copy')}>
            <ContentCopyIcon sx={{ fontSize: '12px' }}/>
          </Tooltip>
        </IconButton>
      </div>,
    );

  }

  return <div>
    {contactArray}
  </div>;

};
