import React from 'react';
import {
  Page, Text, Document, View, Image, StyleSheet,
} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  image: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    objectFit: 'fill',
  },
  qrcode: {
    width: 240,
    height: 240,
    position: 'absolute',
    top: 287,
    left: '50%',
    marginLeft: (240 / 2) * -1,
  },
  brochureQrcode: {
    width: 100,
    height: 100,
    top: 335,
    left: '50%',
    marginLeft: (100 / 2) * -1,
  },
  name: {
    position: 'absolute',
    fontSize: 12,
    top: 551,
    width: '100%',
    textAlign: 'center',
  },
  brochureName: {
    top: 335 + 100 + 3,
  },
});

// Create Document Component
const Poster = (props: any) => {

  const renderCodeMeta = (code: any) => {

    // check unlimited
    if (code.limit === 1) {

      // loop variables, find best choice
      const variables: any = {};
      for (const variable of code.variables) {

        // set
        variables[variable.key] = variable.value.data;

      }

      // check
      const meta = variables.register_user_email
        || `${variables.register_personal_phone_country}${variables.register_personal_phone_number}`
        || `${variables.register_personal_firstname} ${variables.register_personal_lastname}`;
      // done
      return meta;

    }

    // unlimited
    return code.templateName;

  };

  if (props.posterMode === 'default') {

    return <Document creator={'X-Guard B.V.'} producer={'X-Guard B.V.'} title={'QR Poster'}>
      <Page size={'A4'} fixed={true}>
        <Image
          style={styles.image}
          fixed={true}
          src="/assets/img/poster_base.png"
        />
        <Image
          style={styles.qrcode}
          fixed={true}
          src={props.code.qrCode}
        />
        <Text fixed={true} style={styles.name}>{renderCodeMeta(props.code)}</Text>
      </Page>
    </Document>;

  } if (props.posterMode === 'brochure_nl') {

    return <Document creator={'X-Guard B.V.'} producer={'X-Guard B.V.'} title={'QR Poster'}>
      <Page wrap size={'A4'}>
        <Image
          style={styles.image}
          src="/assets/img/poster_base_v3_nl_page_1.png"
        />
      </Page>
      <Page wrap size={'A4'}>
        <View style={styles.image}>
          <Image
            style={styles.image}
            src="/assets/img/poster_base_v3_nl_page_2.png"
          />
        </View>
        <Image
          style={[styles.qrcode, styles.brochureQrcode]}
          src={props.code.qrCode}
        />
        <Text style={[styles.name, styles.brochureName]}>{renderCodeMeta(props.code)}</Text>
      </Page>
    </Document>;

  } if (props.posterMode === 'brochure_en') {

    return <Document creator={'X-Guard B.V.'} producer={'X-Guard B.V.'} title={'QR Poster'}>
      <Page wrap size={'A4'}>
        <Image
          style={styles.image}
          src="/assets/img/poster_base_v3_en_page_1.png"
        />
      </Page>
      <Page wrap size={'A4'}>
        <View style={styles.image}>
          <Image
            style={styles.image}
            src="/assets/img/poster_base_v3_en_page_2.png"
          />
        </View>
        <Image
          style={[styles.qrcode, styles.brochureQrcode]}
          src={props.code.qrCode}
        />
        <Text style={[styles.name, styles.brochureName]}>{renderCodeMeta(props.code)}</Text>
      </Page>
    </Document>;

  }

  return <span></span>;

};

export default Poster;
