import { useEffect, useState } from 'react';
import { AnyGeometry } from '@x-guard/xgac-types/xgac';
import { useLocaleState } from 'ra-core';
import { AddBuildingDialog } from '../components/AddBuildingDialog';

export const BuildingCalculation = () => {

  const [openStep, setOpenStep] = useState<null | number>(0);
  const [, setSelectedBuildings] = useState<AnyGeometry[] | null>(null);
  const [locale, setLocale] = useLocaleState();

  useEffect(() => {

    if (locale !== 'nl') {

      setLocale('nl');

    }

  }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <AddBuildingDialog openStep={openStep} setOpenStep={setOpenStep} setChosenBuilding={setSelectedBuildings} data={[]} fullPage/>
    </div>
  );

};
