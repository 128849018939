import { ChipField, useGetRecordId } from 'react-admin';
import { useEffect, useState } from 'react';
import { xgacDataProvider } from '../../dataProviders/xgacDataProvider';
import TextFieldNullable from './TextFieldNullable';

export const AlarmCenterField = () => {

  const [alarmCenters, setAlarmCenters] = useState([]);

  const recordId = useGetRecordId();
  useEffect(() => {

    const getAlarmCenters = async () => {

      setAlarmCenters(await xgacDataProvider.getAlarmCenters(recordId));

    };

    getAlarmCenters();

  }, [recordId]);

  if (alarmCenters && alarmCenters.length > 0) {

    return (<ul style={{ margin: '0' }}>
      {alarmCenters.map((item: any) => (
        <ChipField source="item" record={{ item }}/>
      ))}
    </ul>);

  }
  return <TextFieldNullable/>;

};
