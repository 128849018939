export const getCurrentCustomer = (): { value: string; label: string } | null => {

  const currentCustomerString = sessionStorage.getItem('currentCustomer') || localStorage.getItem('lastSavedCustomer');

  if (currentCustomerString) {

    return JSON.parse(currentCustomerString);

  }

  const allCustomersString = localStorage.getItem('customers');
  if (allCustomersString) {

    const allCustomers = JSON.parse(allCustomersString);
    if (allCustomers.length) {

      return allCustomers[0];

    }

  }

  return null;

};
