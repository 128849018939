import { FixedSizeList as List } from 'react-window';

const OPTION_HEIGHT = 40;
const ROWS = 6;

const MenuList = ({
  options,
  children,
  getValue,
}: any) => {

  const [value] = getValue();
  // eslint-disable-next-line no-nested-ternary
  const initialOffset = options.indexOf(value) !== -1
    // eslint-disable-next-line no-nested-ternary
    ? Array.isArray(children)
        && children.length >= ROWS
      ? options.indexOf(value) >= ROWS
        ? options.indexOf(value)
              * OPTION_HEIGHT
            - OPTION_HEIGHT * 5
        : 0
      : 0
    : 0;

  return Array.isArray(children) ? (
    <List
      height={
        children.length >= ROWS
          ? OPTION_HEIGHT * ROWS
          : children.length * OPTION_HEIGHT
      }
      width={'100'}
      itemCount={children.length}
      itemSize={OPTION_HEIGHT}
      initialScrollOffset={initialOffset}
    >
      {({ style, index }: any) => {

        return (
          <div style={style}>
            {children[index]}
          </div>
        );

      }}
    </List>
  ) : (
    <div>{children}</div>
  );

};

export default MenuList;
