import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  EditButton,
  List,
  Loading,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  useLocaleState,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {
  useLocation, useNavigate,
} from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import socketIOClient from 'socket.io-client';
import * as React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Input,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import moment from 'moment/moment';
import NumberFieldNullable from '../../../components/fields/NumberFieldNullable';
import ReferenceFieldNullable from '../../../components/fields/ReferenceFieldNullable';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { DEVICE_REGISTRATION_API_URL } from '../../../config';
import { CustomToolbar } from '../../../components/CustomToolBar';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { HasRoles } from '../../../components/HasRoles';
import authProvider from '../../../utils/authProvider';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/en-gb';
import { httpClient } from '../../../utils/httpClient';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';

const Grid = Grid2;

const createTransform = (data: Record<string, any>) => {

  const hubId = localStorage.getItem('hubId');
  return {
    ...data,
    hub: hubId,
  };

};

const editTransform = (data: Record<string, any>) => {

  return {
    name: data.name,
    xgacAssetId: data.xgacAssetId,
  };

};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TestModeButton = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  if (!record) return null;

  const setTestMode = (toEmpty: boolean) => {

    setLoading(true);

    const testModeTill = toEmpty ? 0 : new Date(new Date().getTime() + 30 * 60000);
    httpClient(`${DEVICE_REGISTRATION_API_URL}/v3/flic/button/${record.id}?xgacCustomerId=${getCurrentCustomer()?.value}`, {
      method: 'PATCH',
    }, { testModeTill }).then(() => {

      setLoading(false);
      refresh();

    }).catch(() => {

      setLoading(false);

    });

  };

  const hasTestMode = record.testModeTill;
  if (loading) {

    return (
      <LinearProgress/>
    );

  }
  if (hasTestMode && new Date(hasTestMode).getTime() > new Date().getTime()) {

    return (
      <Button
        variant="contained"
        color='error'
        size="small"
        onClick={() => setTestMode(true)}
      >
        {translate('resources.flic-buttons.text.test_mode_active_until')}: {moment(hasTestMode).format('D MMMM, h:mm:ss')}
      </Button>
    );

  }
  return (
    <Button
      variant="contained"
      onClick={() => setTestMode(false)}
      size="small"
    >
      {translate('resources.flic-buttons.text.activate_test_mode')}
    </Button>
  );

};
export const FlicButtonList = () => {

  const navigate = useNavigate();
  const notify = useNotify();
  const roles = authProvider.getRoles();
  const currentLocale = useLocaleState();
  moment.locale(currentLocale[0]);

  function useQuery() {

    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);

  }
  const params = useQuery();

  const hub = params.get('hub') || localStorage.getItem('hubId') || null;
  if (!hub || hub === 'undefined') {

    navigate('/flic-hubs');
    notify('resources.flic-buttons.text.invalid_hub_id', { type: 'error' });

  } else {

    localStorage.setItem('hubId', hub);

  }

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <List filter={{ hub }} title="resources.flic-buttons.text.title">
        <Datagrid rowClick="toggleSelection" bulkActionButtons={roles.includes('admin') ? undefined : false}>
          <TextField source="name" label="general.fields.name" />
          <TextField source="action" />
          <TextField source="serial" />
          <NumberFieldNullable source="battery" />
          <ReferenceFieldNullable source="xgacAssetId" reference="assets" label="resources.assets.text.single_name" />
          <BooleanField source="deleted" />
          <TestModeButton label="resources.flic-buttons.text.test_mode"/>
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
          <HasRoles anyOf={['admin']}>
            <EditButton/>
          </HasRoles>
        </Datagrid>
      </List>
    </>
  );

};

export const FlicButtonEdit = () => {

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta}/>
      <EditWithSuccess title="resources.flic-buttons.text.title" transform={editTransform} redirect={-1}>
        <SimpleForm toolbar={<CustomToolbar redirect={-1}/>}>
          <TextInput source="name" label="general.fields.name" />
          <ReferenceInput source="xgacAssetId" reference="assets" label="resources.assets.text.single_name" sort={{ field: 'name', order: 'ASC' }}>
            <AutocompleteInput label="resources.assets.text.single_name" autoHighlight={true}/>
          </ReferenceInput>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const FlicButtonCreate = () => {

  const translate = useTranslate();
  const [receivedData, setReceivedData] = useState();
  const socket = socketIOClient(DEVICE_REGISTRATION_API_URL);
  socket.emit('subscribe', 'flicButtonGrab');

  useEffect(() => {

    socket.emit('subscribe', 'flicButtonGrab');
    socket.on('message', (data: any) => {

      if (data.objectName === 'flicButtonGrab') {

        setReceivedData(data.data);

      }

    });
    socket.connect();
    return () => {

      socket.off('connect');
      socket.off('disconnect');
      socket.off('flicButtonGrab');

      socket.disconnect();

    };

  }, [socket]);

  if (receivedData) {

    return (
      <>
        <StabilityAlert stability={StabilityLevel.Beta}/>
        <CreateWithSuccess title="resources.flic-buttons.text.title" transform={createTransform}>
          <Typography variant="h5" sx={{ width: '100%', textAlign: 'center', paddingTop: '5px' }}>
            {translate('resources.flic-buttons.text.found_button')}
          </Typography>
          <SimpleForm defaultValues={receivedData}>
            <TextInput source="serial" disabled/>
            <TextInput source="name" label="general.fields.name" />
            <ReferenceInput source="xgacAssetId" reference="all-assets" label="resources.assets.text.single_name" sort={{ field: 'name', order: 'ASC' }}>
              <AutocompleteInput label="resources.assets.text.single_name" autoHighlight={true}/>
            </ReferenceInput>
          </SimpleForm>
        </CreateWithSuccess>
      </>
    );

  }
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta}/>
      <Grid container spacing={2}>
        <Grid md={6}>
          <Card>
            <CardHeader title={<span style={{ fontSize: '1rem' }}>{translate('resources.flic-buttons.text.config.title')}</span>}>
            </CardHeader>
            <CardContent>
              <TableContainer>
                <Table sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      <th colSpan={2}>{translate('resources.flic-buttons.text.config.single_click_title')}</th>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.description')}</TableCell>
                      <TableCell>{translate('resources.flic-buttons.text.config.single_click_description')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.hub_action')}</TableCell>
                      <TableCell>{translate('resources.flic-buttons.text.config.internet_request')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.url')}</TableCell>
                      <TableCell>
                        <Input
                          type="text"
                          onFocus={(event) => event.target.select()}
                          readOnly
                          value={`${DEVICE_REGISTRATION_API_URL}/flic/button/status`}
                          fullWidth />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.method')}</TableCell>
                      <TableCell>{translate('resources.flic-buttons.text.config.method_post')}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      <th colSpan={2}>{translate('resources.flic-buttons.text.config.double_click_title')}</th>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.description')}</TableCell>
                      <TableCell>{translate('resources.flic-buttons.text.config.double_click_description')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.hub_action')}</TableCell>
                      <TableCell>{translate('resources.flic-buttons.text.config.internet_request')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.url')}</TableCell>
                      <TableCell>
                        <Input
                          type="text"
                          onFocus={(event) => event.target.select()}
                          readOnly
                          value={`${DEVICE_REGISTRATION_API_URL}/flic/button/status`}
                          fullWidth />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.method')}</TableCell>
                      <TableCell>{translate('resources.flic-buttons.text.config.method_post')}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      <th colSpan={2}>{translate('resources.flic-buttons.text.config.hold_title')}</th>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.description')}</TableCell>
                      <TableCell>{translate('resources.flic-buttons.text.config.hold_description')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.hub_action')}</TableCell>
                      <TableCell>{translate('resources.flic-buttons.text.config.internet_request')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.url')}</TableCell>
                      <TableCell>
                        <Input
                          type="text"
                          onFocus={(event) => event.target.select()}
                          readOnly
                          value={`${DEVICE_REGISTRATION_API_URL}/flic/button/status`}
                          fullWidth />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{translate('resources.flic-buttons.text.config.method')}</TableCell>
                      <TableCell>{translate('resources.flic-buttons.text.config.method_post')}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid md={6}>
          <Loading loadingPrimary="resources.flic-buttons.text.waiting_for_press" loadingSecondary="" sx={{ height: '100%' }}></Loading>
        </Grid>
      </Grid>
    </>
  );

};
