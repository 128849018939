import {
  memo, useEffect, useState,
} from 'react';
import queryString from 'query-string';
import { useGetIdentity, useRefresh } from 'react-admin';
import { BhvKnopMarketingWebsiteFrame } from './bhvKnopMarketingWebsiteFrame';
import { getCurrentCustomer } from '../../../lib/currentCustomer';

export const BhvKnopOrdersFrame = memo(() => {

  const [lastCurrentCustomer, setLastCurrentCustomer] = useState<string | null>(null);

  const identity = useGetIdentity();
  const currentCustomer = getCurrentCustomer();
  useEffect(() => {

    if (currentCustomer && lastCurrentCustomer !== currentCustomer?.value) {

      setLastCurrentCustomer(currentCustomer?.value);

    }

  }, [currentCustomer?.value, identity]); // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = useRefresh();
  const { search } = window.location;
  const searchObject = queryString.parse(search);
  const query = queryString.stringify({
    order_id: searchObject.order_id,
    customerId: lastCurrentCustomer,
  });

  return <BhvKnopMarketingWebsiteFrame
    page={searchObject.order_id ? `bestellingen/details/?${query}` : 'bestellingen'}
    disableCustomerId={!!searchObject.order_id}
    onMessage={(event) => {

      // set history without redirecting
      refresh();
      window.history.pushState({}, '', event.data.currentPath);

    }}
  />;

});
