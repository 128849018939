import {
  GetListParams,
  Identifier,
} from 'react-admin';
import queryString from 'query-string';
import {
  CreateParams, DeleteManyParams, DeleteParams, GetManyParams, GetOneParams, UpdateParams,
} from 'ra-core';
import { DEVICE_REGISTRATION_API_URL } from '../config';
import { httpClient } from '../utils/httpClient';
import { updateComparison } from './updateComparison';
import { getCurrentCustomer } from '../lib/currentCustomer';

const apiUrl = DEVICE_REGISTRATION_API_URL;

function translateResource(resource: string) {

  if (resource === 'flic-hubs') return 'flic/hub';
  if (resource === 'flic-buttons') return 'flic/button';
  if (resource === 'domainlinks') return 'deployment/domainlink';
  return resource;

}
export const legacyMainDataProvider = {

  getList: async (resource: string, params: GetListParams) => {

    if (!params.pagination) {

      params.pagination = { page: 1, perPage: 100 };

    }
    if (!params.sort) {

      params.sort = { field: 'createdAt', order: 'DESC' };

    }
    resource = translateResource(resource);
    let { perPage } = params.pagination;
    const { page } = params.pagination;
    perPage = perPage > 100 ? 100 : perPage;
    const { field, order } = params.sort;
    const filter = params.filter;
    const customer = JSON.parse(sessionStorage.getItem('currentCustomer') || '');

    const query = {
      sort: `${((order === 'DESC') ? '-' : '')}${field}`,
      offset: (page - 1) * perPage,
      limit: perPage,
      ...(['flic/hub', 'flic/button'].includes(resource) ? { xgacCustomerId: customer.value } : {}),
      ...filter,
    };

    const url = `${apiUrl}/v3/${resource}?${queryString.stringify(query)}`;

    const result = await httpClient(url).then(({ json }) => ({
      data: json.result.map((record: Record<string, unknown>) => (
        {
          id: record._id,
          ...record,
          ...(record.xgacCustomerId ? { customer: { _ref: 'Customer', _id: record.xgacCustomerId } } : {}),
        }
      )),
      total: json.total,
    }));

    return result;

  },

  getOne: async (resource: string, params: GetOneParams) => {

    resource = translateResource(resource);
    const currentCustomer = getCurrentCustomer();
    const query = `xgacCustomerId=${currentCustomer?.value}`;

    const result = await httpClient(`${apiUrl}/v3/${resource}/${params.id}?${query}`);

    return {
      data: {
        id: result.json._id,
        ...result.json,
        ...(result.json.xgacCustomerId ? { customer: { _ref: 'Customer', _id: result.json.xgacCustomerId } } : {}),
      },
    };

  },
  getMany: async (resource: string, params: GetManyParams) => {

    resource = translateResource(resource);
    const currentCustomer = getCurrentCustomer();
    let query = `xgacCustomerId=${currentCustomer?.value}`;
    for (const id of params.ids) {

      query += `&id[]=${id}`;

    }

    const url = `${apiUrl}/v3/${resource}?${query}`;
    return httpClient(url).then(({ json }) => ({
      data: json.result.map((record: Record<string, unknown>) => (
        {
          id: record._id,
          ...record,
          ...(record.xgacCustomerId ? { customer: { _ref: 'Customer', _id: record.xgacCustomerId } } : {}),
        }
      )),
      total: json.total,
    }));

  },
  create: async (resource: string, params: CreateParams) => {

    resource = translateResource(resource);

    const currentCustomer = getCurrentCustomer();
    const query = `xgacCustomerId=${currentCustomer?.value}`;
    const body = {
      ...params.data,
      ...(!['flic/button', 'deployment/domainlink'].includes(resource) ? { xgacCustomerId: currentCustomer?.value } : {}),
    };

    const createResult = await httpClient(`${apiUrl}/v3/${resource}?${query}`, {
      method: 'POST',
    }, body).then(({ json }) => ({
      data: { ...params.data, id: json._id },
    }));

    return createResult;

  },

  update: async (resource: string, params: UpdateParams) => {

    params.data = updateComparison(params.data, params.previousData);
    const currentCustomer = getCurrentCustomer();
    const query = `xgacCustomerId=${currentCustomer?.value}`;

    resource = translateResource(resource);
    return httpClient(`${apiUrl}/v3/${resource}/${params.id}?${query}`, {
      method: 'PATCH',
    }, { ...params.data }).then(({ json }) => ({ data: { ...json, id: json._id } }));

  },

  delete: (resource: string, params: DeleteParams) => {

    resource = translateResource(resource);
    const currentCustomer = getCurrentCustomer();
    const query = `xgacCustomerId=${currentCustomer?.value}`;
    return httpClient(`${apiUrl}/v3/${resource}/${params.id}?${query}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));

  },

  deleteMany: async (resource: string, params: DeleteManyParams): Promise<any> => {

    resource = translateResource(resource);
    const deletedIds: Identifier[] = [];
    for (const currentId of params.ids) {

      try {

        await httpClient(`${apiUrl}/v3/${resource}/${currentId}`, {
          method: 'DELETE',
        });
        deletedIds.push(currentId);

      } catch (e) {

        console.log({ message: `failed to delete ${currentId}`, e });

      }

    }

    return { data: deletedIds };

  },

};
