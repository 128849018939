import Cookies from 'js-cookie';
// eslint-disable-next-line import/no-cycle
import { refreshContext } from './authProvider';

export const cookieOrStorageRefreshToken = async () => {

  const token = Cookies.get('redirect_refreshToken');
  if (token) {

    const domain = `.${window.location.hostname.split('.').slice(-2).join('.')}`;

    localStorage.setItem('refreshToken', token);
    Cookies.remove('redirect_refreshToken', { domain, path: '/' });
    return token;

  }

  return localStorage.getItem('refreshToken');

};
export const cookieOrStorageToken = async (needsNewRoles = true) => {

  const token = Cookies.get('redirect_token');

  if (token) {

    await cookieOrStorageRefreshToken();
    const domain = `.${window.location.hostname.split('.').slice(-2).join('.')}`;

    localStorage.removeItem('lastSavedCustomer');
    sessionStorage.removeItem('currentCustomer');

    localStorage.setItem('token', token);
    Cookies.remove('redirect_token', { domain, path: '/' });
    if (needsNewRoles) {

      await refreshContext(token);

    }
    window.location.reload();

  }

  return localStorage.getItem('token');

};
