import {
  Button,
  Datagrid,
  DateField,
  Identifier,
  Link,
  List,
  TextField, useGetOne,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { useEffect, useState } from 'react';
import {
  Dialog, DialogContent, DialogTitle, IconButton, Tooltip,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { ReportTriggerChannelResult, ReportTriggerRun } from '@x-guard/xgac-types/xgac';
import { get } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import { ChannelField } from '../components/fields/ReportChannelField';

export const AttachmentModal = (props: { source: string; label: string; buttonLabel: string; record?: ReportTriggerRun | null }) => {

  const raRecord = useRecordContext();
  const record = props.record || raRecord;
  const translate = useTranslate();
  const sourceAttachments = get(record, props.source);
  const [disabled, setDisabled] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attachmentUrls, setAttachmentUrls] = useState([]);
  const [currentAttachment, setCurrentAttachment] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  useEffect(() => {

    setDisabled(true);
    setAttachmentUrls([]);
    if (sourceAttachments && sourceAttachments?.length > 0) {

      const urls = sourceAttachments.filter((attachment: ReportTriggerChannelResult) => {

        return attachment.success && attachment.data?.savedEmailUrl;

      }).map((attachment: ReportTriggerChannelResult) => {

        return attachment.data?.savedEmailUrl;

      });

      if (urls.length > 0) {

        setAttachmentUrls(urls);
        setDisabled(false);
        setCurrentAttachment(urls[0]);

      }

    }

  }, [sourceAttachments]);

  return (
    <>
      <Button startIcon={<AttachFileIcon/>} disabled={disabled} onClick={() => setOpen(true)} label={props.buttonLabel} variant="contained"/>
      <Dialog PaperProps={{
        sx: {
          minHeight: '80vh',
          maxHeight: '80vh',
        },
      }}
      open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle className="flex-in-between">
          <span>{translate('resources.reports.text.attached_email')}</span>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: 'flex' }}>
          <iframe
            style={{
              width: '100%',
              flex: '1',
              border: 'none',
              display: 'block',
              overflow: 'hidden',
            }}
            title="email"
            src={currentAttachment || ''}
          />
        </DialogContent>
      </Dialog>
    </>
  );

};

const ReportTriggerReferenceField = (props: { label: string }) => {

  const record: ReportTriggerRun & { id: Identifier } | undefined = useRecordContext();
  const translate = useTranslate();
  const translatedType = translate(`resources.report-triggers.text.config_types.${record?.reportTrigger.config.type}`);
  const { isLoading, data } = useGetOne('report-triggers', { id: record?.reportTrigger._id });

  if (!record || !record.reportTrigger) {

    return (
      <Tooltip title={translate('resources.report-triggers.text.not-exists')} placement={'top-start'}>
        <div>
          <TextField source="value" record={{ value: translate('resources.report-triggers.text.not-exists') }} label={props.label}/>
        </div>
      </Tooltip>
    );

  }
  if (isLoading || !data) {

    return (
      <Tooltip title={translate('resources.report-triggers.text.not-exists')} placement={'top-start'}>
        <div>
          <TextField source="value" record={{ value: translatedType }} label={props.label}/>
        </div>
      </Tooltip>
    );

  }
  return (
    <Link to={`/report-triggers/${record.reportTrigger._id}`}>
      <TextField source="value" record={{ value: translatedType }} label={props.label}/>
    </Link>
  );

};

export const ReportTriggerRunList = () => (
  <List title="resources.report-trigger-runs.title"
    sort={{ field: 'createdAt', order: 'DESC' }}
    queryOptions={{
      meta: {
        $select: 'channels,createdAt,config,reportTrigger,channelResults',
      },
    }}>
    <Datagrid rowClick="toggleSelection" bulkActionButtons={false}>
      <ReportTriggerReferenceField label="resources.report-triggers.text.title"/>
      <ChannelField source="reportTrigger.channels" label="resources.report-triggers.fields.channels"/>
      <DateField source="createdAt" label="general.fields.createdAt" showTime />
      <AttachmentModal source='channelResults' label="resources.reports.fields.attachments" buttonLabel="resources.reports.text.show_email"/>
    </Datagrid>
  </List>
);
