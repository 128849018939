import { useEffect, useState } from 'react';
import {
  email,
  FormDataConsumer,
  Identifier,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  useGetIdentity,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { User } from '@x-guard/xgac-types/xgac';
import {
  Dialog, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import { Edit } from '@react-admin/ra-rbac';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { UsernameInput } from './inputs/UsernameInput';

const usernameFormTransform = (data: any) => {

  return {
    username: data.username,
  };

};

export const UsernameModal = () => {

  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [lastUserId, setLastUserId] = useState<Identifier | null>(null);
  const { data, refetch } = useGetIdentity();
  const userId = data?.id;
  const refresh = useRefresh();
  const [user, setUser] = useState<User | null>(null);
  const [formValid, setFormValid] = useState(true);

  const CustomSaveButton = () => {

    const { formState } = useFormContext();
    const { isSubmitting } = formState;

    return <>
      <FormDataConsumer>
        {({ formData }) => {

          let disabled = !formValid || isSubmitting;
          if (formData.username === user?.username) {

            disabled = false;

          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return <SaveButton resource="users" alwaysEnable={formData.username === user?.username} disabled={disabled} icon={null} label="general.text.proceed"
            sx={{ float: 'right' }}/>;

        }}
      </FormDataConsumer>
    </>;

  };

  useEffect(() => {

    if (!userId) {

      return;

    }
    if (lastUserId !== userId) {

      xgacDataProvider.getOne('users', { id: userId }).then((returnedUser) => {

        setUser(returnedUser.data as User & { id: Identifier });

      });
      setLastUserId(userId);

    }

  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    const localStorageState = localStorage.getItem(`${userId}_usernameChanged`);

    if (localStorageState !== null || !user) {

      return;

    }
    if (user.username === user.properties.email) {

      return;

    }
    const currentTimestamp = new Date().getTime();
    const createdAtTimestamp = new Date(user.createdAt || 0).getTime();
    if (currentTimestamp - createdAtTimestamp > 1000 * 60 * 60) {

      return;

    }
    setOpen(true);

  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {

    setOpen(false);
    refresh();
    localStorage.setItem(`${userId}_usernameChanged`, 'true');

  };
  return <>
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle bottom="0">
        {translate('general.text.change_username_modal_title')}
      </DialogTitle>
      <DialogContent >
        <Typography variant="body1">
          {translate('general.text.current_username')}:
        </Typography>
        <Edit resource="users" mutationMode="pessimistic" id={userId} actions={false} transform={usernameFormTransform} mutationOptions={{
          onSuccess: () => {

            if (refetch) {

              refetch();

            }
            handleClose();

          },
        }}>
          <SimpleForm
            toolbar={
              <>
                <span style={{ color: 'gray' }}><small>{translate('general.text.current')}: {user?.username}</small></span>
                <CustomSaveButton />
              </>}
            sx={{ padding: '0' }} >
            <TextInput
              source="properties.email"
              fullWidth
              validate={[email(), required()]}
              color="info"
              sx={{ display: 'none' }}
            />
            <UsernameInput sx={{ marginTop: '10px' }} fromEmail={false} initialUsername={user?.username} setFormValid={setFormValid} />
          </SimpleForm>
        </Edit>
      </DialogContent>
    </Dialog>
  </>;

};
