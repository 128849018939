import { ChipField, useGetRecordId, useTranslate } from 'react-admin';
import { useEffect, useState } from 'react';
import { xgacDataProvider } from '../../dataProviders/xgacDataProvider';
import TextFieldNullable from './TextFieldNullable';

export const UserRoleChipField = () => {

  const translate = useTranslate();
  const [roles, setRoles] = useState([]);

  const recordId = useGetRecordId();
  useEffect(() => {

    const getRoles = async () => {

      const rolesRequest = await xgacDataProvider.getRolesForUser(recordId);
      setRoles(rolesRequest.data.map((item: string) => translate(`resources.users.text.roles.${item}`) || item));

    };

    getRoles();

  }, [recordId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (roles && roles.length > 0) {

    return (<ul style={{ margin: '0' }}>
      {roles.map((item: string) => (
        <ChipField source="item" record={{ item }}/>
      ))}
    </ul>);

  }
  return <TextFieldNullable/>;

};
