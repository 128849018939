import { AlarmSeverity } from '@x-guard/xgac-types/xgac';

export const getAlarmColor = (severity: AlarmSeverity) => {

  let color = '#378589';

  if (severity === 'orange') color = '#EA6329';
  if (severity === 'red') color = '#D0382A';

  return color;

};
