import { AuthProvider } from 'react-admin';
import axios from 'axios';
import _ from 'lodash';
import { xgacRequest } from './xgacRequest';
import { XGAC_AUTH_API_URL, XGAC_AUTH_CLIENT_ID } from '../config';
// eslint-disable-next-line import/no-cycle
import { cookieOrStorageRefreshToken, cookieOrStorageToken } from './cookieToken';

// init flag
let loginInProgress = false;

// Used for refreshing the context on page reload, and on page initialisation.
export const refreshContext = async (token?: string) => {

  if (window.location.pathname === '/calculation') {

    return {};

  }
  // Get the auth token
  const authToken = token || localStorage.getItem('token');

  xgacRequest.defaults.headers.common.Authorization = `Bearer ${authToken}`;

  // Get the context from the main API
  const context = (await xgacRequest.get('/context')).data;

  // map the customers to a format that the select can understand.
  const customers = context.customerTreeFlat.map((record: Record<string, unknown>) => ({ value: record._id, label: record.name }));
  const roles = [];

  for (const relation of context.customerUserRelations) {

    roles.push({ roles: relation.roles, customer: relation.customer._id });

  }

  const savedCurrentCustomer = sessionStorage.getItem('currentCustomer') || localStorage.getItem('lastSavedCustomer');
  if (!savedCurrentCustomer) {

    const firstCustomer = context.customerTreeFlat[0];
    localStorage.setItem('lastSavedCustomer', JSON.stringify({ value: firstCustomer._id, label: firstCustomer.name }));
    sessionStorage.setItem('currentCustomer', JSON.stringify({ value: firstCustomer._id, label: firstCustomer.name }));

  }

  // save
  localStorage.setItem('context', JSON.stringify(context));
  localStorage.setItem('customers', JSON.stringify(customers));
  localStorage.setItem('roles', JSON.stringify(roles));

  return context;

};

export const getRoles = () => {

  // get context
  const contextString = localStorage.getItem('context');
  if (!contextString) {

    return [];

  }

  // get current customer
  const currentCustomerString = sessionStorage.getItem('currentCustomer') || localStorage.getItem('lastSavedCustomer');
  if (!currentCustomerString) {

    const customers = JSON.parse(localStorage.getItem('customers') || '[]');
    if (customers.length > 0) {

      sessionStorage.setItem('currentCustomer', JSON.stringify(customers[0]));

    }
    return [];

  }

  // parse
  const context = JSON.parse(contextString);
  const currentCustomer = JSON.parse(currentCustomerString);

  // get roles by relation
  const relation = context.customerUserRelations.find((customerUserRelation: any) => {

    // check if it's the correct id
    return customerUserRelation.customer._id === currentCustomer.value;

  }) || { roles: [] };

  // get global roles
  let globalRoles = context.user.globalRoles;
  if (sessionStorage.getItem('simulateCustomerAdmin') === 'true') {

    globalRoles = ['customer_admin', 'map_viewer'];
    relation.roles = relation.roles.filter((role: string) => !['developer_admin', 'admin'].includes(role));

  }

  return [...new Set([
    ...relation.roles,
    ...globalRoles,
  ])];

};

export const refreshAuthToken = async () => {

  try {

    const token = await cookieOrStorageRefreshToken();
    const refreshRequest = await xgacRequest({
      method: 'POST',
      url: `${XGAC_AUTH_API_URL}/auth/refresh`,
      data: {
        refreshToken: token,
      },
    });

    localStorage.setItem('token', refreshRequest.data.accessToken);
    localStorage.setItem('refreshToken', refreshRequest.data.refreshToken);

    // save for future requests as header
    xgacRequest.defaults.headers.common.Authorization = `Bearer ${refreshRequest.data.accessToken}`;

  } catch (e) {

    console.log('Failed to refresh', e);

  }

};

const authProvider: AuthProvider = {

  async login(params: { code: string | null; credentials?: { username: string; key: string } } | null) {

    if (params?.credentials) {

      try {

        const tokenRequest = await axios.post(`${XGAC_AUTH_API_URL}/auth/login/hashed`, {
          username: params.credentials.username,
          password: params.credentials.key,
        });
        localStorage.setItem('token', tokenRequest.data.accessToken);
        localStorage.setItem('refreshToken', tokenRequest.data.refreshToken);
        xgacRequest.defaults.headers.common.Authorization = `Bearer ${tokenRequest.data.accessToken}`;

        // fetch the context
        await refreshContext();
        window.location.href = '/';

      } catch (e) {

        window.location.href = '/login';
        return;

      }
      return;

    }
    if (params == null || params.code === null) {

      // redirect
      // eslint-disable-next-line max-len
      window.location.href = `${XGAC_AUTH_API_URL}/auth/login?clientId=${XGAC_AUTH_CLIENT_ID}&redirectUri=${window.location.protocol}//${window.location.host}/login`;

      // stop here
      return;

    }

    if (loginInProgress === true) {

      throw new Error('Login in progress');

    }

    loginInProgress = true;

    // eslint-disable-next-line max-len
    const tokens = await axios.get(`${XGAC_AUTH_API_URL}/auth/callback/${XGAC_AUTH_CLIENT_ID}?code=${params.code}&redirectUri=${encodeURIComponent(`${window.location.protocol}//${window.location.host}/login`)}`);
    localStorage.setItem('token', tokens.data.access_token);
    localStorage.setItem('refreshToken', tokens.data.refresh_token);

    // save for future requests as header
    xgacRequest.defaults.headers.common.Authorization = `Bearer ${tokens.data.access_token}`;

    // fetch the context
    await refreshContext();

    // stop
    loginInProgress = false;

  },

  logout: () => {

    // remove tokens and context
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('token');
    localStorage.removeItem('context');
    localStorage.removeItem('permissions');
    localStorage.removeItem('customers');

    // redirect
    // eslint-disable-next-line max-len
    window.location.href = `${XGAC_AUTH_API_URL}/auth/logout?clientId=${XGAC_AUTH_CLIENT_ID}&redirectUri=${window.location.protocol}//${window.location.host}/login`;
    return new Promise(() => {});
    // done

  },

  checkError: async ({ status }) => {

    // throw een error wanneer er iets mis is met de status
    if (status === 401 || status === 403) {

      throw new Error('Not authorised');

    }

  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {

    // haal token op
    const token = await cookieOrStorageToken();
    if (authProvider?.getPermissions) {

      await authProvider.getPermissions({});

    }

    xgacRequest.defaults.headers.common.Authorization = `Bearer ${token}`;

    // throw error (reject) wanneer we niet ingelogd zijn
    if (token !== null) {

      return;

    }

    throw new Error('Not authorised');

  },

  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => {

    const permissionString = localStorage.getItem('permissions');

    if (permissionString) {

      return JSON.parse(permissionString);

    }

    // haal de context string op
    const rawContext = localStorage.getItem('context');

    if (rawContext === null) {

      // geen rollen
      return [];

    }
    // parse de context string
    const context = JSON.parse(rawContext);
    if (context.permissions[0].subject === 'all') {

      const allPermission = [{ resource: '*', action: '*' }];
      localStorage.setItem('permissions', JSON.stringify(allPermission));
      return allPermission;

    }

    // map de permissies die gevonden zijn in de context string
    let permissions = context.permissions.map((record: { subject: string; action: any; conditions: any }) => {

      // Format de permissie resource van hoofdletters naar streepjes
      const formatResource = (resource: string) => {

        const splittedResource = resource.split(/(?=[A-Z])/);
        const joinedResource = splittedResource.join('-');

        return `${joinedResource.toLowerCase()}s`;

      };

      // maak een array van de acties als het dat nog niet is
      if (!Array.isArray(record.action)) {

        record.action = [record.action];

      }

      let formattedAction: string[] = [];

      // Ga over elke actie, en voeg de juiste React Admin syntax toe aan de lijst met permissies
      record.action.forEach((action: string) => {

        switch (action) {

        case 'read':
          formattedAction.push('read');
          formattedAction.push('list');
          formattedAction.push('show');
          break;
        case 'create':
          formattedAction.push('create');
          formattedAction.push('write');
          break;
        case 'update':
          formattedAction.push('edit');
          break;

        default:
          formattedAction.push(action);

        }

      });

      // Kijk of de permissie manage is, want dan mag alles
      if (_.isEqual(record.action, ['manage'])) {

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore react admin types are wrong
        formattedAction = '*';

      }

      // geef een goed geformat permissie object terug
      return { resource: formatResource(record.subject), action: formattedAction };

    });

    const userPermissionActions = [];
    const devicePermissionActions = [];
    const assetPermissionActions = [];

    // voeg ook lees-permissie voor de sub-velden van een resource toe, plus vertaal resources waar nodig.
    for (const permission in permissions) {

      if (permissions[permission].resource === 'assets' || permissions[permission].resource === 'users' || permissions[permission].resource === 'devices') {

        permissions.push({ ...permissions[permission], resource: 'buttons' });
        permissions.push({ ...permissions[permission], resource: `${permissions[permission].resource}/without-app` });
        permissions.push({ action: 'read', resource: `${permissions[permission].resource}/without-app.*` });

      }
      if (permissions[permission].resource === 'devices') {

        devicePermissionActions.push(...permissions[permission].action);

      }
      if (permissions[permission].resource === 'users') {

        userPermissionActions.push(...permissions[permission].action);

      }
      if (permissions[permission].resource === 'assets') {

        assetPermissionActions.push(...permissions[permission].action);

      }

      permissions.push({ action: 'read', resource: `${permissions[permission].resource}.*` });

    }

    const appUserPermission = { resource: 'app-users', action: [] };
    if (userPermissionActions.includes('*') && devicePermissionActions.includes('*') && assetPermissionActions.includes('*')) {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore react admin types are wrong
      appUserPermission.action = '*';

    } else {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore react admin types are wrong
      appUserPermission.action = _.union(userPermissionActions, devicePermissionActions, assetPermissionActions);

    }
    const roles = authProvider.getRoles();
    if (roles.includes('customer_admin') || roles.includes('bhvk_admin')) {

      permissions.push({ action: ['read', 'list', 'show'], resource: 'templates' });

    }
    if (roles.includes('app-user')) {

      permissions = [];

    }
    if (roles.includes('flic_hub_tester')) {

      permissions.push({ action: ['read', 'list', 'show'], resource: 'flic-hubs' });
      permissions.push({ action: ['read', 'list', 'show'], resource: 'flic-buttons' });

    }

    permissions.push(appUserPermission);
    permissions.push({ ...appUserPermission, resource: 'app-users.*' });

    localStorage.setItem('permissions', JSON.stringify(permissions));
    return permissions;

  },

  getIdentity: async () => {

    const rawContext = localStorage.getItem('context');

    if (rawContext === null) {

      // geen rollen
      throw new Error('No context');

    }

    // haal de context op
    // sla de context op, voor later gebruik
    const context = JSON.parse(rawContext);
    let userToDisplay = context.user.name;
    const customersString = localStorage.getItem('customers');
    const customers = JSON.parse(customersString || '[]');
    if (customers && customers.length === 1) {

      if (customers[0].label.startsWith('[BHV-Knop.nl]')) {

        userToDisplay = `${customers[0].label.substring(14)} / ${context.user.name}`;

      } else {

        userToDisplay = `${customers[0].label} / ${context.user.name}`;

      }
      sessionStorage.setItem('currentCustomer', JSON.stringify(customers[0]));

    }

    return {
      id: context.user._id,
      fullName: userToDisplay,
      properties: context.user.properties,
    };

  },

  isAdmin: () => {

    const simulateCustomerAdmin = sessionStorage.getItem('simulateCustomerAdmin');
    const contextString = localStorage.getItem('context');
    if (!contextString) return false;
    const context = JSON.parse(contextString);
    const adminRole = context.user.globalRoles.indexOf('admin') > -1;
    if (adminRole) {

      if (simulateCustomerAdmin === 'true') {

        return false;

      }
      return true;

    }
    return adminRole;

  },
  isAdminSimulation: () => {

    const contextString = localStorage.getItem('context');
    if (!contextString) return false;
    const context = JSON.parse(contextString);
    const adminRole = context.user.globalRoles.indexOf('admin') > -1;
    return adminRole;

  },

  isDeveloperAdmin: () => {

    const contextString = localStorage.getItem('context');
    if (!contextString) return false;
    const context = JSON.parse(contextString);
    return context.user.globalRoles.indexOf('developer_admin') > -1;

  },

  isCustomerAdmin: () => {

    const simulateCustomerAdmin = localStorage.getItem('simulateCustomerAdmin');
    if (simulateCustomerAdmin === 'true') {

      return true;

    }
    return getRoles().includes('customer_admin');

  },

  getRoles: (): string[] => {

    return getRoles();

  },

};

export default authProvider;
