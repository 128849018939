import { Toolbar } from 'react-admin';
import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight, FirstPage } from '@mui/icons-material';
import * as React from 'react';

export const AuditLogPagination = (props: {
  onFirstPage: () => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
  disabled?: boolean;
}) => {

  return (
    <Toolbar sx={{
      display: 'flex',
      justifyContent: 'flex-end',
    }}>
      <IconButton
        disabled={props.disabled}
        onClick={(e) => {

          e.preventDefault();
          e.stopPropagation();
          props.onFirstPage();

        }}
        size="small"><FirstPage/></IconButton>
      <IconButton
        disabled={props.disabled}
        onClick={(e) => {

          e.preventDefault();
          e.stopPropagation();
          props.onPreviousPage();

        }}
        size="small"><ChevronLeft/></IconButton>
      <IconButton
        disabled={props.disabled}
        onClick={(e) => {

          e.preventDefault();
          e.stopPropagation();
          props.onNextPage();

        }}
        size="small"><ChevronRight/></IconButton>
    </Toolbar>
  );

};
