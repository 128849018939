import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  Button,
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  email,
  FieldProps,
  FormDataConsumer,
  Link,
  ReferenceArrayInput,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import * as React from 'react';
import {
  Dispatch,
  SetStateAction, useEffect, useMemo, useState,
} from 'react';
import {
  Button as MuiButton,
  Checkbox,
  InputLabel,
  Typography,
} from '@mui/material';
import _, { get } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomerUserRelation, User } from '@x-guard/xgac-types/xgac';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { ReferenceOneInput } from '@react-admin/ra-relationships';
import moment from 'moment';
import { useGetList } from 'ra-core';
import { ListLive } from '@react-admin/ra-realtime';
import SearchFields from '../components/fields/Filters';
import { LIST_DEBOUNCE, XGAC_MAIN_API_URL } from '../../../config';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { StyledButton } from '../buttons/StyledButton';
import { httpClient } from '../../../utils/httpClient';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import ReferenceFieldNullable from '../../../components/fields/ReferenceFieldNullable';
import { GrayTextInputWithUpperLabel } from '../components/inputs/GrayTextInputWithUpperLabel';
import { BooleanCheckBoxInput } from '../components/inputs/BooleanCheckBoxInput';
import { refreshContext } from '../../../utils/authProvider';
import { PhoneNumberInput } from '../../../components/inputs/PhoneNumberInput';
import { UsernameInput } from '../components/inputs/UsernameInput';
import { ResetPasswordButton } from '../../../components/buttons/ResetPasswordButtons';
import { RegistrationCodePicker } from '../../../components/RegistrationCodePicker';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import ReferenceArrayFieldNullable from '../../../components/fields/ReferenceArrayFieldNullable';

const apiUrl = XGAC_MAIN_API_URL;

const Grid = Grid2;
const assetUserTransformer = (asset: any) => {

  return {
    ...asset,
    available: asset.available || false,
    username: asset.username || asset.properties.email,
    properties: {
      ...asset.properties,
      smsNumber: asset.properties?.phoneNumber,
      locale: asset.properties?.locale || 'nl',
    },
  };

};

const assetUserEditTransformer = (asset: any) => {

  if (asset.assetGroups) {

    asset.assetGroups = asset.assetGroups.map((group: any) => {

      return {
        _id: group,
        _ref: 'AssetGroup',
      };

    });

  }
  return {
    ...asset,
    properties: {
      ...asset.properties,
      smsNumber: asset.properties?.phoneNumber,
      locale: asset.properties?.locale || 'nl',
    },
  };

};

const BooleanSetterField = (props: {
  label: string;
  source: string;
  lastUpdated: Date | null;
  setLastUpdated: Dispatch<SetStateAction<Date | null>>;
}) => {

  const refresh = useRefresh();
  const record = useRecordContext();
  const initialValue = get(record, props.source);
  const translate = useTranslate();
  const [value, setValue] = useState(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

    if (!record) {

      return;

    }
    if (props.source === 'isAdmin') {

      return;

    }
    setIsSaving(true);
    setValue(event.target.checked);
    const patchData = {
      [props.source]: event.target.checked,
    };
    await xgacDataProvider.update('assets', { id: record.id, data: patchData, previousData: record });
    setIsSaving(false);
    refresh();

  };
  useEffect(() => {

    const newValue = get(record, props.source);
    if (newValue !== value) {

      setValue(newValue);

    }
    if (record) {

      if (!props.lastUpdated) {

        props.setLastUpdated(new Date(record.updatedAt));
        return;

      }
      if (new Date(record.updatedAt) > props.lastUpdated) {

        props.setLastUpdated(new Date(record.updatedAt));

      }

    }

  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isSaving) {

    return (
      <>
        <CircularProgress size={20}/>
      </>
    );

  }

  if (props.source === 'available' && !record?.properties.phoneNumber) {

    return (
      <>
        <Checkbox checked={false} sx={{ paddingLeft: 0 }} disabled={true} />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <EditButton label="resources.app-users.text.fill_phonenumber" color="secondary" icon={false} resource="assets"/>
      </>
    );

  }

  return (
    <>
      <Checkbox checked={value} sx={{ paddingLeft: 0 }} onChange={handleChange} />
      <span>{translate(value ? 'general.text.yes' : 'general.text.no')}</span>
    </>
  );

};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsAdminBooleanSetterField = (props: FieldProps & { changeHandler?: any }) => {

  const record = useRecordContext();
  const refresh = useRefresh();
  const [relation, setRelation] = useState<CustomerUserRelation | null>(null);
  const [value, setValue] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const loggedInUserId = useGetIdentity().data?.id;
  const disabled = !record || !record.user?._id || record.user?._id === loggedInUserId;
  const translate = useTranslate();

  useEffect(() => {

    if (!relation && record?.user?._id && !disabled) {

      const getRelation = async () => {

        setIsSaving(true);

        const relationRequest = await httpClient(`${apiUrl}/customer-user-relations?${queryString.stringify({
          'user._id': record.user._id,
          'customer._id': record.customer._id,
        })}`);

        setRelation(relationRequest.json?.result[0] || null);
        const rolesHasBhvkOrAdmin = ['bhvk_admin', 'bhvk_trial'].some((role: string) => relationRequest.json?.result[0]?.roles.includes(role));
        setValue(rolesHasBhvkOrAdmin);
        setIsSaving(false);

      };
      getRelation();

    }

  }, [disabled, record, relation]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

    setIsSaving(true);
    if (!relation) {

      setIsSaving(false);
      return;

    }
    setValue(event.target.checked);
    const patchData = {
      roles: event.target.checked ? [...relation.roles, 'bhvk_admin'] : relation.roles.filter((role: string) => ['bhvk_admin', 'bhvk_trial'].includes(role)),
    };
    const newRelation = await xgacDataProvider.update('customer-user-relations', { id: relation._id, data: patchData, previousData: relation });

    if (event.target.checked) {

      await httpClient(`${apiUrl}/users/reset-password/${relation.user._id}?bhvk=true&admin_role_assigned=true`);

    }
    setRelation(newRelation.data);
    setIsSaving(false);
    refresh();

    if (props.changeHandler) {

      props.changeHandler();

    }

  };

  if (isSaving) {

    return (
      <>
        <CircularProgress size={20}/>
      </>
    );

  }

  return (
    <>
      <Checkbox checked={value} onChange={handleChange} sx={{ paddingLeft: 0 }} disabled={disabled}/>
      <span>{translate(value ? 'general.text.yes' : 'general.text.no')}</span>
    </>
  );

};

const AssetActions = () => {

  const redirect = useRedirect();
  const translate = useTranslate();
  return (
    <>
      <StyledButton
        onClick={() => {

          redirect('/help/de-app/hoe-installeer-je-de-bhv-knop-nl-app/');

        }}
        label="general.text.install_app"
        sx={{
          whiteSpace: 'nowrap', paddingLeft: '30px', paddingRight: '30px', marginBottom: '5px', marginRight: '10px',
        }}
        color="primary"
      />
      <>
        <MuiButton
          variant={'contained'}
          color={'secondary'}
          size={'small'}
          component={Link}
          to={{
            pathname: '/assets/create',
          }}
          sx={{
            whiteSpace: 'nowrap', paddingLeft: '30px', paddingRight: '30px', marginBottom: '5px',
          }}
          disabled={false}
        >
          {translate('resources.assets.text.add_user')}
        </MuiButton>
      </>
    </>
  );

};
const AssetCounters = (props: {
  lastUpdated: Date | null;
}) => {

  const translate = useTranslate();

  const [availableCount, setAvailableCount] = useState(0);
  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const identity = useGetIdentity();
  const currentCustomer = getCurrentCustomer()?.value;

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer);

    }

  }, [identity, currentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    xgacDataProvider.getList(
      'all-assets',
      {
        pagination: { page: 1, perPage: 1 },
        filter: { available: true, 'user._ref': 'User', 'properties.phoneNumber': '!null' },
        sort: { field: 'name', order: 'ASC' },
      },
    ).then((response) => {

      setAvailableCount(response.total || 0);

    });

  }, [lastSavedCustomer, props.lastUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className="assetCounter-card">
    <Typography variant="h4" gutterBottom>
      {availableCount}
    </Typography>
    <Typography variant="caption" gutterBottom>
      {translate('resources.assets.text.number_of_available_users')}
    </Typography>
  </div>;

};

const AssetHasAppField = (props: FieldProps) => {

  const record = useRecordContext();
  if (!record) {

    return null;

  }
  const lastObservationAt = record.lastObservationAt;
  if (!lastObservationAt) {

    return <BooleanField record={{ source: false }} source="source" label={props.label} />;

  }
  const onlineThreshold = moment(record.lastObservationAt).add(1, 'week');

  const isApp = moment.utc().isBefore(onlineThreshold);
  return <BooleanField record={{ source: isApp }} source="source" label={props.label} />;

};

export const AssetList = () => {

  const translate = useTranslate();
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const currentCustomer = getCurrentCustomer()?.value;
  const identity = useGetIdentity();
  const { data } = useGetList('app-users', {
    pagination: { page: 1, perPage: -1 },
    sort: { field: 'name', order: 'ASC' },
    filter: {},
    meta: {
      $select: 'assetGroups',
    },
  });

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer);
      setLastUpdated(null);

    }

  }, [identity, currentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasGroupsField = useMemo(() => {

    if (!data) {

      return false;

    }
    const groups = data?.map((asset: { assetGroups: string[] }) => asset.assetGroups);
    return !groups.every((group: string[]) => {

      return _.isEqual(group, groups[0]);

    });

  }, [data]);
  return (
    <>
      <h3>{translate('resources.users.text.title')}</h3>
      <p>{translate('resources.users.text.sub_title')}</p>
      <AssetCounters lastUpdated={lastUpdated}/>
      <ListLive actions={<AssetActions/>}
        filters={SearchFields}
        debounce={LIST_DEBOUNCE}
        title="resources.assets.text.without_app_title"
        resource="all-assets"
        filter={{ 'user._ref': 'User', type: '!kiosk' }}
        empty={false}
      >
        <Datagrid rowClick={false} bulkActionButtons={false} resource="assets">
          <TextField source="name" label="general.fields.name"/>
          <ReferenceFieldNullable source="user._id" reference="users" label="resources.users.fields.username">
            <TextField source="username"/>
          </ReferenceFieldNullable>
          {hasGroupsField && (
            <ReferenceArrayFieldNullable source="assetGroups" reference="asset-groups" label="resources.asset-groups.name"/>
          )}
          <IsAdminBooleanSetterField source={''} label="resources.users.text.access_to_dashboard"/>
          <BooleanSetterField label="resources.users.text.receives_alarms" source="available" lastUpdated={lastUpdated} setLastUpdated={setLastUpdated}/>
          <AssetHasAppField source="lastObservationAt" label="resources.assets.fields.has_app"/>
          <EditButton resource="assets" label="general.text.more_settings" />
        </Datagrid>
      </ListLive>
    </>
  );

};

const ResetButtonPreparer = (props: { updatedAt: Date | null }) => {

  const record = useRecordContext();
  const [user, setUser] = useState<User | null>(null);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  useEffect(() => {

    if (!user && record?.user?._id) {

      xgacDataProvider.getOne('users', { id: record.user?._id }).then((response) => {

        setUser(response.data as unknown as User);

      });

    }

  }, [record?.user?._id, user]);
  useEffect(() => {

    if (!record || !record.user?._id) {

      return;

    }
    httpClient(`${apiUrl}/customer-user-relations?${queryString.stringify({ 'user._id': record.user._id })}`).then((response) => {

      if (response?.json.result[0]?.roles) {

        setUserRoles(response.json.result[0].roles);

      }

    });

  }, [record, props.updatedAt]);

  return (
    <>
      {(userRoles.includes('admin') || userRoles.includes('bhvk_admin') || userRoles.includes('bhvk_trial')) ? (
        <ResetPasswordButton user={user} disabled={false} bhvk={true}/>
      ) : (
        <ResetPasswordButton user={user} disabled={true} bhvk={true}/>
      )}

    </>
  );

};

const AssetDeleteButton = () => {

  const notify = useNotify();
  const redirect = useRedirect();
  const loggedInUser = useGetIdentity().data?.id;
  const record = useRecordContext();
  const translate = useTranslate();
  const customMessage = `${translate('ra.action.delete')} ${record?.name}`;

  const onSuccess = () => {

    notify('resources.assets.text.bhvk_deleted');
    redirect('/assets');

  };

  return (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
    <DeleteWithConfirmButton icon={false}
      variant={'contained'}
      color="secondary"
      resource="app-users"
      confirmTitle={customMessage}
      mutationOptions={{ onSuccess }}
      label={'resources.assets.text.delete_user'}
      sx={{ color: 'white', height: 'fit-content', marginTop: '10px' }}
      size="medium" disabled={!record || record.user?._id === loggedInUser}
    />
  );

};

const AssetEditForm = (editProps: { setNeedsContextRefresh: SetStateAction<any> }) => {

  const record = useRecordContext();
  const [updatedAt, setUpdatedAt] = useState<Date | null>(null);
  const identity = useGetIdentity();
  const groupsRequest = useGetList('asset-groups', {
    pagination: { page: 1, perPage: 1 },
    sort: { field: 'name', order: 'ASC' },
    filter: { name: '!BHV-Knoppen' },
    meta: {
      $select: 'name',
    },
  });
  useEffect(() => {

    if (identity.data?.id === record?.user?._id) {

      editProps.setNeedsContextRefresh(true);

    }

  }, [identity, editProps, record]);
  const navigate = useNavigate();
  const AssetToolBar = (props: any) => {

    return (
      <Toolbar {...props} sx={{ justifyContent: 'end', maxWidth: '1000px' }} >

        <Button label="ra.action.back" onClick={() => navigate(-1)} size="medium" sx={{ marginRight: '10px' }} variant="text"/>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <SaveButton icon={null} color="primary"/>
      </Toolbar>
    );

  };
  const translate = useTranslate();

  if (!record) {

    return null;

  }
  return (
    <SimpleForm toolbar={<AssetToolBar/>} sx={{ maxWidth: '1000px' }}>
      <h4 style={{ marginBottom: '10px' }}>"{record.name}" {translate('general.text.settings')}</h4>
      <hr/>
      <Typography variant="h6" gutterBottom marginTop={'10px'}>
        {translate('resources.assets.text.properties')}
      </Typography>
      <Grid container spacing={3}>
        <Grid md={6}>
          <GrayTextInputWithUpperLabel
            source="name"
            label="general.fields.name"
            fullWidth
            validate={required()}
          />
          <GrayTextInputWithUpperLabel
            source="properties.email"
            label="resources.assets.fields.email"
            fullWidth
            validate={email()}
          />
        </Grid>
        <Grid md={6}>
          <InputLabel className="input-label">
            {translate('resources.users.fields.username')}
          </InputLabel>
          <ReferenceOneInput reference="users" target="_id" source="userId" fullWidth>
            <GrayTextInputWithUpperLabel source="username" fullWidth label="" disabled/>
          </ReferenceOneInput>
          <PhoneNumberInput source="properties.phoneNumber" fullWidth colorVariant="gray" required/>
        </Grid>
        {(groupsRequest.data?.length || 0) > 0 && (
          <Grid md={12}>
            <InputLabel className="input-label">
              {translate('menu.items.groups')}
            </InputLabel>
            <ReferenceArrayInput
              source="assetGroups"
              reference="asset-groups"
              label="menu.items.groups"
              fullWidth
              filter={{ name: '!BHV-Knoppen' }}
            >
              <AutocompleteArrayInput fullWidth label={false} color='info'
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                inputProps={{ style: { backgroundColor: '#f1f1f1', borderRadius: '5px' } }} sx={{
                  '& fieldset': {
                    border: 'none',
                  },
                }}
              />
            </ReferenceArrayInput>
          </Grid>
        )}
      </Grid>
      <hr/>
      <Typography variant="h6" gutterBottom marginTop={'10px'}>
        {translate('resources.qr-templates.text.options')}
      </Typography>
      <div style={{ width: '100%' }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid md={6}>
            <InputLabel className="input-label">
              {translate('resources.users.text.access_to_dashboard')}
            </InputLabel>
            <IsAdminBooleanSetterField source={''} label="resources.users.text.access_to_dashboard" changeHandler={() => {

              setUpdatedAt(new Date());

            }}/>
          </Grid>
          <Grid md={6}>
            <FormDataConsumer>
              {({ formData }) => {

                return (
                  <BooleanCheckBoxInput
                    source="available"
                    label="resources.users.text.receives_alarms"
                    disabled={!formData.properties?.phoneNumber || formData.properties?.phoneNumber === '+31'}
                    disabledText={translate('resources.app-users.text.fill_phonenumber')}
                  />
                );

              }}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </div>
      <hr/>
      <Typography variant="h6" gutterBottom marginTop={'10px'}>
        {translate('general.text.actions')}
      </Typography>
      <ResetButtonPreparer updatedAt={updatedAt}/>
      <AssetDeleteButton/>
      <hr style={{ marginTop: '20px' }}/>
    </SimpleForm>
  );

};

export const AssetEdit = () => {

  const notify = useNotify();
  const refresh = useRefresh();
  const { refetch } = useGetIdentity();
  const [needsContextRefresh, setNeedsContextRefresh] = useState(false);
  const editSuccess = async () => {

    notify('resources.users.text.saving_successful');
    if (needsContextRefresh) {

      await refreshContext();
      if (refetch) {

        refetch();

      }

    }
    refresh();

  };
  return (
    <EditWithSuccess actions={false} transform={assetUserEditTransformer} mutationOptions={{ onSuccess: editSuccess }}>
      <AssetEditForm setNeedsContextRefresh={setNeedsContextRefresh}/>
    </EditWithSuccess>
  );

};

export const AssetCreate = () => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [code, setCode] = useState<string | null>(null);
  const assetCreateOnSuccess = (data: any) => {

    if (data.error) {

      notify(data.message, { type: 'error' });
      return;

    }
    notify('ra.notification.created', { messageArgs: { smart_count: 1 } });
    redirect('edit', 'assets', data.id, data);

  };

  const translate = useTranslate();
  return (
    <Create
      transform={assetUserTransformer}
      actions={false}
      mutationOptions={{ meta: { needsUserCreation: true, code }, onSuccess: assetCreateOnSuccess }}
    >
      <SimpleForm>
        <RegistrationCodePicker setCode={setCode}/>
        <Typography variant="h6" gutterBottom>
          {translate('general.fields.name')}
        </Typography>
        <TextInput
          source="name"
          label="general.fields.name"
          fullWidth
          color="info"
          validate={required()}
        />
        <Typography variant="h6" gutterBottom>
          {translate('resources.assets.text.properties')}
        </Typography>
        <PhoneNumberInput
          source="properties.phoneNumber"
          label="resources.assets.fields.phoneNumber"
          fullWidth
          color="info"
          colorVariant="normal"
          required
        />
        <TextInput
          source="properties.email"
          label="resources.assets.fields.email"
          fullWidth
          validate={[email(), required()]}
          color="info"
        />
        <UsernameInput/>
        <BooleanInput source="available" label="resources.users.text.receives_alarms" name="available" defaultValue={true} defaultChecked={true}/>
        <BooleanInput label="resources.users.text.access_to_dashboard" source="isAdmin"/>
      </SimpleForm>
    </Create>
  );

};
