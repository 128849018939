import { Card, Typography } from '@mui/material';
import {
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  AutocompleteInput,
  Datagrid,
  BooleanField,
  TextField,
  List,
  useRecordContext,
  ChipField,
  Button,
  useCreatePath,
  Link,
  DateField,
  SaveButton,
  Toolbar,
  ReferenceField,
  useTranslate,
  CreateButton,
  TopToolbar,
  FormDataConsumer,
  useGetIdentity,
  useRedirect,
} from 'react-admin';
import _, { get } from 'lodash';
import * as React from 'react';
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import { ListLive } from '@react-admin/ra-realtime';
import {
  AlarmSeverity, AlarmType, Asset, AssetGroupRef,
} from '@x-guard/xgac-types/xgac';
import { useEffect, useMemo, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import CircularProgress from '@mui/material/CircularProgress';
import Grid2 from '@mui/material/Unstable_Grid2';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { XGAC_ALARM_MANAGER_BASEURL } from '../../../config';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import DateFieldNullable from '../../../components/fields/DateFieldNullable';
import { alarmSeverities, alarmTypes } from '../../../lib/constants/selectChoices';
import authProvider from '../../../utils/authProvider';
import { PageExplanation } from '../../../components/PageExplanation';
import { CustomBulkExportButton, FullExportButton } from '../../../utils/customExporter';
import { HasRoles } from '../../../components/HasRoles';
import { MassAcknowledgmentButton } from '../components/buttons/MassAcknowledgmentButton';
import { getAlarmColor } from '../../../utils/getAlarmColor';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import { atsDataProvider } from '../../../dataProviders/atsDataProvider';
import { CustomerLink } from '../../../lib/constants/customTypes';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { RaXgacRecord } from '../../../lib/RaXgacRecord';
import arrowDownIcon from '../../bhvk/svg/arrow_down.svg';

const Grid = Grid2;

type AlarmCreateData = {
  assetId: string | null;
  severity: AlarmSeverity;
  type: AlarmType;
};
const transform = (data: Record<string, any>) => {

  if (data.ack === undefined) data.ack = true;
  if (!data.type) {

    switch (data.severity) {

    case 'red':
      data.type = 'panic';
      break;
    case 'orange':
      data.type = 'assistance';
      break;
    case 'green':
      data.type = 'test';
      break;
    default:
      data.type = 'panic';

    }

  }

  const transformed: any = {
    ...data,
    asset: { _ref: 'Asset', _id: data.asset._id },
    ack: { value: data.ack !== true, needed: data.ack || true },
  };

  // save in local storage for next time
  localStorage.setItem('prefill-create-alarm', JSON.stringify(data));
  localStorage.setItem(`prefill-create-alarm-asset-${getCurrentCustomer()?.value}`, data.asset._id);

  return transformed;

};

const AlarmSimulateAside = (props: {
  alarmCreateData: AlarmCreateData | null;
  customerLinks: CustomerLink[];
}) => {

  const [asset, setAsset] = useState<RaXgacRecord<Asset> | null>(null);
  const [simulateResponse, setSimulateResponse] = useState<null | JSX.Element[] | 'nobody' >(null);

  const translate = useTranslate();
  const redirect = useRedirect();
  const isAdmin = authProvider.isAdmin();

  const mappedAssetGroups = asset?.assetGroups?.map((group: AssetGroupRef | string) => (typeof group === 'string' ? group : group._id)) || [];
  const filteredCustomerLinks = useMemo(() => {

    return props.customerLinks.filter((item: CustomerLink) => {

      return _.intersection(item.xgacGroups, [...mappedAssetGroups, -2]).length > 0
        && _.intersection(item.notXgacGroups, mappedAssetGroups).length === 0
        && item.alarmLevels[props.alarmCreateData?.severity || 'red']
        && (item.alarmTypes.includes(props.alarmCreateData?.type || AlarmType.Panic) || item.alarmTypes.length === 0);

    });

  }, [props, asset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (props.alarmCreateData?.assetId && asset?._id !== props.alarmCreateData?.assetId) {

      xgacDataProvider.getOne('assets', { id: props.alarmCreateData?.assetId }).then((response) => {

        setAsset(response.data as RaXgacRecord<Asset>);

      });

    }

  }, [props.alarmCreateData?.assetId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!props.alarmCreateData?.assetId) {

      return;

    }
    const simulationIds = filteredCustomerLinks.map((currentLink) => currentLink.processTemplate?.id) || ['334df770-17c7-4dbb-8f6b-eebb5f75e270'];

    setSimulateResponse(null);
    const simulatePromises = [];
    const simulateResponses: JSX.Element[] = [];
    for (let i = 0; i < simulationIds.length; i++) {

      const simulationId = simulationIds[i];
      const customerLink = filteredCustomerLinks[i];
      simulatePromises.push(atsDataProvider.simulate(simulationId, props.alarmCreateData.assetId).then((response: any) => {

        if (!response.json || Object.keys(response.json).length === 0) {

          setSimulateResponse('nobody');
          return;

        }
        let totalResponders = 0;
        for (const key of Object.keys(response.json)) {

          totalResponders += response.json[key].filter((item: any) => item.name || item.alarmCenter).length;

        }
        if (totalResponders === 0) {

          setSimulateResponse('nobody');
          return;

        }
        simulateResponses.push(<div key={customerLink.id}>
          {isAdmin
          && <button className={'sf-template-name'} onClick={() => redirect(`/customer-links/${customerLink.id}`)}>
            <span>{customerLink.processTemplate.processName}</span>
          </button>
          }
          <h5 style={{
            textAlign: 'center',
          }}>{translate(`resources.alarms.text.severity_options.${props.alarmCreateData?.severity}`)} { ' '}
            {translate('general.text.alarm').toLowerCase()}
          </h5>
          {Object.keys(response.json).map((key, index) => {

            const hasResults = response.json[Object.keys(response.json)[index - 1]]?.length > 0;
            const hasPersons = response.json[key].some((item: any) => item.name);
            const taskContactedFilter = customerLink?.processTemplate?.scheduleTasks[index]?.metaScript?.meta?.xgacFilter?.result?.contacted
              || customerLink?.processTemplate?.scheduleTasks[index]?.metaScript?.meta?.xgacFilter?.request?.contacted;
            const hasContactedFilter = taskContactedFilter === '!=true';
            const delay = customerLink?.processTemplate?.scheduleTasks[index]?.delay || 0;
            const isAlarmCenterStep = response.json[key].some((item: any) => item.alarmCenter);

            if (!isAlarmCenterStep && !hasPersons) {

              return null;

            }

            return (
              <div>
                <div className={'sf-arrow'}>
                  {!hasResults
                    ? <img src={arrowDownIcon} alt="arrow down"/>
                    : <div>
                      <img src={arrowDownIcon} alt="arrow down"/>
                      {delay / 1000} {translate('general.text.seconds')}
                    </div>
                  }
                </div>
                <div className={`sf-step ${hasPersons ? 'sf-has-persons' : 'sf-no-persons'}`}>
                  {hasPersons === false
                    ? <h6>{customerLink.processTemplate.scheduleTasks[index]?.taskTemplate?.templateName || `fase ${index + 1}`}</h6>
                    : <>
                      <small>({response.json[key].length} {translate('general.text.person', { smart_count: response.json[key].length })})</small>
                      <ul>
                        {hasContactedFilter && index !== 0
                          ? (<>
                            <li>
                              <PersonIcon style={{
                                verticalAlign: 'middle', position: 'relative', top: '-2px', marginRight: '1px',
                              }}/>
                              {translate('resources.buttons.text.create_alarm_uncontacted', { smart_count: response.json[key].length })}
                            </li>
                          </>
                          ) : (
                            <>
                              {response.json[key].map((item: any) => (
                                <>
                                  <li key={key}>
                                    <PersonIcon style={{
                                      verticalAlign: 'middle',
                                      position: 'relative',
                                      top: '-2px',
                                    }}/> {item.name}
                                  </li>
                                </>
                              ))}
                            </>
                          )}
                      </ul>
                    </>
                  }
                </div>
              </div>
            );

          })}
        </div>);

      }));

    }

    Promise.all(simulatePromises).then(() => {

      if (simulateResponses.length === 0) {

        setSimulateResponse('nobody');

      }

      setSimulateResponse(simulateResponses.sort((a, b) => {

        if (a.key && b.key) {

          return a.key.localeCompare(b.key);

        }
        return 0;

      }));

    }).catch((error) => {

      console.log('Error in simulation', error);

    });

  }, [filteredCustomerLinks]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card sx={{
        width: '80%', marginLeft: '1em', marginRight: '1em', padding: '10px',
      }}>
        <div className={'simulationFlow'}>
          <div className="sf-alarm">{translate('general.text.alarm')}</div>
          {(simulateResponse) ? <>
            {(simulateResponse !== 'nobody' && simulateResponse.length > 0)
              ? <>
                <Grid container spacing={2}>
                  {simulateResponse.map((item: any) => (
                    <Grid xs={Math.max(Math.ceil(12 / simulateResponse.length), 3)}>
                      <Card className="sf-card">
                        {item}
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </>
              : <div className="sf-step" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                {isAdmin
                  ? <span onClick={() => redirect('/customer-links')} style={{
                    cursor: 'pointer',
                  }}>{translate('resources.alarms.text.no_links')}</span>
                  : <span >{translate('resources.alarms.text.no_links')}</span>
                }
              </div>
            }
          </>
            : (<div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress/>
            </div>)
          }
        </div>

      </Card>
    </>
  );

};

const AlarmCreateToolbar = (props: React.PropsWithChildren) => <Toolbar {...props}>
  <SaveButton icon={<EmergencyShareIcon/>} label='resources.alarms.create_title' alwaysEnable={true} />
</Toolbar>;

export const AlarmCreate = () => {

  const translate = useTranslate();
  const [alarmCreateData, setAlarmCreateData] = useState<AlarmCreateData | null>(null);
  const [customerLinks, setCustomerLinks] = useState<CustomerLink[]>([]);
  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const currentCustomer = getCurrentCustomer()?.value;
  const identity = useGetIdentity();

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer);

    }

  }, [currentCustomer, lastSavedCustomer, identity]);

  useEffect(() => {

    if (!lastSavedCustomer) {

      return;

    }
    const getProcessTemplates = async () => {

      const customerLinkRequest = await atsDataProvider.getList('customer-links', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: {},
      });

      if (!customerLinkRequest.data) {

        setCustomerLinks([]);

      }
      setCustomerLinks(customerLinkRequest.data);

    };

    getProcessTemplates();

  }, [lastSavedCustomer]);

  // find previously stored
  let defaults: any = {
    name: 'Panic',
    severity: 'red',
    procedure: '',
    ack: true,
    type: 'panic',
  };

  try {

    // get defaults from local storage
    const previousData = JSON.parse(localStorage.getItem('prefill-create-alarm') || '{}');
    const previousAsset = localStorage.getItem(`prefill-create-alarm-asset-${currentCustomer}`);
    if (Object.keys(previousData).length) {

      // combine defaults
      defaults = {
        ...defaults,
        ...previousData,
      };

      // check asset
      if (previousAsset) {

        defaults = {
          ...defaults,
          asset: previousAsset,
        };

      } else {

        delete defaults.asset;

      }

    }

  } catch (e) {

    console.log('Could not prase previous data');

  }

  return (
    <>
      <CreateWithSuccess
        transform={transform}
        title={translate('resources.alarms.create_title')}
        aside={<AlarmSimulateAside alarmCreateData={alarmCreateData} customerLinks={customerLinks}/>}
      >
        <SimpleForm toolbar={<AlarmCreateToolbar />}>
          <Typography variant="h6">{translate('resources.alarms.text.alarm_creator')}</Typography>
          <p style={{ minWidth: '150px' }}>{translate('resources.alarms.text.alarm_creator_explanation')}</p>
          <ReferenceInput source="asset._id" reference="all-assets" sort={{ field: 'name', order: 'ASC' }}>
            <AutocompleteInput label="general.fields.person_or_object" defaultValue={defaults.asset} validate={required()} fullWidth />
          </ReferenceInput>

          <Typography variant="h6">{translate('resources.alarms.text.alarm_information')}</Typography>
          <p style={{ minWidth: '150px' }}>{translate('resources.alarms.text.alarm_information_explanation')}</p>
          <TextInput source="name" defaultValue={defaults.name} validate={required()} label="general.fields.name" fullWidth/>
          <AutocompleteInput source={'severity'} defaultValue={defaults.severity} validate={required()} choices={alarmSeverities} fullWidth/>
          <HasRoles anyOf={['developer_admin']}>
            <AutocompleteInput source={'type'} defaultValue={defaults.type} validate={required()} choices={alarmTypes} shouldUnregister fullWidth/>
          </HasRoles>
          <TextInput
            source="procedure"
            validate={[required()]}
            defaultValue={defaults.procedure}
            fullWidth
            placeholder={translate('resources.alarms.text.procedure_placeholder')}
            multiline />
          <HasRoles anyOf={['developer_admin']}>
            <Typography variant="h6">{translate('resources.alarms.text.ack_definition')}</Typography>
            <p style={{ minWidth: '150px' }}>
              {translate('resources.alarms.text.ack_explanation')}
            </p>
            <BooleanInput source="ack" defaultValue={defaults.ack} label="resources.alarms.text.ack.value" shouldUnregister/>
          </HasRoles>
          <FormDataConsumer>
            {({ formData }) => {

              const newData = {
                assetId: formData.asset?._id || null,
                severity: formData.severity,
                type: formData.type,
              };
              if (!_.isEqual(newData, alarmCreateData)) {

                setAlarmCreateData(newData);

              }
              return null;

            }}
          </FormDataConsumer>
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};

const AcknowledgmentField = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext();
  const ackValue = get(record, 'ack');
  let label = translate('resources.alarms.text.ack.no_time');

  if (!ackValue.value) {

    label = translate('resources.alarms.text.ack.open_alarm');

  }
  if (!ackValue.needed) {

    label = translate('resources.alarms.text.ack.not_needed');

  }

  // const timeBetween = moment(ackValue.ackedAt).locale(locale[0]).from(moment(record.createdAt), true);

  return <span style={{ whiteSpace: 'nowrap' }}>
    <BooleanField
      {...props}
      valueLabelFalse={'resources.alarms.text.ack.open_alarm'}
      valueLabelTrue={'resources.alarms.text.ack.acked'}
      color={record?.ack.value !== true ? '#d0382a' : 'black'}
      source="ack.value"
      sx={{ verticalAlign: 'text-bottom' }}/>
    <DateFieldNullable label={label} source="ack.ackedAt" showTime={true} neverText="general.text.no_time_yet"/>
  </span>;

};

const AlarmSeverityField = (props: any) => {

  const record = useRecordContext();
  const severity = get(record, props.source);
  const translate = useTranslate();

  const color = getAlarmColor(severity);

  return <ChipField {...props}
    source="value"
    record={{ value: translate(`resources.customer-links.fields.level.${severity}`) }}
    sx={{ backgroundColor: color, color: 'white', padding: 0 }}
  />;

};

const AMButton = () => {

  const record = useRecordContext();
  const link = `${XGAC_ALARM_MANAGER_BASEURL}/alarms/${record?._id}?customerId=${record?.customer._id}`;
  return <Button disabled={record === undefined} onClick={() => window.open(link, '_blank')} label='general.text.open_in_am' variant={'contained'}/>;

};

const AssetFieldClickable = () => {

  const record = useRecordContext();
  const createPath = useCreatePath();
  const roles = authProvider.getRoles();

  if (!record || !record.asset) return <TextFieldNullable/>;
  if (roles.includes('admin') || roles.includes('developer_admin')) {

    return <Link
      to={createPath({ resource: 'app-users', type: 'edit', id: record.asset._id })}>{record.asset.name}</Link>;

  }
  return <TextField source="asset.name" />;

};

const TypeField = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext();
  const type = get(record, props.source);

  return <TextField {...props} source="type" record={{ type: translate(`resources.alarms.text.type_options.${type}`) }}/>;

};

const getRowStyle = (record: any) => {

  if (record.ack.value === true) {

    return {};

  }

  return {
    backgroundColor: '#fce5e3',
  };

};

const AlarmListActions = () => {

  return (
    <TopToolbar>
      <CreateButton sx={{
        marginRight: '20px',
      }}/>
      <FullExportButton resource="alarms"/>
      <HasRoles anyOf={['admin']}>
        <MassAcknowledgmentButton allAlarms={true}/>
      </HasRoles>
    </TopToolbar>
  );

};

const AlarmBulkActionButtons = () => {

  return (
    <>
      <CustomBulkExportButton/>
      <HasRoles anyOf={['admin']}>
        <MassAcknowledgmentButton allAlarms={false}/>
      </HasRoles>
    </>
  );

};

export const AlarmList = () => {

  return (
    <>
      <PageExplanation text="resources.alarms.text.page_info" />
      <ListLive sort={{ field: 'createdAt', order: 'DESC' }}
        queryOptions={{
          meta: {
            $select: 'createdAt,customer,ack,severity,name,type,alarmCenter,asset',
          },
        }}
        actions={<AlarmListActions/>}>
        <Datagrid bulkActionButtons={<AlarmBulkActionButtons/>}
          rowStyle={getRowStyle}>
          <DateField source="createdAt" showTime={true} label="general.fields.createdAt"/>
          <AcknowledgmentField label="resources.alarms.fields.ack.value"/>
          <TextField source="name" label="general.fields.name" />
          <AlarmSeverityField source="severity" />
          <AssetFieldClickable />
          <TypeField source="type" />
          <TextFieldNullable source="alarmCenter.name" />
          <AMButton />
        </Datagrid>
      </ListLive>
    </>
  );

};

export const OpenAlarmList = () => {

  return (
    <>
      <List sort={{ field: 'createdAt', order: 'DESC' }}
        actions={<TopToolbar><CreateButton/><FullExportButton resource="alarms"/></TopToolbar> }>
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle}>
          <ReferenceField reference='customers' source="customer._id"/>
          <DateField source="createdAt" showTime={true} label="general.fields.createdAt"/>
          <AcknowledgmentField label="resources.alarms.fields.ack.value"/>
          <TextField source="name" label="general.fields.name"/>
          <AlarmSeverityField source="severity"/>
          <AssetFieldClickable/>
          <TextField source="type" sx={{ textTransform: 'capitalize' }}/>
          <TextFieldNullable source="alarmCenter.name"/>
          <AMButton/>
        </Datagrid>
      </List>
    </>
  );

};
