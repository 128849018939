import {
  memo, useEffect, useRef, useState,
} from 'react';
import queryString from 'query-string';
import { CircularProgress } from '@mui/material';
import { useGetIdentity } from 'ra-core';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import { cookieOrStorageToken } from '../../../utils/cookieToken';
import { refreshContext } from '../../../utils/authProvider';

export type BhvKnopMarketingWebsiteFrameProps = {
  page: string;
  params?: Record<string, any>;
  onMessage?: (event: any) => void;
  disableCustomerId?: boolean;
  enableEmail?: boolean;
  frameId?: string;
  onLoad?: () => void;
};

export const BhvKnopMarketingWebsiteFrame = memo((props: BhvKnopMarketingWebsiteFrameProps) => {

  const currentCustomer = getCurrentCustomer();
  const { data } = useGetIdentity();
  const [loading, setLoading] = useState(true);
  const { refetch } = useGetIdentity();
  const frameRef = useRef<HTMLIFrameElement>(null);
  cookieOrStorageToken();

  const tld = window.location.origin.split('.').pop() === 'nl' ? 'nl' : 'dev';
  const query = queryString.stringify({
    customerId: props.disableCustomerId ? undefined : currentCustomer?.value,
    email: props.enableEmail ? data?.properties.email : undefined,
    ...props.params,
  });

  useEffect(() => {

    const onMessage = (event: any) => {

      if (event.data.scrollHeight) {

        frameRef.current?.setAttribute('height', `${event.data.scrollHeight}px`);

      }

      if (event.data.scrollToTop === true) {

        window.scrollTo(0, 0);

      }

      if (props.onMessage) props.onMessage(event);

    };

    // listen for messages from the iframe
    window.addEventListener('message', onMessage);

    return () => {

      window.removeEventListener('message', onMessage);

    };

  }, [props]);

  useEffect(() => {

    if (refetch) {

      refreshContext().then(() => {

        refetch();

      });

    }

  }, [refetch]);

  return <div>
    {loading && <div style={{ marginTop: 100, textAlign: 'center' }}>
      <CircularProgress size={50} />
      <div style={{ marginTop: 20 }}>Laden...</div>
    </div>}
    <iframe
      src={`https://frame.bhv-knop.${tld}/${props.page}${query ? `?${query}` : ''}`}
      style={{
        border: 'none',
        marginTop: '20px',
        display: loading ? 'none' : 'block',
        overflow: 'hidden',
      }}
      id={props.frameId}
      scrolling={'no'}
      ref={frameRef}
      width="100%"
      height={'2000px'}
      onLoad={() => {

        setLoading(false);
        props.onLoad?.();

      }}
      title={props.page} />
  </div>;

});
