import {
  Button, ButtonProps, RaRecord, useNotify, useRedirect,
} from 'react-admin';
import { useState } from 'react';

import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { getCurrentCustomer } from '../../../../lib/currentCustomer';
import { CustomConfirmDialog } from '../../../../components/CustomConfirmDialog';

export const AppUserCreateButton = (props: ButtonProps & { record?: RaRecord; generateQr?: any }) => {

  const redirect = useRedirect();
  const notify = useNotify();
  const currentCustomer = getCurrentCustomer()?.value;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {

    setOpen(true);

  };

  const redirectToCreate = () => {

    if (props.record) {

      const formattedRecord = {
        id: props.record.id, label: props.record.name, code: props.record.code.code, hasEmailToCreatedAssetAction: props.record.hasEmailToCreatedAssetAction,
      };
      localStorage.setItem(`${currentCustomer}_defaultTemplate`, JSON.stringify(formattedRecord));

    }
    setOpen(false);
    redirect('create', 'app-users');

  };

  const redirectToQrPageAndShowNotification = () => {

    setOpen(false);
    if (props.generateQr) {

      props.generateQr(true);

    } else {

      redirect('list', 'templates');

    }
    notify('resources.app-users.text.create_explanation', { type: 'info' });

  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        {...props}
        label={props.label || 'ra.action.create'}
        startIcon={<AddIcon className="MuiButton-startIcon" style={{ marginRight: '8px' }}/>}
      />
      <CustomConfirmDialog
        open={open}
        handleClose={() => setOpen(false)}
        dialogTitle={'resources.app-users.text.create_continue'}
        dialogSubTitle={'resources.app-users.text.create_modal_intro'}
        icon1={{
          title: 'resources.qr-code.text.single',
          subTitle: 'resources.app-users.text_create_register_via_qr',
          action: redirectToQrPageAndShowNotification,
          icon: <QrCodeScannerIcon/>,
        }} icon2={{
          title: 'resources.users.text.manually',
          subTitle: 'resources.app-users.text_create_continue_to_create',
          action: redirectToCreate,
          icon: <PersonAddIcon/>,
        }} />
    </>
  );

};
