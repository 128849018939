import { Button, ButtonProps } from 'react-admin';

export const StyledButton = (props: ButtonProps) => {

  return (
    <Button
      variant={'contained'}
      color={'secondary'}
      size={'small'}
      sx={{ whiteSpace: 'nowrap' }}
      {...props}
    />
  );

};
