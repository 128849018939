import { BulkDeleteButton, useResourceContext } from 'react-admin';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { CustomBulkExportButton } from '../../utils/customExporter';
import authProvider from '../../utils/authProvider';
import { MoveUserButton } from './AppUserListButtons';

const CustomBulkActionButtons = () => {

  const resource = useResourceContext();

  const isAdmin = authProvider.isAdmin();

  return (
    <>
      {((isAdmin) && (resource === 'app-users'))
      && <MoveUserButton/>
      }
      <IfCanAccess action="export">
        <CustomBulkExportButton/>
      </IfCanAccess>
      <IfCanAccess action='delete'>
        <BulkDeleteButton mutationMode='pessimistic'/>
      </IfCanAccess>
    </>
  );

};

export default CustomBulkActionButtons;
