import { CreateResult } from 'ra-core';
import { ContactList } from '@x-guard/xgac-types/xgac';
import { Identifier } from 'react-admin';
import moment from 'moment-timezone';
// eslint-disable-next-line import/no-cycle
import { xgacDataProvider } from '../dataProviders/xgacDataProvider';
import { atsDataProvider } from '../dataProviders/atsDataProvider';
import { ATS_CUSTOMER_LINK_ALL_TEMPLATE_ID, XGAC_ATS_CUSTOMER_LINK_GREEN_TEMPLATE_ID } from '../config';

export const customerCreateDefaults = (customerId: string, redOrangeTemplateId: string, timezone: string) => {

  return [
    xgacDataProvider.create('zones', {
      data: {
        customer: customerId,
        name: 'Opvolging wereld',
        description: '',
        type: 'default',
        location: {
          coordinates: [
            [
              [
                -157.7159643793944,
                75.59255675711492,
              ],
              [
                -77.41423910275202,
                -56.135093116484946,
              ],
              [
                52.54265929181204,
                -41.204006875740966,
              ],
              [
                74.65472857088832,
                69.84369594156016,
              ],
              [
                -157.7159643793944,
                75.59255675711492,
              ],
            ],
          ],
          type: 'Polygon',
          properties: {
            accuracy: 0,
            address: {
              formattedAddress: '',
            },
          },
        },

      },
    }),

    xgacDataProvider.create('observation-triggers', {
      data: {
        customer: customerId,
        name: 'Alarm - Paniekalarm',
        eventCodes: ['ALARM_RED'],
        description: 'Rode knop ingedrukt',
        status: 'active',
        actions: [
          {
            config: {
              severity: 'red',
              type: 'panic',
              procedure: null,
              name: 'Paniekalarm',
            },
            type: 'alarm',
          },
        ],
        filter: {
          operands: [],
          type: 'operator/and',
        },
      },
    }),

    xgacDataProvider.create('observation-triggers', {
      data: {
        customer: customerId,
        name: 'Alarm - Assistentiealarm',
        eventCodes: [
          'ALARM_ORANGE',
        ],
        description: 'Oranje knop ingedrukt',
        status: 'active',
        actions: [
          {
            config: {
              severity: 'orange',
              type: 'assistance',
              procedure: null,
              name: 'Assistentiealarm',
            },
            type: 'alarm',
          },
        ],
        filter: {
          operands: [],
          type: 'operator/and',
        },
      },
    }),

    xgacDataProvider.create('observation-triggers', {
      data: {
        customer: customerId,
        name: 'Alarm - Testmelding',
        eventCodes: [
          'ALARM_GREEN',
        ],
        description: 'Groene knop ingedrukt',
        status: 'active',
        actions: [
          {
            config: {
              severity: 'green',
              type: 'test',
              procedure: null,
              name: 'Testmelding',
            },
            type: 'alarm',
          },
        ],
        filter: {
          operands: [],
          type: 'operator/and',
        },
      },
    }),

    xgacDataProvider.create('observation-triggers', {
      data: {
        customer: customerId,
        name: 'Alarm - Mandown – bewusteloos',
        eventCodes: [
          'MANDOWN',
        ],
        description: 'Dit alarm is geactiveerd doordat de gebruiker niet voldoende heeft bewogen.',
        status: 'active',
        actions: [
          {
            config: {
              severity: 'red',
              type: 'mandown',
              procedure: null,
              name: 'Mandown – bewusteloos',
            },
            type: 'alarm',
          },
        ],
        filter: {
          operands: [],
          type: 'operator/and',
        },
      },
    }),

    xgacDataProvider.create('contact-lists', {
      data: {
        customer: customerId,
        name: 'Contacten',
        description: 'Contacten (gebruikt voor Rapportage)',
        contacts: [],
      },
    }).then(async (contactList: CreateResult<ContactList & { id: Identifier }>) => {

      const promises = [
        xgacDataProvider.create('report-triggers', {
          data: {
            customer: customerId,
            naam: 'Appgebruik - maandelijks',
            config: {
              type: 'appUsage',
              mode: 'hourlyUsage',
              timeSeries: {
                granularity: {
                  value: 1,
                  type: 'hour',
                },
                timeframe: {
                  floorTo: 'month',
                  type: 'month',
                  value: 1,
                },
              },
            },
            channels: [
              {
                type: 'email',
                address: [],
                contactLists: [{ _id: contactList.data.id, _ref: 'ContactList' }],
                getters: [],
              },
            ],
            scheduleTrigger: {
              interval: {
                value: 1,
                type: 'month',
              },
              nextAt: moment.tz(timezone || 'Europe/Amsterdam').add(1, 'month').startOf('month').toISOString(),
              lastAt: null,
              lastAttemptedAt: null,
            },
            eventTrigger: null,
            enabled: true,
          },
        }),
        xgacDataProvider.create('report-triggers', {
          data: {
            customer: customerId,
            naam: 'Alarmen - wekelijks',
            config: {
              type: 'alarms',
              excelFormat: 'default',
              severities: [
                'red',
                'orange',
              ],
              alarmTypes: null,
              timeSeries: {
                granularity: {
                  value: 1,
                  type: 'day',
                },
                timeframe: {
                  floorTo: 'day',
                  type: 'week',
                  value: 1,
                },
              },
            },
            channels: [
              {
                type: 'email',
                address: [],
                contactLists: [{ _id: contactList.data.id, _ref: 'ContactList' }],
                getters: [],
              },
            ],
            scheduleTrigger: {
              interval: {
                value: 1,
                type: 'week',
              },
              nextAt: moment.tz(timezone || 'Europe/Amsterdam')
                .add(1, 'week')
                .startOf('week')
                .add(1, 'day')
                .add(8, 'hours')
                .toISOString(),
              lastAt: null,
              lastAttemptedAt: null,
            },
            eventTrigger: null,
            enabled: true,
          },
        }),
      ];

      await Promise.all(promises);

    }),

    atsDataProvider.create('customer-links', {
      data: {
        xgacCustomerId: customerId,
        xgacGroups: [-2],
        alarmLevels: {
          green: false,
          red: true,
          orange: true,
        },
        processTemplate: redOrangeTemplateId,
      },
    }),

    atsDataProvider.create('customer-links', {
      data: {
        xgacCustomerId: customerId,
        xgacGroups: [-2],
        alarmLevels: {
          green: true,
          red: false,
          orange: false,
        },
        processTemplate: XGAC_ATS_CUSTOMER_LINK_GREEN_TEMPLATE_ID,
      },
    }),

    atsDataProvider.create('customer-links', {
      data: {
        xgacCustomerId: customerId,
        xgacGroups: [-2],
        alarmLevels: {
          green: true,
          red: true,
          orange: true,
        },
        processTemplate: ATS_CUSTOMER_LINK_ALL_TEMPLATE_ID,
      },
    }),
  ];

};
