import {
  TextField, useRecordContext, ReferenceField, useTranslate,
} from 'react-admin';
import { get } from 'lodash';

const ReferenceFieldNullable = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext();
  const value = get(record, props.source);

  if (!value) return <TextField source="value" record={{ value: translate('general.text.none') }} className="gray"/>;
  return <ReferenceField {...props}/>;

};

export default ReferenceFieldNullable;
