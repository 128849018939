import {
  FormDataConsumer, required, TextInput,
} from 'react-admin';
import * as React from 'react';
import { get } from 'lodash';

export const LoraConfigInput = (props: any) => {

  return (
    <>
      <FormDataConsumer>
        {({ formData }) => {

          if (!formData) {

            return null;

          }
          const currentConfig = get(formData, props.source);
          if (!currentConfig) {

            return null;

          }
          if (currentConfig.type === 'adeunis.arf8123aa') {

            return (
              <>
                <TextInput name={`${props.source}.config.fieldTest`} source={`${props.source}.config.fieldTest`}
                  defaultValue="LORA_FIELDTEST" shouldUnregister
                  sx={{ display: 'block', width: '100%' }}
                  label="resources.devices.fields.loraConfig.fieldtest"

                  validate={required()}/>
              </>
            );

          }
          if (currentConfig.type === 'iotracker.v2') {

            return (
              <div>
                <TextInput name={`${props.source}.config.beaconScan`} source={`${props.source}.config.beaconScan`} defaultValue="BEACON"
                  shouldUnregister sx={{ display: 'block', width: '100%' }}
                  label="resources.devices.fields.loraConfig.beaconScan"
                  validate={required()}/>
                <TextInput name={`${props.source}.config.gps`} source={`${props.source}.config.gps`} defaultValue="GPS"
                  shouldUnregister sx={{ display: 'block', width: '100%' }}
                  label="resources.devices.fields.loraConfig.gps"
                  validate={required()}/>
                <TextInput name={`${props.source}.config.preMandown`} source={`${props.source}.config.preMandown`}
                  defaultValue="PRE_MANDOWN"
                  shouldUnregister sx={{ display: 'block', width: '100%' }}
                  label="resources.devices.fields.loraConfig.preMandown"
                  validate={required()}/>
                <TextInput name={`${props.source}.config.mandown`} source={`${props.source}.config.mandown`} defaultValue="MANDOWN"
                  shouldUnregister sx={{ display: 'block', width: '100%' }}
                  label="resources.devices.fields.loraConfig.mandown"
                  validate={required()}/>
                <TextInput name={`${props.source}.config.movement`} source={`${props.source}.config.movement`} defaultValue="MOVEMENT"
                  shouldUnregister sx={{ display: 'block', width: '100%' }}
                  label="resources.devices.fields.loraConfig.movement"
                  validate={required()}/>
                <TextInput name={`${props.source}.config.alarmSeverity`} source={`${props.source}.config.alarmSeverity`}
                  defaultValue="ALARM_RED"
                  shouldUnregister sx={{ display: 'block', width: '100%' }}
                  label="resources.devices.fields.loraConfig.alarmSeverity"
                  validate={required()}/>
                <TextInput name={`${props.source}.config.alive`} source={`${props.source}.config.alive`} defaultValue="ALIVE"
                  shouldUnregister sx={{ display: 'block', width: '100%' }}
                  label="resources.devices.fields.loraConfig.alive"
                  validate={required()}/>
              </div>
            );

          }
          if (currentConfig.type === 'milesight.ws101') {

            return (
              <div>
                <>
                  <TextInput name={`${props.source}.config.beaconScan`} source={`${props.source}.config.beaconScan`}
                    defaultValue="BEACON"
                    sx={{ display: 'block', width: '100%' }}
                    label="resources.devices.fields.loraConfig.beaconScan"
                    shouldUnregister
                    validate={required()}/>
                  <TextInput name={`${props.source}.config.alarmSeverity`} source={`${props.source}.config.alarmSeverity`}
                    defaultValue="ALARM_RED"
                    sx={{ display: 'block', width: '100%' }}
                    label="resources.devices.fields.loraConfig.alarmSeverity"
                    shouldUnregister
                    validate={required()}/>
                  <TextInput name={`${props.source}.config.alive`} source={`${props.source}.config.alive`} defaultValue="ALIVE"
                    sx={{ display: 'block', width: '100%' }}
                    label="resources.devices.fields.loraConfig.alive"
                    shouldUnregister
                    validate={required()}/>
                </>
              </div>
            );

          }
          if (currentConfig.type === 'sensative.1301001') {

            return (
              <div>
                <div>
                  <>
                    <TextInput name={`${props.source}.config.alarmSeverity`} source={`${props.source}.config.alarmSeverity`}
                      defaultValue="ALARM_RED"
                      sx={{ display: 'block', width: '100%' }}
                      label="resources.devices.fields.loraConfig.alarmSeverity"
                      shouldUnregister
                      validate={required()}/>
                    <TextInput name={`${props.source}.config.alive`} source={`${props.source}.config.alive`} defaultValue="ALIVE"
                      sx={{ display: 'block', width: '100%' }}
                      label="resources.devices.fields.loraConfig.alive"
                      shouldUnregister
                      validate={required()}/>
                    <TextInput name={`${props.source}.config.floodDetected`} source={`${props.source}.config.floodDetected`}
                      defaultValue="WATER_DETECTED"
                      sx={{ display: 'block', width: '100%' }}
                      label="resources.devices.fields.loraConfig.floodDetected"
                      shouldUnregister
                      validate={required()}/>
                    <TextInput name={`${props.source}.config.noFloodDetected`} source={`${props.source}.config.noFloodDetected`}
                      defaultValue="WATER_ALIVE"
                      sx={{ display: 'block', width: '100%' }}
                      shouldUnregister
                      label="resources.devices.fields.loraConfig.noFloodDetected"
                      validate={required()}/>
                  </>
                </div>
              </div>
            );

          }
          return <></>;

        }}
      </FormDataConsumer></>
  );

};
