import {
  memo,
} from 'react';

export const InvoicesPage = memo(() => {

  return <div>
    <h1>Facturen</h1>
    Het is nog niet mogelijk om facturen in te zien. Neem contact op met info@bhv-knop.nl. Ons excuses voor het ongemak.
  </div>;

});
