import React from 'react';
import {
  Create, CreateProps, useNotify, useRedirect, useResourceContext, useTranslate,
} from 'react-admin';

export const CreateWithSuccess = (props: CreateProps & {
  children: React.ReactNode;
}) => {

  function capitalizeFirstLetter(string: string) {

    return string.charAt(0).toUpperCase() + string.slice(1, -1);

  }

  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext() || '';
  const translate = useTranslate();
  const OnSuccess = () => {

    notify(`${capitalizeFirstLetter(resource)} ${translate('general.text.saved').toLowerCase()}`, { type: 'success' });

    redirect(props.redirect ?? `/${resource}`);

  };

  return (<Create mutationOptions={{ onSuccess: OnSuccess }}{...props}/>);

};
