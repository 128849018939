import { getDistance } from 'geolib';

export type Position = {
  latitude: number;
  longitude: number;
};

export const isPosition = (pos: unknown): pos is Position => {

  return typeof pos === 'object'
    && typeof (pos as Position).latitude === 'number'
    && typeof (pos as Position).longitude === 'number';

};

export const hasPosition = (obj: object): obj is { position: Position } => {

  const pos = (obj as { position: Position })?.position;

  return isPosition(pos);

};

export const areSamePosition = (pos1: Position, pos2: Position): boolean => {

  return getDistance(pos1, pos2) < 1;

};

export const latLng = (position: Position): [number, number] => {

  return [position.latitude, position.longitude];

};
