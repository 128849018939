import {
  TextField, useRecordContext, useTranslate, DateFieldProps,
} from 'react-admin';
import { get } from 'lodash';
import { DateFieldWithTime } from './DateFieldWithTime';

const DateFieldNullable = (props: DateFieldProps & { neverText?: string; timeOnHover?: boolean }) => {

  const translate = useTranslate();
  const record = useRecordContext();
  const value = get(record, props.source || '');

  if (!value) return <TextField source="value" record={{ value: translate(props.neverText || 'general.text.never') }} sx={{ whiteSpace: 'noWrap' }} />;
  return <DateFieldWithTime {...props} />;

};

export default DateFieldNullable;
