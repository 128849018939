import { TextField, useRecordContext, useTranslate } from 'react-admin';
import * as React from 'react';
import TextFieldNullable from './TextFieldNullable';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RunIntervalField = (props: any) => {

  const translate = useTranslate();

  const record = useRecordContext();
  const granularityType = (translate(`resources.report-triggers.text.granularity_types.${record?.scheduleTrigger?.interval?.type}`)
    || record?.runInterval?.type || '').toLowerCase();

  const granularityValue = record?.scheduleTrigger?.interval?.value;
  if (!granularityValue) return <TextFieldNullable/>;

  return <TextField source="interval" record={{ interval: `${granularityValue} ${granularityType} ` }} />;

};
