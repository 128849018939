import {
  Datagrid,
  EditButton,
  List,
  TextField,
  ArrayInput,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  AutocompleteInput,
  NumberInput,
  required,
  useRecordContext,
  Loading,
  useNotify,
  Link,
  useCreatePath,
  useTranslate,
  SearchInput,
} from 'react-admin';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { JsonInput } from 'react-admin-json-view';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as turf from '@turf/turf';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { atsDataProvider } from '../../../dataProviders/atsDataProvider';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';

const processTemplateTransform = (data: Record<string, any>) => {

  delete data.createdAt;
  delete data.updatedAt;
  delete data.customerId;
  delete data.xgacCustomerId;
  delete data.id;
  return {
    ...data,
    scheduleTasks: data.scheduleTasks.map((task:any) => {

      const taskTemplateId = task.taskTemplate.id ?? task.taskTemplate;
      if (!task.ifScript.name || task.ifScript.name === '') {

        task.ifScript = null;

      }
      return {
        ...task,
        taskTemplate: taskTemplateId,
      };

    }),
  };

};

export const SimulateProcessButton = () => {

  const translate = useTranslate();
  const [preDialogOpen, setPreDialogOpen] = useState(false);
  const [mainDialogOpen, setMainDialogOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<string | null>(null);
  const [atsSimulateResponse, setAtsSimulateResponse] = useState<any>(null);

  const notify = useNotify();
  const record = useRecordContext();
  const createPath = useCreatePath();

  if (!record) return null;

  const simulateProcess = async () => {

    if (!selectedAsset) {

      setPreDialogOpen(false);
      notify('resources.process-templates.text.no_asset_error', { type: 'error' });
      return;

    }

    setPreDialogOpen(false);
    setMainDialogOpen(true);

    const atsRequest = await atsDataProvider.simulate(record.id.toString(), selectedAsset);
    if (!atsRequest) {

      setMainDialogOpen(false);
      notify('resources.process-templates.text.simulation_error', { type: 'error' });

    }

    const atsSimulateCardList: any[] = [];

    let countDelay = true;
    for (const key of Object.keys(atsRequest.json)) {

      const index = Object.keys(atsRequest.json).indexOf(key);

      const currentTask = record.scheduleTasks[index];

      if (typeof atsRequest.json[key][0]?.alarmCenter !== 'undefined') {

        const alarmCenters = atsRequest.json[key];

        const alarmCenterTableRows = [];

        for (const currentCenter of alarmCenters) {

          alarmCenterTableRows.push(
            <TableRow>
              <TableCell>
                <span style={{ color: 'grey' }}>{currentCenter.querySpeed} ms</span>
              </TableCell>
              <TableCell>
                <Link to={createPath({ resource: 'customers', type: 'edit', id: currentCenter.alarmCenter.id })}
                  target="_blank">{currentCenter.alarmCenter.name}
                  <OpenInNewIcon sx={{ fontSize: '0.875rem' }}/>
                </Link>
              </TableCell>
              <TableCell>
                {currentCenter.alarmCenter.escalate ? translate('general.text.yes') : translate('general.text.no')}
              </TableCell>
              <TableCell>
                {
                  currentCenter.alarmCenter.queue ? <span>{currentCenter.alarmCenter.queue}</span> : <span className="gray">
                    {translate('general.text.none').charAt(0).toUpperCase() + translate('general.text.none').slice(1)}
                  </span>
                }
              </TableCell>
            </TableRow>,
          );

        }

        atsSimulateCardList.push(
          <Card sx={{ marginBottom: '5px' }} variant="outlined">
            <CardContent>
              <Typography variant="h6" >{index + 1}: {currentTask.taskTemplate.templateName}</Typography>
              <p style={{ fontSize: '12px', color: 'gray', marginTop: '3px' }} className={countDelay ? '' : 'line-through'}>
                {translate('resources.process-templates.text.previous_stage_delay', { delay: currentTask.delay / 1000 })}
              </p>
              {alarmCenters.length > 0 && (
                <Table sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: 'grey' }}>
                        {translate('resources.process-templates.text.query_speed')}
                      </TableCell>
                      <TableCell>
                        {translate('general.fields.name')}
                      </TableCell>
                      <TableCell>
                        {translate('resources.process-templates.text.alarmcenter_escalate')}
                      </TableCell>
                      <TableCell>
                        {translate('resources.process-templates.text.alarmcenter_queue_name')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {alarmCenterTableRows}
                  </TableBody>
                </Table>
              )}
              {alarmCenters.length === 0 && (
                <span>{translate('resources.process-templates.text.no_results_in_stage')}</span>
              )}
            </CardContent>
          </Card>,
        );

        countDelay = alarmCenters.length !== 0;

      } else {

        const creatorAsset = await xgacDataProvider.getOne('assets', { id: selectedAsset });

        const creatorPosition = turf.point(creatorAsset.data?.position?.coordinates || [0, 0]);

        const assets = atsRequest.json[key];

        const assetRows = [];

        for (const currentAsset of assets) {

          let validDistance = true;
          const fromPosition = currentAsset.position?.coordinates;

          if (!fromPosition || !creatorPosition || (creatorPosition.geometry.coordinates[0] === 0 && creatorPosition.geometry.coordinates[1] === 0)) {

            validDistance = false;

          }
          const distance = turf.distance(creatorPosition, turf.point(fromPosition || [0, 0])).toFixed(2);

          assetRows.push(
            <TableRow>
              <TableCell>
                <span style={{ color: 'grey' }}>{currentAsset.querySpeed} ms</span>
              </TableCell>
              <TableCell>
                <Link to={createPath({ resource: 'app-users', type: 'edit', id: currentAsset._id })}
                  target="_blank">{currentAsset.name}
                  <OpenInNewIcon sx={{ fontSize: '0.875rem' }}/>
                </Link>
              </TableCell>
              <TableCell>
                {validDistance && distance
                  ? `${distance} ${translate('resources.process-templates.text.kilometers')}`
                  : translate('general.text.invalid')}
              </TableCell>
              <TableCell>
                {currentAsset.properties?.phoneNumber}
              </TableCell>
              <TableCell>
                {currentAsset.properties?.smsNumber}
              </TableCell>
            </TableRow>,
          );

        }

        atsSimulateCardList.push(
          <Card sx={{ marginBottom: '5px' }} variant="outlined">
            <CardContent>
              <Typography variant="h6" >{index + 1}: {currentTask.taskTemplate.templateName}</Typography>
              <p style={{ fontSize: '12px', color: 'gray', marginTop: '3px' }} className={countDelay ? '' : 'line-through'}>
                {translate('resources.process-templates.text.previous_stage_delay', { delay: currentTask.delay / 1000 })}
              </p>
              {assets.length > 0 && (
                <Table sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: 'grey' }}>
                        {translate('resources.process-templates.text.query_speed')}
                      </TableCell>
                      <TableCell>
                        {translate('general.fields.name')}
                      </TableCell>
                      <TableCell>
                        {translate('resources.process-templates.text.asset_distance')}
                      </TableCell>
                      <TableCell>
                        {translate('resources.assets.fields.phoneNumber')}
                      </TableCell>
                      <TableCell>
                        {translate('resources.assets.fields.smsNumber')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assetRows}
                  </TableBody>
                </Table>
              )}
              {assets.length === 0 && (
                <span>{translate('resources.process-templates.text.no_results_in_stage')}</span>
              )}
            </CardContent>
          </Card>,
        );

        countDelay = assets.length !== 0;

      }

    }

    setAtsSimulateResponse(atsSimulateCardList);

  };

  return (
    <>
      <Card>
        <CardContent>
          <Button onClick={() => setPreDialogOpen(true)}>
            {translate('resources.process-templates.text.simulate_process')}
          </Button>
        </CardContent>
      </Card>
      <Dialog open={preDialogOpen} onClose={() => setPreDialogOpen(false)}>
        <DialogTitle className="flex-in-between">
          <span> {`${translate('resources.process-templates.text.simulate_process')} ${record.processName}`} </span>
          <IconButton onClick={() => setPreDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ReferenceInput source="asset" reference="all-assets" record={{ id: 0, asset: null }}>
            <AutocompleteInput onChange={(event) => setSelectedAsset(event)} label={'resources.assets.text.single_name'} />
          </ReferenceInput>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreDialogOpen(false)} autoFocus>
            <>{translate('ra.action.cancel')}</>
          </Button>
          <Button onClick={simulateProcess} disabled={selectedAsset === null}>{translate('resources.process-templates.text.simulate')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={mainDialogOpen} onClose={() => setMainDialogOpen(false)} fullWidth={true} maxWidth="xl">
        <DialogTitle className="flex-in-between">
          <span> {`${translate('resources.process-templates.text.simulate_process')} ${record.processName}`} </span>
          <IconButton onClick={() => setMainDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!atsSimulateResponse && (
            <Loading
              loadingPrimary="resources.process-templates.text.simulation_started"
              loadingSecondary="resources.process-templates.text.simulation_started_secondary"
            />
          )}
          {atsSimulateResponse && (

            atsSimulateResponse
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMainDialogOpen(false)} autoFocus>
            <>{translate('general.text.close')}</>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

};
export const ProcessTemplateList = () => {

  const templateFilters = [
    <SearchInput source="search" alwaysOn sx={{ width: '100%' }} variant="filled"/>,
  ];

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Unstable} />
      <List title="resources.process-templates.text.title" filters={templateFilters}>
        <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
          <TextField source="processName" />
          <TextFieldNullable source="description" />
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
          <EditButton/>
        </Datagrid>
      </List>
    </>
  );

};

export const ProcessTemplateEdit = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Unstable} />
      <EditWithSuccess title="resources.process-templates.text.title" transform={processTemplateTransform}>
        <SimpleForm toolbar={false}>
          <SimulateProcessButton/>
          <br/>
          <Typography variant="h6">{translate('resources.assets.text.general')}</Typography>
          <TextInput source="processName" validate={required()}/>
          <TextInput source="description" validate={required()} multiline/>
          <Typography variant="h6">{translate('resources.process-templates.text.schedule_tasks')}</Typography>
          <ArrayInput source="scheduleTasks">
            <SimpleFormIterator disableReordering>
              <Typography variant="h6">{translate('resources.process-templates.text.if_script')}</Typography>
              <TextInput source="ifScript.name" label="general.fields.name" />
              <JsonInput source="ifScript.meta" reactJsonOptions={{
                // Props passed to react-json-view
                name: 'meta',
                collapsed: false,
                enableClipboard: true,
                displayDataTypes: false,
              }} label="Meta"/>
              <Typography variant="h6">{translate('resources.process-templates.fields.delay')}</Typography>
              <NumberInput source="delay" validate={required()} defaultValue={0} label="resources.process-templates.fields.delay" />
              <Typography variant="h6">MetaScript</Typography>
              <TextInput source="metaScript.name" />
              <JsonInput source="metaScript.meta.xgacFilter" reactJsonOptions={{
                name: 'xgacFilter',
                collapsed: false,
                enableClipboard: true,
                displayDataTypes: false,
              }} label="xgacFilter"/>
              <Typography variant="h6">MetaData</Typography>
              <JsonInput source="metaData" reactJsonOptions={{
                name: 'metaData',
                collapsed: false,
                enableClipboard: true,
                displayDataTypes: false,
              }} label=' '/>
              <Typography variant="h6">{translate('resources.process-templates.text.taskTemplate.id')}</Typography>
              <ReferenceInput source="taskTemplate.id" reference="task-templates" label='resources.process-templates.text.taskTemplate.id'>
                <AutocompleteInput autoHighlight={true} label='resources.process-templates.text.taskTemplate.id'/>
              </ReferenceInput>

            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};
