import {
  Identifier, ReferenceField, TextField, useRecordContext,
} from 'react-admin';
import * as React from 'react';
import { ReportAuditLog } from '../../../../lib/constants/customTypes';

export const AuthorField = (props: { label: string }) => {

  const record: ReportAuditLog & { id: Identifier } | undefined = useRecordContext();
  if (record?.user) {

    return (
      <ReferenceField reference={'users'} source={'user._id'} label={props.label}/>
    );

  }
  return <TextField source="userLabel" label={props.label}/>;

};
