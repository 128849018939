import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  DELETE_MANY,
} from 'react-admin';
import { xgacDataProvider } from './xgacDataProvider';
import { legacyMainDataProvider } from './legacyMainDataProvider';
import { atsDataProvider } from './atsDataProvider';
import { qrCodeDataProvider } from './qrCodeDataProvider';
import { TypedDataProvider } from './typedDataProvider';

const mappingType = {
  [GET_LIST]: 'getList',
  [GET_ONE]: 'getOne',
  [GET_MANY]: 'getMany',
  [GET_MANY_REFERENCE]: 'getManyReference',
  [CREATE]: 'create',
  [UPDATE]: 'update',
  [UPDATE_MANY]: 'updateMany',
  [DELETE]: 'delete',
  [DELETE_MANY]: 'deleteMany',
  subscribe: 'subscribe',
  unsubscribe: 'unsubscribe',

} as const;

const dataProviders: {
  dataProvider: TypedDataProvider;
  resources: string[];
}[] = [
  {
    // this is the default, all resources not defined in other dataproviders will be handled by this one
    dataProvider: xgacDataProvider as TypedDataProvider,
    resources: [],
  },
  {
    dataProvider: legacyMainDataProvider as unknown as TypedDataProvider,
    resources: ['flic-hubs', 'flic-buttons', 'domainlinks'],
  },
  {
    dataProvider: atsDataProvider as unknown as TypedDataProvider,
    resources: ['customer-links', 'process-templates', 'task-templates'],
  },
  {
    dataProvider: qrCodeDataProvider as unknown as TypedDataProvider,
    resources: ['templates', 'lite-templates', 'codes', 'actions'],
  },
];

export const dataProvider: any = (type: keyof typeof mappingType, resource: string, params: any) => {

  // find the data provider
  const foundDataProvider = dataProviders.find((dp) => dp.resources.includes(resource)) || dataProviders[0];

  // proxy the call
  return foundDataProvider.dataProvider[mappingType[type]](resource, params);

};
