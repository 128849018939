import { TextInput, TextInputProps, useTranslate } from 'react-admin';
import { InputLabel } from '@mui/material';

export const GrayTextInputWithUpperLabel = (props: TextInputProps) => {

  const translate = useTranslate();
  return (
    <>
      <InputLabel className="input-label">
        {translate(typeof props.label === 'string' ? props.label : '', { _: props.label })}
      </InputLabel>
      <TextInput {...props} label={false} inputProps={{ style: { backgroundColor: '#f1f1f1', borderRadius: '5px' } }} sx={{
        '& fieldset': {
          border: 'none',
        },
      }}/>
    </>
  );

};
