import { useGetMany, useTranslate } from 'ra-core';
import { Asset, AssetGroup, AssetGroupRef } from '@x-guard/xgac-types/xgac';
import {
  Avatar, Chip,
  DialogContent,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Loading, useNotify, useRedirect,
} from 'react-admin';
import * as React from 'react';
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextFieldNullable from './fields/TextFieldNullable';

const NullablePrivacyTableCell = (props: { value: string | undefined | null }) => {

  const notify = useNotify();
  const translate = useTranslate();
  if (!props.value) return (<TableCell><TextFieldNullable/></TableCell>);

  const copyValues = () => {

    copy(`${props.value}` || '');
    notify('general.text.copied');

  };

  return (
    <TableCell>
      <div style={{ minWidth: 'max-content' }}>
        <div className="tablePrivacyBlur" style={{ display: 'inline' }}>
          <span>
            {props.value}
          </span>
        </div>
        <IconButton onClick={copyValues} sx={{ display: 'inline' }} size="small">
          <Tooltip title={translate('general.text.copy')}>
            <ContentCopyIcon sx={{ fontSize: '12px' }}/>
          </Tooltip>
        </IconButton>
      </div>
    </TableCell>
  );

};

const AssetPictureField = (props: { asset: Asset }) => {

  const asset = props.asset;
  const widthHeight = 30;
  if (asset.properties.image) {

    return (
      <Avatar alt={asset.name} src={asset.properties.image} sx={{ width: widthHeight, height: widthHeight }}/>
    );

  }

  const initials = asset.name.match(/\b\w/g);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const twoInitials = initials?.length > 1 ? initials[0] + initials[initials.length - 1] : initials[0];
  return (
    <Avatar alt={asset.name} sx={{ width: widthHeight, height: widthHeight, fontSize: '15px' }}>{twoInitials.toUpperCase()}</Avatar>
  );

};
export const AssetTableInDialog = (props: { assets: Asset[]; loading: boolean }) => {

  const { assets, loading } = props;
  const translate = useTranslate();
  const redirect = useRedirect();
  const allGroupIds = assets.map((asset) => asset.assetGroups).flat().map((group) => {

    return group._id;

  });
  const uniqueGroupIds = [...new Set(allGroupIds)];
  const { data: assetGroups } = useGetMany('asset-groups', { ids: uniqueGroupIds });

  return (
    <DialogContent>
      {loading && <Loading loadingSecondary={''}/>}
      {(!loading && assets.length === 0) && (
        <div><Typography variant="body1">{translate('resources.asset-groups.text.no_assets')}</Typography></div>
      )}
      {(!loading && assets.length > 0) && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell>
                {translate('general.fields.name')}
              </TableCell>
              <TableCell>
                {translate('resources.assets.fields.email')}
              </TableCell>
              <TableCell>
                {translate('resources.assets.fields.phoneNumber')}
              </TableCell>
              <TableCell>
                {translate('menu.items.groups')}
              </TableCell>
            </TableRow>
          </TableHead>
          {assets && assets.map((asset) => {

            const groupsForAsset = assetGroups?.filter((group) => asset.assetGroups.map((assetAssetGroup: AssetGroupRef) => {

              return assetAssetGroup._id;

            }).includes(group._id));
            return (
              <TableRow>
                <TableCell>
                  <AssetPictureField asset={asset}/>
                </TableCell>
                <TableCell>
                  {asset.name}
                </TableCell>
                <NullablePrivacyTableCell value={asset.properties?.email}/>
                <NullablePrivacyTableCell value={asset.properties?.phoneNumber}/>
                <TableCell>
                  {groupsForAsset?.map((group: AssetGroup) => (
                    <Chip label={group.name} sx={{ margin: '2px' }} onClick={() => {

                      redirect('edit', 'asset-groups', group._id);

                    }}/>
                  ))}
                </TableCell>
              </TableRow>
            );

          })}
        </Table>
      )}
    </DialogContent>
  );

};
