import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoading } from 'ra-core';

import { RefreshIconButton } from 'react-admin';

const PREFIX = 'RaLoadingIndicator';

export const LoadingIndicatorClasses = {
  loader: `${PREFIX}-loader`,
  loadedIcon: `${PREFIX}-loadedIcon`,
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoadingIndicatorClasses.loader}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  [`& .${LoadingIndicatorClasses.loadedIcon}`]: {},
}));

export const CustomLoadingIndicator = (props: LoadingIndicatorProps) => {

  const { className, sx, ...rest } = props;
  const loading = useLoading();

  return (
    <Root className={className} sx={sx}>
      {loading ? (
        <CircularProgress
          className={clsx(
            'app-loader',
            LoadingIndicatorClasses.loader,
          )}
          style={{
            margin: '10px',
            width: '16px',
          }}
          color="inherit"
          size={'16px'}
          thickness={4}
          {...rest}
        />
      ) : (
        <RefreshIconButton
          className={LoadingIndicatorClasses.loadedIcon}
        />
      )}
    </Root>
  );

};

CustomLoadingIndicator.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
};

interface LoadingIndicatorProps {
  className?: string;
  sx?: any;
}
