import { Identifier, RaRecord } from 'react-admin';
import type {
  Alarm, Asset, Beacon, Customer, Device, Entity, StaticResponder, User, Webhook, Zone,
} from '@x-guard/xgac-types/xgac';

export const RecordNameAsRaXgacRecord = (record: Record<string, unknown>, recordName: string): RaXgacRecord<Entity> => {

  const returnRecord: { id: Identifier } = { ...record, id: record._id as Identifier };
  switch (recordName) {

  case 'alarms':
    return returnRecord as RaXgacRecord<Alarm>;
  case 'assets':
    return returnRecord as RaXgacRecord<Asset>;
  case 'beacons':
    return returnRecord as RaXgacRecord<Beacon>;
  case 'customers':
    return returnRecord as RaXgacRecord<Customer>;
  case 'devices':
    return returnRecord as RaXgacRecord<Device>;
  case 'staticResponders':
    return returnRecord as RaXgacRecord<StaticResponder>;
  case 'users':
    return returnRecord as RaXgacRecord<User>;
  case 'webhooks':
    return returnRecord as RaXgacRecord<Webhook>;
  case 'zones':
    return returnRecord as RaXgacRecord<Zone>;
  default:
    return returnRecord as RaXgacRecord<Entity>;

  }

};
export type RaXgacRecord<T> = RaRecord & T;
