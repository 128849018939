import { User } from '@x-guard/xgac-types/xgac';
import { Button, useNotify, useTranslate } from 'react-admin';
import { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import * as React from 'react';
import { httpClient } from '../../utils/httpClient';
import { StyledButton } from '../../apps/bhvk/buttons/StyledButton';
import { XGAC_MAIN_API_URL } from '../../config';

const apiUrl = XGAC_MAIN_API_URL;

export const ResetPasswordButton = (props: { user: User | null; disabled?: boolean; bhvk?: boolean }) => {

  const translate = useTranslate();
  const notify = useNotify();
  const [dialogOpen, setDialogOpen] = useState(false);

  const sendPasswordReset = async () => {

    const passwordResetRequest = await httpClient(`${apiUrl}/users/reset-password/${props.user?._id}${props.bhvk ? '?bhvk=true' : ''}`);
    const passwordRequestBody = JSON.parse(passwordResetRequest.body);
    if (passwordRequestBody.statusCode < 400) {

      notify('resources.users.text.password_reset_sent', { type: 'success' });

    } else {

      notify('resources.users.text.password_reset_failed', { type: 'warning' });

    }
    setDialogOpen(false);

  };

  const handleClickOpen = () => {

    setDialogOpen(true);

  };
  const handleClose = () => {

    setDialogOpen(false);

  };

  return (<>
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <StyledButton
        color="primary"
        onClick={handleClickOpen}
        disabled={props.disabled || (props.user === null || !props.user.properties.email || props.user?.properties?.email?.length === 0)}
        label="resources.users.text.password_reset_button"
        sx={{ height: 'fit-content', margin: 'auto' }}
        size="medium"
      />
    </div>
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflowY: 'visible',
        },
      }}
    >
      <DialogTitle>
        {translate('resources.users.text.password_reset_button')}
      </DialogTitle>
      <DialogContent style={{ overflowY: 'visible' }}>
        <DialogContentText>
          {translate('resources.users.text.password_reset_to_base_description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          <>{translate('ra.action.cancel')}</>
        </Button>
        <Button onClick={sendPasswordReset}>
          <>
            {translate('resources.users.text.password_reset_mail_confirm')}
          </>
        </Button>
      </DialogActions>
    </Dialog></>);

};
