import englishFlag from './englishFlag.svg';
import dutchFlag from './dutchFlag.svg';

export const DutchFlag = () => (
  <div>
    <img src={dutchFlag} alt="Dutch flag" width='24' style={{ marginTop: 7 }} />
  </div>
);
export const EnglishFlag = () => (
  <div>
    <img src={englishFlag} alt="English flag" width='24' style={{ marginTop: 7 }} />
  </div>
);
