import { Datagrid, IfCanAccess, List } from '@react-admin/ra-rbac';
import {
  EditButton,
  regex,
  required,
  SimpleForm,
  TextField,
  TextInput,
  useGetRecordId,
  useNotify,
  useTranslate,
} from 'react-admin';
import * as React from 'react';
import {
  Button, Card, CardContent,
} from '@mui/material';
import { useRecordContext } from 'ra-core';
import SearchFields, { FilterResetter } from '../components/fields/Filters';
import { LIST_DEBOUNCE, XGAC_MAIN_API_URL } from '../../../config';
import { exporter } from '../../../utils/customExporter';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { CustomToolbar } from '../../../components/CustomToolBar';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { httpClient } from '../../../utils/httpClient';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

const apiUrl = XGAC_MAIN_API_URL;

const createTransform = (data: Record<string, any>) => ({
  ...data,
  isServiceAccount: true,
  properties: {
    email: 'info+sa@x-guard.nl',
    locale: 'en',
  },
});

const editTransform = (data: Record<string, any>) => ({
  name: data.name,
});

export const ServiceAccountList = () => {

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <List title="resources.service-accounts.text.title" filters={SearchFields} debounce={LIST_DEBOUNCE} exporter={exporter}>
        <>
          <FilterResetter/>
          <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
            <TextField source="username" label="resources.users.fields.username"/>
            <TextField source="name" label="general.fields.name"/>
            <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
            <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
            <IfCanAccess action="edit">
              <EditButton/>
            </IfCanAccess>
            <HasRoles anyOf={['developer_admin']}>
              <AuditlogButton/>
            </HasRoles>
          </Datagrid>
        </>
      </List>
    </>
  );

};

export const ServiceAccountEdit = () => {

  const notify = useNotify();
  const recordId = useGetRecordId();
  const translate = useTranslate();

  const ResetButton = () => {

    const sendPasswordReset = async () => {

      const passwordResetRequest = await httpClient(`${apiUrl}/users/reset-password/${recordId}`);
      const passwordRequestBody = JSON.parse(passwordResetRequest.body);
      if (passwordRequestBody.statusCode < 400) {

        notify('resources.users.text.password_reset_sent', { type: 'success' });

      } else {

        notify('resources.users.text.password_reset_failed', { type: 'warning' });

      }

    };

    const record = useRecordContext();

    if (record?.properties?.email) {

      return (<Card>
        <CardContent>
          <Button onClick={sendPasswordReset}>{translate('resources.users.text.password_reset_button')}</Button>
        </CardContent>
      </Card>);

    }
    return null;

  };
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta}/>
      <EditWithSuccess title="resources.service-accounts.text.title" actions={false} transform={editTransform}>
        <SimpleForm toolbar={<CustomToolbar/>}>
          <TextInput source="username" disabled label="resources.users.fields.username"/>
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <ResetButton/>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const ServiceAccountCreate = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Unstable}/>
    <CreateWithSuccess title="Service Accounts" actions={false} transform={createTransform}>
      <SimpleForm>
        <TextInput source="username" validate={[regex(/^sa-(.*)$/, 'resources.service-accounts.text.username_validation"'),
          required()]}
        defaultValue={'sa-'}
        label="resources.users.fields.username"
        />
        <TextInput source="name" validate={required()} label="general.fields.name"/>
      </SimpleForm>
    </CreateWithSuccess>
  </>
);
