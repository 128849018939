import { useListContext } from 'ra-core';
import {
  Button, useRefresh, useResourceContext, useTranslate, useUnselectAll,
} from 'react-admin';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  TextField as MuiTextField,
} from '@mui/material';
import { useState } from 'react';
import { xgacDataProvider } from '../../../../dataProviders/xgacDataProvider';

export const MassAcknowledgmentButton = (props: { allAlarms: boolean }) => {

  const { selectedIds } = useListContext();
  const translate = useTranslate();
  const resource = useResourceContext();
  const unselectAll = useUnselectAll(resource);
  const refresh = useRefresh();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [acknowledgeComment, setAcknowledgeComment] = useState('');
  const acknowledgeClick = () => {

    xgacDataProvider.massAcknowledge(acknowledgeComment, props.allAlarms ? null : selectedIds);
    setAcknowledgeComment('');
    setDialogOpen(false);
    unselectAll();
    refresh();

  };
  return (
    <>
      <Button label={props.allAlarms ? 'resources.alarms.text.acknowledge_all' : 'resources.alarms.text.acknowledge_selected' }
        onClick={() => setDialogOpen(true)}/>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          <span>
            {translate('resources.alarms.text.acknowledge_confirmation', {
              length: props.allAlarms
                ? translate('general.text.all').toLowerCase()
                : selectedIds.length,
            })}
          </span>
        </DialogTitle>
        <DialogContent>
          <MuiTextField
            value={acknowledgeComment}
            onChange={(e) => setAcknowledgeComment(e.target.value)}
            label={translate('general.text.message')}
          />
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={() => setDialogOpen(false)} color="warning"/>
          <Button label="resources.alarms.text.acknowledge" onClick={acknowledgeClick}/>
        </DialogActions>
      </Dialog>
    </>
  );

};
