import {
  DateField, DateFieldProps, useRecordContext,
} from 'react-admin';
import _ from 'lodash';
import moment from 'moment/moment';
import { Tooltip } from '@mui/material';

export const DateFieldWithTime = (props: DateFieldProps & { timeOnHover?: boolean }) => {

  const record = useRecordContext();
  if (!record || !props.source) return null;
  const dateValue = _.get(record, props.source);
  const time = moment(dateValue).format('HH:mm:ss');
  if (props.timeOnHover) {

    return (
      <Tooltip title={time}>
        <div>
          <DateField {...props} />
        </div>
      </Tooltip>
    );

  }
  return (
    <DateField {...props} />
  );

};
