import { useTranslate } from 'ra-core';
import { Title } from 'react-admin';
import React, { useState } from 'react';
import { Chip } from '@mui/material';
import moment from 'moment-timezone';
import { ReportCardList } from '../../../components/reports';

export const ReportTriggerList = () => {

  const translate = useTranslate();
  const possibleReportTypes = ['inactivityMonitor', 'alarms', 'newAlarm'];

  const [lastSaved, setLastSaved] = useState<Date | false | 'saving' | null>(null);

  return (
    <>
      <Title title="resources.reports.title"/>
      <h3>{translate('resources.reports.title')}
        <div style={{ marginLeft: '10px', display: 'inline' }}>
          {lastSaved === false && (
            <Chip size="small" label={translate('general.text.cannot_save')} color="error"/>
          )}
          {lastSaved === 'saving' && (
            <Chip size="small" label={translate('general.text.saving')} color="info"/>
          )}
          {lastSaved === null && (
            <Chip size="small" label={translate('general.text.no_changes')}/>
          )}
          {(lastSaved && lastSaved !== 'saving') && (
            <Chip
              size="small"
              label={`${translate('general.text.saved')}: ${moment(lastSaved).format('HH:mm:ss')}`}
              color="primary"/>
          )}
        </div>
      </h3>
      <p>{translate('resources.reports.text.sub_title')}</p>
      <ReportCardList possibleReportTypes={possibleReportTypes} setLastSaved={setLastSaved} theme="bhvk"/>
    </>
  );

};
