import {
  TextField, useNotify, useRecordContext, useTranslate,
} from 'react-admin';
import { get } from 'lodash';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import * as React from 'react';
import copy from 'copy-to-clipboard';

const TextFieldNullable = (props: any) => {

  const translate = useTranslate();
  const notify = useNotify();
  const record = useRecordContext();
  const value = get(record, props.source);

  const copyValues = () => {

    copy(value || '');
    notify('general.text.copied');

  };
  if (!value || value === '') return <TextField source="value" record={{ value: translate(props.emptyText || 'general.text.none') }} className="gray"/>;
  if (props.privacy) {

    return <div style={{ display: 'flex' }}>
      <span className="privacyBlur">
        <span> <TextField {...props}/></span>
      </span>
      <IconButton onClick={copyValues} sx={{
        display: 'inline', height: 'fit-content', marginTop: 'auto', marginBottom: 'auto',
      }} size="small">
        <Tooltip title={translate('general.text.copy')}>
          <ContentCopyIcon sx={{ fontSize: '12px' }}/>
        </Tooltip>
      </IconButton>
    </div>;

  }
  return <TextField {...props}/>;

};

export default TextFieldNullable;
