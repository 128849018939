import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  EditButton,
  List,
  ReferenceInput,
  required,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import * as React from 'react';
import { exporter } from '../../../utils/customExporter';
import { GoToButtonsButton } from '../components/buttons/GoToButtonsButton';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { HasRoles } from '../../../components/HasRoles';
import authProvider from '../../../utils/authProvider';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';

const alarmLevelMap = [
  { name: 'resources.customer-links.fields.level.red', id: 0 },
  { name: 'resources.customer-links.fields.level.orange', id: -5 },
  { name: 'resources.customer-links.fields.level.green', id: -15 },
];

const editTransform = (data: Record<string, any>) => ({
  name: data.name,
  alarmLevel: data.alarmLevel,
});

const roles = authProvider.getRoles();
export const FlicHubList = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Stable}/>
    <List exporter={exporter} title="resources.flic-hubs.text.title">
      <Datagrid rowClick="toggleSelection" bulkActionButtons={roles.includes('admin') ? undefined : false}>
        <TextField source="name" label="general.fields.name"/>
        <SelectField source="alarmLevel" choices={alarmLevelMap}/>
        <BooleanField source="deleted" label="resources.flic-buttons.fields.deleted" />
        <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
        <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
        <GoToButtonsButton/>
        <HasRoles anyOf={['admin']}>
          <EditButton/>
        </HasRoles>
      </Datagrid>
    </List>
  </>
);

export const FlicHubEdit = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Beta}/>
    <EditWithSuccess title="resources.flic-hubs.text.title" transform={editTransform}>
      <SimpleForm>
        <TextInput source="name" label="general.fields.name"/>
        <SelectInput source="alarmLevel" choices={alarmLevelMap} validate={required()}/>
        <ReferenceInput source="xgacCustomerId" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
          <AutocompleteInput disabled />
        </ReferenceInput>
        <BooleanInput source="deleted" disabled label="resources.flic-buttons.fields.deleted"/>
      </SimpleForm>
    </EditWithSuccess>
  </>
);

export const FlicHubCreate = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Beta}/>
    <CreateWithSuccess title="resources.flic-hubs.text.title">
      <SimpleForm>
        <TextInput source="name" label="general.fields.name"/>
        <SelectInput source="alarmLevel" choices={alarmLevelMap} validate={required()}/>
      </SimpleForm>
    </CreateWithSuccess>
  </>
);
