import type {
  AlarmUpdateDto,
  AlarmCreateDto,
  AssetUpdateDto,
  AssetCreateDto,
  AssetGroupUpdateDto,
  AssetGroupCreateDto,
  BeaconUpdateDto,
  BeaconCreateDto,
  ContactListUpdateDto,
  ContactListCreateDto,
  CustomerUpdateDto,
  CustomerCreateDto,
  CustomerAlarmCenterRelationUpdateDto,
  CustomerAlarmCenterRelationCreateDto,
  CustomerUserRelationCreateDto,
  DeviceUpdateDto,
  DeviceCreateDto,
  ObservationTriggerUpdateDto,
  ObservationTriggerCreateDto,
  OverlayUpdateDto,
  OverlayCreateDto,
  StaticResponderUpdateDto,
  StaticResponderCreateDto,
  UserUpdateDto,
  UserCreateDto,
  WebhookUpdateDto,
  WebhookCreateDto,
  ZoneUpdateDto,
  ZoneCreateDto,
} from '@x-guard/xgac-node-main-api';

export const toXgacUpdateDto = (patchData: any, recordName: string) => {

  const returnData = { ...patchData };
  switch (recordName) {

  case 'alarms':
    return returnData as AlarmUpdateDto;
  case 'assets':
    return returnData as AssetUpdateDto;
  case 'asset-groups':
    return returnData as AssetGroupUpdateDto;
  case 'beacons':
    return returnData as BeaconUpdateDto;
  case 'contact-lists':
    return returnData as ContactListUpdateDto;
  case 'customers':
    return returnData as CustomerUpdateDto;
  case 'customer-alarm-center-relations':
    return returnData as CustomerAlarmCenterRelationUpdateDto;
  case 'devices':
    return returnData as DeviceUpdateDto;
  case 'observation-triggers':
    return returnData as ObservationTriggerUpdateDto;
  case 'overlays':
    return returnData as OverlayUpdateDto;
  case 'staticResponders':
    return returnData as StaticResponderUpdateDto;
  case 'users':
    return returnData as UserUpdateDto;
  case 'webhooks':
    return returnData as WebhookUpdateDto;
  case 'zones':
    return returnData as ZoneUpdateDto;
  default:
    return returnData;

  }

};

export const toXgacCreateDto = (data: any, recordName: string) => {

  const returnData = { ...data };
  switch (recordName) {

  case 'alarms':
    return returnData as AlarmCreateDto;
  case 'assets':
    return returnData as AssetCreateDto;
  case 'asset-groups':
    return returnData as AssetGroupCreateDto;
  case 'beacons':
    return returnData as BeaconCreateDto;
  case 'contact-lists':
    return returnData as ContactListCreateDto;
  case 'customers':
    return returnData as CustomerCreateDto;
  case 'customer-alarm-center-relations':
    return returnData as CustomerAlarmCenterRelationCreateDto;
  case 'customer-user-relations':
    return {
      customer: { _id: returnData.customer._id, _ref: 'Customer' },
      user: { _id: returnData['user._id'], _ref: 'User' },
      assetGroupRestrictions: returnData.assetGroupRestrictions?.map((assetGroup: string) => ({ _id: assetGroup, _ref: 'AssetGroup' })),
      roles: returnData.roles,
    } as CustomerUserRelationCreateDto;
  case 'devices':
    return returnData as DeviceCreateDto;
  case 'observation-triggers':
    return returnData as ObservationTriggerCreateDto;
  case 'overlays':
    return returnData as OverlayCreateDto;
  case 'staticResponders':
    return returnData as StaticResponderCreateDto;
  case 'users':
    return returnData as UserCreateDto;
  case 'webhooks':
    return returnData as WebhookCreateDto;
  case 'zones':
    return returnData as ZoneCreateDto;
  default:
    return returnData;

  }

};
