import {
  DateInput, InputProps, useInput,
} from 'react-admin';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslate } from 'ra-core';

export const IncidentCounterField = (props: InputProps & { disabled?: boolean }) => {

  const { field } = useInput({ source: props.source });
  const [storedValue, setStoredValue] = useState(field.value?.countFromDate ? 'lastIncident' : 'lastAlarm');
  const translate = useTranslate();

  useEffect(() => {

    if (storedValue === 'lastAlarm') {

      field.onChange({
        ...field.value,
        countFromDate: null,
      });

    }

  }, [storedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={props.disabled ? 'disabled' : ''}>
      <RadioGroup row value={storedValue} onChange={(event, value) => setStoredValue(value)}>
        <FormControlLabel
          control={<Radio/>}
          label={translate('resources.kiosks.text.kioskConfig.homepage.incidentCounter.last_alarm')}
          value={'lastAlarm'}
          disabled={props.disabled}
        />
        <FormControlLabel
          control={<Radio/>}
          label={<DateInput
            source={`${props.source}.countFromDate`}
            label="general.text.from"
            disabled={storedValue !== 'lastIncident' || props.disabled}/> }
          value={'lastIncident'}
          disabled={props.disabled}
        />
      </RadioGroup>
    </div>
  );

};
