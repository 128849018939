import {
  TextField,
  TextInput,
  ReferenceInput,
  SimpleForm,
  EditButton,
  required,
  AutocompleteInput,
  SelectInput,
  FormDataConsumer,
  Button,
  useTranslate,
  useNotify,
  Loading,
} from 'react-admin';
import {
  Datagrid, IfCanAccess,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import { ListLive } from '@react-admin/ra-realtime';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useListContext } from 'ra-core';
import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as MuiTextField,
} from '@mui/material';
import { CustomToolbar } from '../../../components/CustomToolBar';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { exporter } from '../../../utils/customExporter';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import ReferenceFieldNullable from '../../../components/fields/ReferenceFieldNullable';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { LIST_DEBOUNCE, XGAC_LORA_API_URL } from '../../../config';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { LoraConfigInput } from '../../../components/inputs/LoraConfigInput';
import { loraDeviceTypes } from '../../../lib/constants/selectChoices';
import NumberFieldNullable from '../../../components/fields/NumberFieldNullable';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { httpClient } from '../../../utils/httpClient';
import authProvider from '../../../utils/authProvider';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

const LoraUrl = XGAC_LORA_API_URL;

const transform = (data: Record<string, any>) => {

  if (!data.loraConfig?.type) {

    data.loraConfig = null;

  }
  return {
    ...data,
    asset: data.asset?._id ? { _ref: 'Asset', _id: data.asset._id } : null,
  };

};

const deviceTypes = [
  { name: 'resources.devices.text.types.lora', id: 'lora' },
  { name: 'resources.devices.text.types.loraGateway', id: 'loraGateway' },
  { name: 'resources.devices.text.types.appDevice', id: 'appDevice' },
  { name: 'resources.devices.text.types.flic', id: 'flic' },
  { name: 'resources.devices.text.types.generic', id: 'generic' },
];

const DeviceBulkActionButtons = (props: any) => {

  const [downLinkDialogOpen, setDownLinkDialogOpen] = useState(false);
  const [hexValue, setHexValue] = useState('');
  const [downlinkLoading, setDownlinkLoading] = useState(false);
  const [downlinkResult, setDownlinkResult] = useState<any>(null);
  const { selectedIds } = useListContext();
  const translate = useTranslate();
  const notify = useNotify();

  const isAdmin = authProvider.isAdmin();

  const handleDownlink = async () => {

    const devices = await xgacDataProvider.getMany('devices', { ids: selectedIds });

    if (!devices.data) {

      notify('resources.devices.text.error_getting_devices', { type: 'error' });
      setHexValue('');
      setDownLinkDialogOpen(false);
      return;

    }

    const devEuis = devices.data?.map((device: any) => device.deviceId);
    setDownlinkLoading(true);
    const downlinkResponse = await httpClient(
      `${LoraUrl}/lora/iotracker/v2/downlink`,
      { method: 'POST' },
      { devEuis, payload: hexValue, confirmed: true },
    );

    setDownlinkLoading(false);
    setDownlinkResult(downlinkResponse.json?.results);
    setHexValue('');

  };

  const handleCloseDialog = () => {

    setDownLinkDialogOpen(false);
    setDownlinkResult(null);
    setHexValue('');
    setDownlinkLoading(false);

  };

  return (
    <>
      <CustomBulkActionButtons {...props} />
      {isAdmin && (
        <>
          <Button label="resources.devices.actions.downLink" startIcon={<DownloadForOfflineIcon/>} onClick={() => setDownLinkDialogOpen(true)}/>
          <Dialog open={downLinkDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>
              {translate('resources.devices.actions.downLink')}
            </DialogTitle>
            <DialogContent>
              {!downlinkLoading && !downlinkResult && (
                <>
                  <span>{translate('resources.devices.text.downLink_explanation')}</span>
                  <MuiTextField value={hexValue} onChange={(e) => setHexValue(e.target.value)} label="Hex"/>
                </>
              )}
              {downlinkLoading && (
                <Loading loadingSecondary=""/>
              )}
              {downlinkResult && (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate('resources.devices.fields.deviceId')}
                        </TableCell>
                        <TableCell>
                          {translate('resources.devices.text.result')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {downlinkResult.map((result: any) => (
                          <TableRow>
                            <TableCell>
                              {result.devEui}
                            </TableCell>
                            <TableCell>
                              {result.value}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    </TableBody>
                  </Table>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              {!downlinkLoading && !downlinkResult && (
                <Button onClick={handleDownlink} label="resources.devices.actions.downLink"/>
              )}
              <Button onClick={handleCloseDialog} label="ra.action.cancel"/>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );

};
export const DeviceList = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Stable}/>
    <ListLive debounce={LIST_DEBOUNCE} exporter={exporter} title="resources.devices.text.title">
      <Datagrid rowClick="toggleSelection" bulkActionButtons={<DeviceBulkActionButtons/>}>
        <ReferenceFieldNullable source="asset._id" reference="assets" label="resources.assets.text.single_name"/>
        <TextField source="deviceId" label="resources.devices.fields.deviceId"/>
        <TextFieldNullable source="type"/>
        <NumberFieldNullable source="battery" label="resources.devices.fields.battery"/>
        <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
        <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
        <IfCanAccess action="edit">
          <EditButton/>
        </IfCanAccess>
        <HasRoles anyOf={['developer_admin']}>
          <AuditlogButton/>
        </HasRoles>
      </Datagrid>
    </ListLive>
  </>
);

export const DeviceEdit = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Beta}/>
    <EditWithSuccess transform={transform} title="resources.devices.text.title" >
      <SimpleForm toolbar={<CustomToolbar/>}>
        <ReferenceInput source="asset._id" reference="all-assets" sort={{ field: 'name', order: 'ASC' }}>
          <AutocompleteInput autoHighlight={true} label="resources.assets.text.single_name"/>
        </ReferenceInput>
        <TextInput source="deviceId" validate={required()} label="resources.devices.fields.deviceId"/>
        <SelectInput source="type" validate={required()} defaultValue="generic" choices={deviceTypes}/>
        <FormDataConsumer>
          {({ formData }) => {

            if (formData.type === 'lora') {

              return (<SelectInput source="loraConfig.type" defaultValue={null} choices={loraDeviceTypes}
                emptyText="resources.devices.text.no_lora_config"
                label="resources.devices.fields.loraConfig.type"
                helperText="resources.devices.fields.loraConfig.type_help"
                shouldUnregister
              />);

            }
            return null;

          } }
        </FormDataConsumer>
        <LoraConfigInput source="loraConfig"/>
      </SimpleForm>
    </EditWithSuccess>
  </>
);
export const DeviceCreate = () => {

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta}/>
      <CreateWithSuccess transform={transform} title="Devices">
        <SimpleForm>
          <ReferenceInput source="asset._id" reference="all-assets" sort={{ field: 'name', order: 'ASC' }}>
            <AutocompleteInput label="resources.assets.text.single_name" autoHighlight={true}/>
          </ReferenceInput>
          <TextInput source="deviceId" validate={required()}/>
          <SelectInput source="type" validate={required()} defaultValue="generic" choices={deviceTypes}/>
          <FormDataConsumer>
            {({ formData }) => {

              if (formData.type === 'lora') {

                return (<SelectInput source="loraConfig.type" defaultValue={null} choices={loraDeviceTypes}
                  emptyText="resources.devices.text.no_lora_config"
                  label="resources.devices.fields.loraConfig.type"
                  helperText="resources.devices.fields.loraConfig.type_help"/>);

              }
              return null;

            } }
          </FormDataConsumer>
          <LoraConfigInput source="loraConfig"/>
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};
