import {
  AddItemButton,
  ArrayInput,
  AutocompleteInput,
  SimpleFormIterator,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import * as React from 'react';
import _ from 'lodash';
import { Typography } from '@mui/material';

export const ArrayInputWithAutoComplete = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext();
  if (!record) return null;

  const choices = props.choices;

  const recordValues = record[props.source].map((value: any) => {

    return { name: value, id: value };

  });
  const combinedChoices: Array<any> = _.unionBy(recordValues, choices, 'id');

  return (
    <ArrayInput source="eventCodes">
      <SimpleFormIterator disableReordering
        addButton={<div><AddItemButton/><Typography variant="body1" sx={{ display: 'inline', fontSize: '15px' }}>
          {translate('resources.observation-triggers.text.add_eventcode')}
        </Typography></div>}>
        <AutocompleteInput
          onCreate={(value) => {

            const newChoice = { name: value, id: value };
            combinedChoices.push(newChoice);
            return newChoice;

          }}
          choices={combinedChoices}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );

};
