import { useTranslate } from 'react-admin';
import { Alert } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReactMarkdown from 'react-markdown';

export const PageExplanation = ({ text, options }: { text: string; options?: any }) => {

  const translate = useTranslate();

  let translatedText = translate(text);
  if (options) {

    translatedText = translate(text, options);

  }
  if (!translatedText) {

    return null;

  }
  return (
    <Alert severity="info" icon={<InfoOutlinedIcon/>} sx={{ whiteSpace: 'pre-line' }} >
      <ReactMarkdown className="page-info-markdown" >{translatedText}</ReactMarkdown>
    </Alert>
  );

};
