import {
  Children, cloneElement, ReactNode, useEffect, useState,
} from 'react';
import { Checkbox } from '@mui/material';
import { Confirm, useInput, useRecordContext } from 'react-admin';
import _ from 'lodash';

const checkIfValueIsNotEmpty = (value: any) => {

  if (value === undefined) {

    return false;

  }
  if (typeof value === 'object' && typeof value !== 'string' && value !== null) {

    if (Object.keys(value).length === 0) {

      return false;

    }
    const hasValues = Object.values(value).some((v) => checkIfValueIsNotEmpty(v));
    return hasValues;

  }
  return true;

};
export const KioskFormBlock = (props: {
  title?: string;
  children: ReactNode;
  hasOverwrite?: boolean;
  fields?: string[];
  overwriteSource?: string;
  openCustomerDialog?: () => void;
}) => {

  const {
    children,
    hasOverwrite = true,
    fields,
    openCustomerDialog,
  } = props;

  const [enabled, setEnabled] = useState(!hasOverwrite);
  const [singleEditConfirmOpen, setSingleEditConfirmOpen] = useState(false);
  const record = useRecordContext();
  const { field } = useInput({ source: props.overwriteSource || 'disabled', defaultValue: !hasOverwrite });

  useEffect(() => {

    if (record && hasOverwrite && fields) {

      const hasValues = fields.some((innerField) => {

        const value = _.get(record, innerField);
        return checkIfValueIsNotEmpty(value);

      });
      if (hasValues) {

        setEnabled(true);

      }

      field.onChange(hasValues);

    }

  }, []); // eslint-disable-line

  useEffect(() => {

    field.onChange(enabled);

}, []); // eslint-disable-line

  useEffect(() => {

    field.onChange(enabled);

  }, [enabled]); // eslint-disable-line

  const childrenWithEnabled = Children.map(children, (child) => {

    return cloneElement(child as any, {
      shouldUnregister: true,
    });

  });

  const handleEnabledClick = (e: any) => {

    e.preventDefault();
    if (e.target.checked) {

      if (sessionStorage.getItem('singleEditConfirm') === 'true') {

        setEnabled(true);
        return;

      }
      setSingleEditConfirmOpen(true);

    } else {

      setEnabled(false);

    }

  };

  const handleSingleEditConfirmClose = () => {

    setSingleEditConfirmOpen(false);
    setEnabled(false);
    if (openCustomerDialog) {

      openCustomerDialog();

    }

  };

  const handleSingleEditConfirmConfirm = () => {

    setSingleEditConfirmOpen(false);
    sessionStorage.setItem('singleEditConfirm', 'true');
    setEnabled(true);

  };

  if (!hasOverwrite) {

    return <div>
      {childrenWithEnabled}
    </div>;

  }

  return (
    <>
      <div className={[
        'overwrite-form',
        enabled ? 'overwrite-form-enabled' : 'overwrite-form-disabled',
      ].join(' ')}>
        <div className='title-and-checkbox'>
          <span style={!hasOverwrite ? {
            paddingTop: '10px',
          } : {}
          }>{props.title}</span>
          {hasOverwrite && (
            <Checkbox checked={enabled} onChange={handleEnabledClick}/>
          )}
        </div>
        <div className={'content'}>
          {childrenWithEnabled}
        </div>
      </div>
      <Confirm
        title={'resources.kiosks.text.edit_single_confirm_title'}
        content={'resources.kiosks.text.edit_single_confirm_content'}
        confirm={'resources.kiosks.text.edit_single_confirm_confirm'}
        cancel={'resources.kiosks.text.edit_single_confirm_cancel'}
        onClose={handleSingleEditConfirmClose}
        onConfirm={handleSingleEditConfirmConfirm}
        isOpen={singleEditConfirmOpen}
        disableEscapeKeyDown={true}
        disableScrollLock={true}
      />
    </>
  );

};
