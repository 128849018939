import queryString from 'query-string';
import { GetListParams, Identifier } from 'react-admin';
import { parsePhoneNumber } from 'libphonenumber-js';
import { AssetGroupRef } from '@x-guard/xgac-types/xgac';
import { DEVICE_REGISTRATION_API_URL } from '../config';
import { httpClient } from '../utils/httpClient';
// eslint-disable-next-line import/no-cycle
import { xgacDataProvider } from './xgacDataProvider';
import { updateComparison } from './updateComparison';
import { getCurrentCustomer } from '../lib/currentCustomer';
import { RegisterUsers } from '../utils/qrCsvService';

const apiUrl = DEVICE_REGISTRATION_API_URL;

function translateResource(resource: string) {

  if (resource === 'templates') return 'code/template';
  if (resource === 'lite-templates') return 'code/template/lite';
  if (resource === 'pages') return 'code/page';
  if (resource === 'codes') return 'code';
  if (resource === 'actions') return 'action';
  return resource;

}

export const actionOrder = [
  'assign_customer',
  'asset_group',
  'create_user',
  'user_restriction_group',
  'config_group',
  'email',
  'email',
];

const defaultActions = (codeTemplate: string, customerId: string) => {

  return [
    {
      codeTemplate,
      actionTemplate: 'assign_customer',
      order: 1,
      xgacValue: customerId,
    },
    {
      codeTemplate,
      actionTemplate: 'asset_group',
      order: 2,
      xgacValue: [],
    },
    {
      codeTemplate,
      actionTemplate: 'user_restriction_group',
      order: 3,
      xgacValue: [],
    },
    {
      codeTemplate,
      actionTemplate: 'config_group',
      order: 4,
      xgacValue: [],
    },
    {
      codeTemplate,
      actionTemplate: 'create_user',
      order: 5,
      xgacValue: null,
    },
    {
      codeTemplate,
      actionTemplate: 'email',
      order: 6,
      xgacValue: {
        recipientType: 'asset',
        template: {
          label: 'Welcome e-mail',
          value: 'welcome',
        },
        recipientList: [],
      },
    },
    {
      codeTemplate,
      actionTemplate: 'email',
      order: 7,
      xgacValue: {
        recipientType: 'manual',
        template: {
          label: 'New registration',
          value: 'register_notify',
        },
        recipientList: [{
          label: 'demo02.olc9rh@zapiermail.com',
        }],
      },
    },
  ];

};
export const qrCodeDataProvider = {
  getList: async (resource: string, params: GetListParams) => {

    resource = translateResource(resource);
    if (!params.pagination) {

      params.pagination = { page: 1, perPage: 100 };

    }
    if (!params.sort) {

      params.sort = { field: 'createdAt', order: 'DESC' };

    }
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filter = params.filter;
    const customer = params.meta?.customer || getCurrentCustomer();
    if (filter.q) {

      filter.search = filter.q;
      delete filter.q;

    }

    const query = {
      sort: `${((order === 'DESC') ? '-' : '')}${field}`,
      offset: (page - 1) * perPage,
      limit: perPage,
      xgacCustomerId: customer.value,
      ...filter,
    };

    const url = `${apiUrl}/v3/deployment/${resource}?${queryString.stringify(query)}`;

    const result = await httpClient(url).then(({ json }) => ({
      data: json.result.map((record: Record<string, unknown>) => (
        {
          id: record._id,
          ...record,
        }
      )),
      total: json.total,
    }));

    if (resource === 'code/template/lite') {

      result.data = result.data.map((innerResult: any) => {

        innerResult.assetGroups = innerResult.assetGroups.map((assetGroup: AssetGroupRef) => {

          return assetGroup._id;

        });
        return innerResult;

      });

    }
    return result;

  },

  getOne: async (resource: string, params: any) => {

    resource = translateResource(resource);

    const result = await httpClient(`${apiUrl}/v3/deployment/${resource}/${params.id}`);

    if (resource === 'code/template') {

      return {
        data: {
          ...result.json,
          id: result.json._id,
          actions: result.json.actions.map((action: any) => {

            if (action.actionTemplate?.functionName === 'user_restriction_group') {

              action.xgacValue = action.xgacValue?.map((xgacValue: any) => {

                const parsedValue = JSON.parse(xgacValue);
                return parsedValue.id;

              });

            }

            return {
              ...action,
              xgacValue: action.xgacValue || action.value,
            };

          }),
        },
      };

    }

    return {
      data: {
        id: result.json._id,
        ...result.json,
      },
    };

  },

  update: async (resource: string, params: any) => {

    params.data = updateComparison(params.data, params.previousData);

    resource = translateResource(resource);

    let url = `${apiUrl}/v3/deployment/${resource}/${params.id}`;
    if (resource === 'code') {

      url = `${apiUrl}/v3/deployment/${resource}`;

    }

    if (resource === 'action' && params.meta?.functionName === 'user_restriction_group' && params.data.xgacValue?.length > 0) {

      const groups = await xgacDataProvider.getMany('asset-groups', { ids: params.data.xgacValue });
      params.data.xgacValue = groups.data.map((group) => (JSON.stringify({ id: group._id, name: group.name })));

    }
    return httpClient(url, {
      method: 'PATCH',
    }, { ...params.data }).then(({ json }) => ({ data: { ...json, id: json._id } }));

  },

  create: async (resource: string, params: any) => {

    resource = translateResource(resource);

    const currentCustomer = getCurrentCustomer();
    const body = {
      ...params.data,
      ...(!['code', 'action'].includes(resource) ? { xgacCustomerId: params.data.xgacCustomerId || currentCustomer?.value } : {}),
    };

    if (resource === 'code/template') {

      const postResult = await httpClient(`${apiUrl}/v3/deployment/${resource}`, {
        method: 'POST',
      }, body).then(({ json }) => ({
        data: json,
      }));

      const filledActions = defaultActions(postResult.data.id, currentCustomer?.value || '');
      const promises = [];
      for (const action of filledActions) {

        promises.push(httpClient(`${apiUrl}/v3/deployment/action`, {
          method: 'POST',
        }, action));

      }

      Promise.all(promises);

      return postResult;

    }
    if (resource === 'action' && params.meta?.functionName === 'user_restriction_group' && params.data.xgacValue?.length > 0) {

      const groups = await xgacDataProvider.getMany('asset-groups', { ids: params.data.xgacValue });
      params.data.xgacValue = groups.data.map((group) => ({ id: group._id, label: group.name }));

    }

    return httpClient(`${apiUrl}/v3/deployment/${resource}`, {
      method: 'POST',
    }, body).then(({ json }) => ({
      data: json,
    }));

  },

  delete: (resource: string, params: any) => {

    resource = translateResource(resource);
    if (resource === 'code') {

      return httpClient(`${apiUrl}/v3/deployment/${resource}`, {
        method: 'DELETE',
      }, { code: params.id }).then(({ json }) => ({ data: json }));

    }
    return httpClient(`${apiUrl}/v3/deployment/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));

  },

  deleteMany: async (resource: string, params: any): Promise<any> => {

    resource = translateResource(resource);
    const deletedIds: Identifier[] = [];
    for (const currentId of params.ids) {

      if (resource === 'code') {

        await httpClient(`${apiUrl}/v3/deployment/${resource}`, {
          method: 'DELETE',
        }, { code: currentId }).then(({ json }) => ({ data: json }));

      }

      try {

        await httpClient(`${apiUrl}/v3/deployment/${resource}/${currentId}`, {
          method: 'DELETE',
        });
        deletedIds.push(currentId);

      } catch (e) {

        console.log({ message: `failed to delete ${currentId}`, e });

      }

    }

    return { data: deletedIds };

  },

  registerUser: async (
    name: string,
    username: string,
    properties: { email: string; phoneNumber: string; smsNumber: string },
    externalId: string | undefined,
    available: boolean,
    downloadFiles = true,
    code?: string,
  ) => {

    const firstName = name.split(' ')[0];
    const restOfName = name.split(' ').slice(1).join(' ');
    let template = null;
    if (!code) {

      try {

        const templates = await qrCodeDataProvider.getList('lite-templates', {
          pagination: { page: 1, perPage: 1 },
          filter: {},
          sort: { field: 'createdAt', order: 'ASC' },
        });
        template = templates.data[0];

      } catch (e) {

        return {
          message: 'No template found',
          status: 400,
        };

      }

    }

    const usedCode = code || template?.code.code;
    if (!usedCode) {

      return {
        message: 'No code found in provided template',
        status: 400,
      };

    }
    const codeData = {
      code: code || template?.code.code,
      variables: [
        {
          key: 'register_username',
          locked: true,
          value: { type: 'string', data: username },
        },
        {
          key: 'register_external_id',
          locked: false,
          value: { type: 'string', data: externalId || '' },
        },
        {
          key: 'register_personal_firstname',
          locked: false,
          value: { type: 'string', data: firstName },
        },
        {
          key: 'register_personal_lastname',
          locked: false,
          value: { type: 'string', data: restOfName },
        },
        {
          key: 'register_personal_phone_country',
          locked: true,
          value: { type: 'string', data: properties.phoneNumber ? parsePhoneNumber(properties.phoneNumber)?.countryCallingCode || '' : '' },
        },
        {
          key: 'register_personal_phone_number',
          locked: true,
          value: { type: 'string', data: properties.phoneNumber ? parsePhoneNumber(properties.phoneNumber)?.nationalNumber || '' : '' },
        },
        {
          key: 'register_user_email',
          locked: true,
          value: { type: 'string', data: properties.email },
        },
        {
          key: 'register_personal_available',
          locked: false,
          value: { type: 'boolean', data: available },
        },
        {
          key: 'register_locale',
          locked: false,
          value: { type: 'string', data: 'nl-NL' },
        },
        {
          key: 'register_timezone',
          locked: false,
          value: { type: 'string', data: 'europe/amsterdam' },
        },
      ],
      limit: 1,
    };

    await RegisterUsers([codeData], undefined, undefined, downloadFiles);

    return {
      message: 'User registered',
      status: 200,
    };

  },

};
