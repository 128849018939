import {
  useEditContext,
  useNotify,
  useRedirect,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import { Edit } from '@react-admin/ra-rbac';
import _ from 'lodash';
import { getCurrentCustomer } from '../../lib/currentCustomer';

export const EditWithSuccess = (props: any) => {

  const translate = useTranslate();
  const CheckCustomer = () => {

    const { record } = useEditContext();

    // Dit kijkt naar de huidige customer in de sessionstorage, en de customer uit de record.
    // Als deze niet gelijk is wordt de customer uit de record naar sessionstorage geschreven
    // Dit is voor als er links naar het dashboard verstuurd worden, zo wordt de juiste customer opgepakt
    if (record) {

      const currentCustomer = getCurrentCustomer();
      if (record.customer && record.customer?._id !== currentCustomer?.value) {

        const allCustomersString = localStorage.getItem('customers');
        const allCustomers = JSON.parse(allCustomersString || '');

        const newCustomer = _.find(allCustomers, { value: record.customer._id });
        if (newCustomer) {

          sessionStorage.setItem('currentCustomer', JSON.stringify(newCustomer));
          window.location.reload();

        }

      }

    }
    return null;

  };

  function capitalizeFirstLetter(string: string) {

    return string.charAt(0).toUpperCase() + string.slice(1, -1);

  }
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext() || '';
  const OnSuccess = () => {

    notify(`${capitalizeFirstLetter(resource)} ${translate('general.text.updated')}`, { type: 'success' });
    redirect(props.redirect ?? `/${resource}`);

  };
  return (<Edit actions={false} mutationOptions={{ onSuccess: OnSuccess }}{...props} mutationMode="pessimistic">
    <CheckCustomer/>
    {props.children}
  </Edit>);

};
