import { Card, CardContent, styled } from '@mui/material';
import clsx from 'clsx';
import {
  LayoutProps,
  SkipNavigationButton,
  LayoutClasses,
  Inspector,
  Sidebar as DefaultSidebar,
  useTranslate,
  useGetIdentity,
  Button,
  useLogout,
} from 'react-admin';
import { useHref } from 'react-router-dom';
import { AppLocationContext } from '@react-admin/ra-navigation';
import React, { useEffect } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import ReactMarkdown from 'react-markdown';
import CircularProgress from '@mui/material/CircularProgress';
import { useRedirect } from 'ra-core';
import { Asset, Device } from '@x-guard/xgac-types/xgac';
import { CustomMenu } from './Menu';
import { MyAppBar } from './MyAppBar';
import authProvider from '../../utils/authProvider';
import { UsernameModal } from './components/usernameModal';
import { useAutoUnselector } from '../../utils/selectionResetter';
import { httpClient } from '../../utils/httpClient';
import { XGAC_MAIN_API_URL, XGAC_OTHER_DOMAIN } from '../../config';
import { getCurrentCustomer } from '../../lib/currentCustomer';

const Grid = Grid2;

const PREFIX = 'RaLayout';
const StyledLayout = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  minWidth: 'fit-content',
  width: '100%',
  color: theme.palette.getContrastText(theme.palette.background.default),

  [`& .${LayoutClasses.appFrame}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
    },
  },
  [`& .${LayoutClasses.contentWithSidebar}`]: {
    display: 'flex',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${LayoutClasses.content}`]: {
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    padding: 0,
    [theme.breakpoints.up('xs')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  },
}));

export const BHVKLayout = (props: LayoutProps) => {

  // styling
  import('./scss/App.scss');

  const {
    appBar: AppBar = MyAppBar,
    children,
    className,
    error: errorComponent,
    menu: Menu = CustomMenu,
    sidebar: Sidebar = DefaultSidebar,
    ...rest
  } = props;

  const redirect = useRedirect();

  const { refetch, data } = useGetIdentity();
  const roles = authProvider.getRoles();
  // determine fullscreen map
  const fullscreenPages = ['/buttons', '/maak-alarm', '/overlays', '/kiosks'];

  const noMenuPages = ['/calculation'];

  const href = useHref('?');
  const isFullscreen = fullscreenPages.includes(href);

  const currentCustomer = getCurrentCustomer();
  const translate = useTranslate();
  const logout = useLogout();
  useAutoUnselector();

  const hoverToView = translate('general.text.hover_to_view');

  useEffect(() => {

    if (refetch) {

      refetch();

    }

  }, [refetch]);

  useEffect(() => {

    if (!localStorage.getItem('already_opened')) {

      httpClient(`${XGAC_MAIN_API_URL}/specials/bhv-knop/device-list?customer._id=${currentCustomer?.value}`).then((res: any) => {

        if (res.json) {

          const isDemo = res.json.result.filter((asset: Asset & { device: Device }) => {

            return asset.device.deviceId.substring(0, 4) !== 'Demo';

          }).length === 0;

          if (isDemo) {

            localStorage.setItem('openBuildingDialog', 'true');

            redirect('/buttons');

          }

        }

      });
      localStorage.setItem('already_opened', 'true');

    }

  }, [currentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  if (noMenuPages.includes(href)) {

    return (
      <>
        {children}
      </>
    );

  }

  if (data?.id && !roles) {

    return (
      <div className="mobile-div">
        <div>
          <Button onClick={logout} variant="contained" sx={{
            display: 'block', marginRight: '5px', marginLeft: 'auto',
          }}>
            <>{translate('ra.auth.logout')}</>
          </Button>
          <img src='/assets/img/bhvk.svg' alt="BHV-Knop.nl" width="50%" style={{
            marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px', display: 'block',
          }}/>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={80} color="info" />
        </div>
      </div>
    );

  }

  if (data?.id && !roles.includes('bhvk_admin') && !roles.includes('bhvk_trial') && !roles.includes('admin')) {

    return (
      <div className="mobile-div">
        <div>
          <img src='/assets/img/bhvk.svg' alt="BHV-Knop.nl" width="50%" style={{
            marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px', display: 'block',
          }}/>
        </div>
        <Grid container justifyContent="center" alignItems="center">
          <Grid xs={11}>
            <Card>
              <CardContent>
                <ReactMarkdown >
                  {translate('general.text.appUserWarning_bhvk')}
                </ReactMarkdown>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                  {roles.includes('customer_admin') && (
                    <Button onClick={() => {

                      window.location.href = XGAC_OTHER_DOMAIN;

                    }} variant="contained" sx={{
                      marginRight: '10px',
                    }}>
                      <>{translate('general.text.go_to_management')}</>
                    </Button>
                  )}
                  <Button onClick={logout} variant="contained">
                    <>{translate('ra.auth.logout')}</>
                  </Button>

                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </div>
    );

  }

  return (
    <AppLocationContext>
      <UsernameModal/>
      <style>{`.privacyBlur::before { content: "${hoverToView}"; }`}</style>
      <StyledLayout className={clsx('layout', className)} {...rest}>
        <SkipNavigationButton />
        <div className={LayoutClasses.appFrame}>
          <AppBar />
          <main className={LayoutClasses.contentWithSidebar}>
            <Sidebar>
              <Menu />
            </Sidebar>
            <div id="main-content" className={`${LayoutClasses.content}${isFullscreen ? ' main-content-fullscreen' : ''}`}>
              {children}
            </div>
          </main>
          <Inspector />
        </div>
      </StyledLayout>
    </AppLocationContext>
  );

};
