import { useTranslate } from 'react-admin';
import * as React from 'react';

export const ButtonPageButtons = (props: { icon: JSX.Element; label: string; onClick: () => void; disabled?: boolean }) => {

  const translate = useTranslate();
  return (
    <button
      className={'device-action-button'}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.icon}
      <span>{translate(props.label)}</span>
    </button>
  );

};

export const ButtonWithIconAndLabel = (props: { icon: string; label: string; onClick: () => void; selected?: boolean }) => {

  const translate = useTranslate();
  return (
    <button
      className={props.selected ? 'add-device-button selected-add-device-button' : 'add-device-button'}
      onClick={props.onClick}
    >
      <img src={props.icon} alt="Item"/>
      <span>{translate(props.label)}</span>
    </button>
  );

};

export const LabelButton = (props: { label: string; onClick: () => void; selected?: boolean; recommended?: boolean }) => {

  const translate = useTranslate();
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      {props.recommended && (
        <div className={'recommended-label'}>
          <span>
            {translate('resources.buttons.text.building_dialog.2.recommended')}
          </span>
        </div>
      )}
      <button
        className={props.selected ? 'add-device-button selected-add-device-button' : 'add-device-button'}
        onClick={props.onClick}
      >
        <span>{translate(props.label)}</span>
      </button>
    </div>
  );

};
