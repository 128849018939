import { useEffect, useState } from 'react';
import { useUnselectAll } from 'react-admin';
import { useLocation } from 'react-router-dom';

export const useAutoUnselector = () => {

  const location = useLocation();
  const [lastResource, setLastResource] = useState<string | null>(null);
  const unselectAll = useUnselectAll(lastResource || '');

  useEffect(() => {

    const slicedResource = location.pathname.slice(1);

    if (slicedResource !== lastResource) {

      if (lastResource) {

        unselectAll();

      }
      setLastResource(slicedResource);

    }

  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

};
