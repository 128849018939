import { fetchUtils, HttpError } from 'react-admin';
import { refreshAuthToken } from './authProvider';

export const httpClient = async (url: string, options: fetchUtils.Options = {}, body?: any, attempt = 0): Promise<{
  status: number;
  headers: Headers;
  body: string;
  json: any;
}> => {

  const token = localStorage.getItem('token');
  if (token === null) {

    throw new Error('No token');

  }

  if (body) {

    options.body = JSON.stringify(body);

  }

  options.headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    ...options.headers,
    ...(options.method === 'DELETE' && (!options.body || options.body?.toString().length === 0) ? {
      'Content-Type': 'text/plain',
    } : {}),
  });
  for (const [key, value] of options.headers.entries()) {

    if (value === null || value === '') {

      options.headers.delete(key);

    }

  }

  try {

    // perform request with return await
    // this is not an anti-pattern because we want to catch errors
    return (await fetchUtils.fetchJson(url, options));

  } catch (e) {

    if (e instanceof HttpError && e.status === 401 && attempt === 0) {

      // refresh and try again
      await refreshAuthToken();

      // we do not need to fix the added header in options.headers
      // this is done by the recursive call
      return httpClient(url, options, body, attempt + 1);

    }

    // otherwise just throw
    throw e;

  }

};
