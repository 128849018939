import {
  Alert,
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import {
  MapContainer, Pane, TileLayer,
} from 'react-leaflet';
import * as React from 'react';
import {
  useEffect, useMemo, useState,
} from 'react';
import { LatLngExpression, Map } from 'leaflet';
import PublicIcon from '@mui/icons-material/Public';
import HomeIcon from '@mui/icons-material/Home';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import moment from 'moment/moment';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import { Asset } from '@x-guard/xgac-types/xgac';
import { useLocaleState, useRefresh, useTranslate } from 'react-admin';
import { xgacDataProvider } from '../dataProviders/xgacDataProvider';
import { CustomMarker } from './leaflet/CustomMarker';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore dit werkt op de juiste manier
import OMSClass from '../lib/spiderfy';
import { LIST_DEBOUNCE, XGAC_WS_API_URL } from '../config';
import { xgacRequest } from '../utils/xgacRequest';
import authProvider from '../utils/authProvider';
import { OMS } from './leaflet/Oms';
import { HasRoles } from './HasRoles';
import { useOverlay } from '../utils/useOverlay';

const OverlappingMarkerSpiderfier = OMSClass as unknown as {
  new(map: Map, options?: {
    nearbyDistance?: number;
    legLengthMultiplier?: number;
  }): OMS;
};
export const LiveMap = () => {

  const translate = useTranslate();
  const locale = useLocaleState();
  const refresh = useRefresh();
  const [assets, setAssets] = useState<Array<any>>([]);
  const [initialAssets, setInitialAssets] = useState<Array<any>>([]);
  const [selectedAsset, setSelectedAsset] = useState();
  const [map, setMap] = useState<Map | null>(null);
  const defaultCenterPosition = [52.1009166, 5.6462914];
  const [centerPosition, setCenterPosition] = useState<any[]>();
  const [searchValue, setSearchValue] = useState('');
  const [searchDebounceValue] = useDebounce(searchValue, LIST_DEBOUNCE);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const navigate = useNavigate();
  const roles = authProvider.getRoles();

  useOverlay(map);

  useEffect(() => {

    if (!roles.includes('map_viewer') && !roles.includes('admin')) {

      navigate('/');

    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchChange = (e: any) => {

    if (e.target.value) {

      setSearchValue(e.target.value);

    }

  };

  // eslint-disable-next-line consistent-return
  const oms = useMemo(() => {

    if (map) {

      return new OverlappingMarkerSpiderfier(map, {
        nearbyDistance: 50,
        legLengthMultiplier: 5,
      });

    }

  }, [map]);

  const subscribe = async (passetList: any[]) => {

    if (!socket) {

      setSocket(new WebSocket(XGAC_WS_API_URL));

    }
    setTimeout(() => {

      const assetIds = passetList.map((asset) => asset._id);

      const token = (xgacRequest.defaults.headers.common.Authorization || '').toString().substring(7);
      const assetChunks = _.chunk(assetIds, 100);

      for (const assetChunk of assetChunks) {

        socket?.send(JSON.stringify({
          event: 'subscribe',
          token,
          data: {
            entity: 'Asset',
            id: assetChunk,
            events: ['update'],
          },
        }));

      }

    }, 1000);

  };

  useEffect(() => {

    if (!socket) {

      return;

    }
    socket.onmessage = async (event) => {

      let data = event.data;
      try {

        data = JSON.parse(event.data);

      } catch (e) {

        console.log('Socket parse error', e);

      }

      if (data.entity === 'Asset') {

        const updatedAsset = data.document;

        const mappedAsset = {
          _id: updatedAsset._id,
          name: updatedAsset.name,
          properties: updatedAsset.properties,
          app: updatedAsset.app || null,
          position: updatedAsset.position || null,
          available: updatedAsset.available || false,
        };
        const originalAsset = _.find(assets, { _id: updatedAsset._id });

        if (originalAsset && originalAsset.position && updatedAsset.position) {

          const index = _.findIndex(assets, { _id: updatedAsset._id });
          assets.splice(index, 1, mappedAsset);
          await setAssets(assets);
          if (selectedAsset === updatedAsset._id) {

            map?.flyTo([updatedAsset.position.coordinates[1], updatedAsset.position.coordinates[0]]);

          }
          refresh();

        }

      }

    };

    socket.onclose = () => {

      setSocket(new WebSocket(XGAC_WS_API_URL));

    };

  }, [selectedAsset, assets, initialAssets, socket, map]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    const getInitialAssets = async () => {

      const assetList: Asset[] = [];

      const getAssets = async (page: number) => {

        let assetRequest;
        try {

          assetRequest = await xgacDataProvider.getList('all-assets', {
            pagination: { page, perPage: 100 },
            sort: { field: 'lastObservationAt', order: 'DESC' },
            filter: searchDebounceValue.length > 0 ? { q: searchDebounceValue } : {},
          });

        } catch (e) {

          await authProvider.logout({});
          navigate('/login');

        }

        if (assetRequest) {

          assetList.push(...assetRequest.data);

          if (assetRequest.total && assetRequest.total > assetList.length) {

            await getAssets(page + 1);

          }

        }

      };

      await getAssets(1);
      const mappedAssets = assetList.map((asset) => {

        return {
          _id: asset._id,
          name: asset.name,
          properties: asset.properties,
          app: asset.app || null,
          position: asset.position || null,
          available: asset.available || false,
        };

      });

      const orderedAssets = _.orderBy(mappedAssets, ['name'], ['asc']);
      await setAssets(orderedAssets);
      if (!initialAssets.length) {

        setInitialAssets(orderedAssets);

      }

    };
    getInitialAssets();

  }, [navigate, searchDebounceValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (initialAssets.length) {

      subscribe(initialAssets);

    }

  }, [initialAssets]); // eslint-disable-line react-hooks/exhaustive-deps

  const assetsOnMap = useMemo(() => {

    const markerArray: any[] = [];
    const allPositions: any[] = [];
    const currentDate = new Date().getTime();

    assets.forEach((asset) => {

      let inactive = true;

      if (asset.position?.properties?.dateTime) {

        inactive = (currentDate - new Date(asset.position?.properties?.dateTime).getTime() > 1000 * 60 * 60 * 3);

      }

      if (asset.position?.properties?.provider === 'static') {

        inactive = true;

      }

      if (asset.position?.coordinates) {

        allPositions.push([asset.position.coordinates[1], asset.position.coordinates[0]]);

        markerArray.push(<CustomMarker
          oms={oms}
          key={asset._id}
          position={{ latitude: asset.position.coordinates[1], longitude: asset.position.coordinates[0] }}
          variant={'alarmPosition'}
          label={asset.name}
          inactive={inactive}
          selected={selectedAsset === asset._id}
          onClick={() => {

            setSelectedAsset(asset._id);
            document.getElementById(`assetListItem-${asset._id}`)?.scrollIntoView({ behavior: 'smooth' });

          }}
        />);

        if (!centerPosition) {

          setCenterPosition([asset.position.coordinates[1], asset.position.coordinates[0]]);

        }

      }

    });

    if (!selectedAsset && assets.length > 0 && allPositions.length > 0) {

      map?.flyToBounds(allPositions);

    }
    return markerArray;

  }, [assets, selectedAsset, oms, centerPosition, map]);

  const handleSelection = (asset: any) => {

    setSelectedAsset(asset._id);
    map?.flyTo([asset.position.coordinates[1], asset.position.coordinates[0]]);

  };

  return <div className={'liveMap'}>
    <Grid container spacing={2}>
      <Grid xs={3} md={3}>
        <div className="selector-sidebar">
          <TextField onInput={(e) => {

            handleSearchChange(e);

          }} fullWidth placeholder={translate('ra.action.search')}/>
          <div className="selector-table">

            {assets.length === 0 && (
              <Alert style={{ margin: 10 }} severity="warning">{translate('pages.live_map.text.no_assets_in_customer')}</Alert>
            )}

            {assets?.map((asset) => {

              if (asset.position) {

                // do someting with the styling of this card when the asset is selected
                return (
                  <Card className={`assetCard ${(selectedAsset === asset._id) ? 'selected' : ''}`}
                    sx={{ margin: '5px' }} id={`assetListItem-${asset._id}`}>
                    <CardActionArea onClick={() => handleSelection(asset)}>
                      <CardContent>
                        <div className="flex-in-between" >
                          <div style={{ display: 'inline-flex', alignItems: 'center', width: '100%' }} >
                            <Avatar alt={asset.name} src={asset.properties?.image} sx={{ marginRight: '20px' }}/>
                            <strong>{asset.name}</strong>
                          </div>
                          <div style={{ margin: 'auto' }}>
                            <HasRoles anyOf={['admin']}>
                              <OpenInNewIcon onClick={() => window.open(`/app-users/${asset._id}`, '_blank')} sx={{ fontSize: '15px' }}/>
                            </HasRoles>
                          </div>
                        </div>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  {asset.position?.properties?.provider?.match(/gps.*/) !== null
                                    ? <PublicIcon/> : <HomeIcon/>
                                  }

                                </TableCell>
                                <TableCell>
                                  {translate('pages.live_map.text.position_is')}
                                  {/* eslint-disable-next-line no-nested-ternary */}
                                  {translate(asset.position?.properties?.provider === 'static' ? 'fixed'
                                    : asset.position?.properties?.provider?.match(/gps.*/) !== null
                                      ? 'pages.live_map.text.outdoors' : 'pages.live_map.text.indoors')}
                                  {translate('pages.live_map.text.at')}
                                  <br/>
                                  <a target="_blank"
                                    href={`https://www.google.com/maps/place/${asset.position?.coordinates[1]},${asset.position?.coordinates[0]}/@${asset.position?.coordinates[1]},${asset.position?.coordinates[0]},14z/`}
                                    rel="noreferrer">
                                    {asset.position?.properties?.address?.formattedAddress}
                                  </a>
                                  <br/>
                                  {translate('pages.live_map.text.accuracy', { accuracy: asset.position?.properties?.accuracy })}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <WatchLaterIcon/>
                                </TableCell>
                                <TableCell>
                                  {translate(
                                    'pages.live_map.text.last_position',
                                    {
                                      time: asset.position?.properties?.dateTime
                                        ? moment(new Date(asset.position?.properties?.dateTime)).locale(locale[0]).fromNow()
                                        : 'unknown',
                                    },
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <PersonIcon/>
                                </TableCell>
                                <TableCell>
                                  {translate(
                                    'pages.live_map.text.person_available',
                                    { available: asset.available ? '' : translate('pages.live_map.text.not') },
                                  )}
                                </TableCell>
                              </TableRow>
                              {asset.app && (
                                <TableRow>
                                  <TableCell>
                                    <PhoneIphoneIcon/>
                                  </TableCell>
                                  <TableCell>
                                    <div>
                                      {`${translate('pages.live_map.text.app_mode')} ${translate(`general.text.app_mode.${asset.app.mode}`)}`}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );

              }

              return null;

            })
            }
          </div>
        </div>
      </Grid>
      <Grid xs={9} md={9}>
        <MapContainer
          ref={(ref: any) => setMap(ref)}
          center={(assets.length > 0 ? centerPosition : defaultCenterPosition) as LatLngExpression}
          zoom={12}
          minZoom={4}
          zoomControl={false}
          style={{
            height: '100vh', position: 'relative', marginTop: '-33px', width: '100%',
          }}
        >
          <TileLayer
            maxZoom={19}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {assets.length > 0 && (
            <Pane name={'assetMarkers'}>
              {assetsOnMap}
            </Pane>
          )}
        </MapContainer>
      </Grid>
    </Grid>
  </div>;

};
