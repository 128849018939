import { xgacDataProvider } from '../dataProviders/xgacDataProvider';
import { massUpdateDeviceConfig } from '../dataProviders/appDataProvider';

export enum QrAction {
  replace = 'replace',
  append = 'append',
  skip = 'skip',
}

export const groupAndConfigSetter = async (
  selectedAssetIds: string[],
  groupAction: QrAction,
  configAction: QrAction,
  selectedGroupIds: string[],
  selectedConfigGroups: string[],
) => {

  const updateAssetGroups = async () => {

    const payload = {
      assetGroups: selectedGroupIds.map((group: string) => {

        return { _id: group, _ref: 'AssetGroup' };

      }) || [],
      assets: selectedAssetIds.map((assetId) => ({ _id: assetId, _ref: 'Asset' })),
      replace: groupAction === QrAction.replace,
    };
    await xgacDataProvider.updateAssetsInGroup(payload);

  };

  const updateConfigs = async () => {

    await massUpdateDeviceConfig(selectedAssetIds, selectedConfigGroups, configAction === QrAction.replace);

  };

  const promises = [];

  if (groupAction !== QrAction.skip) {

    promises.push(updateAssetGroups());

  }
  if (configAction !== QrAction.skip) {

    promises.push(updateConfigs());

  }

  if (promises.length === 0) {

    await Promise.all(promises);

  }

};
