import { SetStateAction } from 'react';
import { Card, CardContent } from '@mui/material';
import {
  Form, SaveButton, useNotify,
} from 'react-admin';
import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { User } from '@x-guard/xgac-types/xgac';
import { useTranslate } from 'ra-core';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { PhoneNumberInput } from '../../../components/inputs/PhoneNumberInput';

const Grid = Grid2;

export const PhoneInputCard = (props: { user: User; userSetter: SetStateAction<any> }) => {

  const notify = useNotify();
  const translate = useTranslate();
  const savePhoneNumber = async (data: any) => {

    if (props.user._id && data.properties.phoneNumber) {

      try {

        const updateResult = await xgacDataProvider.update('users', {
          id: props.user._id,
          data: {
            properties: { ...props.user.properties, phoneNumber: data.properties.phoneNumber, smsNumber: data.properties.phoneNumber },
          },
          previousData: {},
        });
        props.userSetter(updateResult.data);

      } catch (e) {

        console.log(e);
        notify('general.text.incorrect_phonenumber', { type: 'warning' });

      }

    }

  };

  return (
    <>
      <Grid xs={12} md={7}>
        <Card style={{ height: '100%' }} color={'secondary'}>
          <CardContent>
            <h4>{translate('homepage.text.continue_setting_up')}</h4>
            <p>{translate('homepage.text.continue_setting_up_subtext')}
            </p>
            <Form onSubmit={savePhoneNumber} >
              <PhoneNumberInput
                colorVariant="normal"
                variant={'outlined'}
                source="properties.phoneNumber"
                color={'primary'}
                style={{ width: '250px' }}
              />
              <SaveButton
                className={'inlineSticky'}
                label={'general.text.proceed'}
                icon={<></>}
                color={'secondary'}
                alwaysEnable={true} />
            </Form>
          </CardContent>
        </Card>
      </Grid>
    </>
  );

};
