import { NumberField, useRecordContext, TextField } from 'react-admin';
import { get } from 'lodash';

const NumberFieldNullable = (props: any) => {

  const record = useRecordContext();
  const value = get(record, props.source);

  if (!value || value === '') return <TextField source="value" record={{ value: 'N/A' }} className="gray"/>;
  return <NumberField {...props}/>;

};

export default NumberFieldNullable;
