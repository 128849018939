import { Device } from '@x-guard/xgac-types/xgac';
import queryString from 'query-string';
import { DEVICE_REGISTRATION_API_URL, XGAC_MAIN_API_URL } from '../config';
import { httpClient } from '../utils/httpClient';

const xgacMainApiUrl = XGAC_MAIN_API_URL;
const deviceRegistrationApiUrl = DEVICE_REGISTRATION_API_URL;

export const getDeviceInfo = async (assetId: string) => {

  // Create list of promises and fill with two requests
  const promises = [];
  promises.push(httpClient(`${deviceRegistrationApiUrl}/v3/deployment/device/groups`));
  promises.push(httpClient(`${xgacMainApiUrl}/devices?asset._id=${assetId}`));

  // Await the promises and assign the results to variables
  const promiseResults = await Promise.all(promises);
  const deviceGroups = promiseResults[0];
  const xgacDevices = promiseResults[1].json.result.sort((a: any, b: any) => {

    if (a.type === 'appDevice' && b.type !== 'appDevice') {

      return -1;

    }

    if (a.type !== 'appDevice' && b.type === 'appDevice') {

      return 1;

    }

    return 0;

  });

  const xgacDeviceData = xgacDevices[0];

  const deviceRegistrationDevice = await httpClient(`${deviceRegistrationApiUrl}/v3/deployment/device/${xgacDeviceData._id}`);

  return { groups: deviceGroups.json.groups, legacyDevice: deviceRegistrationDevice.json, xgacDevice: xgacDeviceData };

};

export const updateDeviceInfo = async (groups: Array<string>, deviceId:string) => {

  return httpClient(
    `${deviceRegistrationApiUrl}/v3/deployment/device/${deviceId}`,
    { method: 'PATCH' },
    { group: groups },
  );

};

export const getDeviceConfig = async (assetId: string) => {

  const xgacDevice = await httpClient(`${xgacMainApiUrl}/devices?asset._id=${assetId}`);

  xgacDevice.json.result.sort((a: any, b: any) => {

    if (a.type === 'appDevice' && b.type !== 'appDevice') {

      return -1;

    }

    if (a.type !== 'appDevice' && b.type === 'appDevice') {

      return 1;

    }

    return 0;

  });

  const xgacDeviceData = xgacDevice.json.result[0];

  // Create a list of promises containing the requests to the device registration api to get the configs
  const promises = [];

  promises.push(httpClient(
    `${deviceRegistrationApiUrl}/v3/deployment/device/config`,
    { method: 'POST' },
    { noSync: true, xgacDeviceId: 'default' },
  ));

  promises.push(httpClient(
    `${deviceRegistrationApiUrl}/v3/deployment/device/config`,
    { method: 'POST' },
    { noSync: true, xgacDeviceId: xgacDeviceData._id },
  ));

  // Resolve the promises and return the result
  const promiseResult = await Promise.all(promises);

  return { defaultConfig: promiseResult[0].json.config, deviceConfig: promiseResult[1].json };

};

export const massUpdateDeviceConfig = async (assetIds: string[], configGroups: string[], replace: boolean) => {

  const devices: Device[] = [];
  const getDevices = async (offset: number) => {

    if (assetIds.length === 0) {

      return;

    }
    const query = { 'asset._id': assetIds.join(','), $max: 100, $offset: offset };
    const deviceRequest = await httpClient(`${xgacMainApiUrl}/devices?${queryString.stringify(query)}`);
    const deviceData = deviceRequest.json.result;
    devices.push(...deviceData);
    if (deviceData.json && deviceData.length < deviceRequest.json.total) {

      await getDevices(offset + 100);

    }

  };
  await getDevices(0);

  const updateDeviceConfig = async (device: Device) => {

    if (!device.deviceId) {

      return;

    }

    const deviceConfigRequest = await httpClient(
      `${deviceRegistrationApiUrl}/v3/deployment/device/${device._id}`,
      { method: 'GET' },
    );

    const deviceConfig = deviceConfigRequest.json;

    await httpClient(
      `${deviceRegistrationApiUrl}/v3/deployment/device/${device._id}`,
      { method: 'PATCH' },
      { group: replace ? configGroups : [...deviceConfig.group || [], ...configGroups] },
    );

  };
  const configGroupsPromises: any[] = [];
  if (devices.length === 0) {

    return;

  }
  for (const currentDevice of devices) {

    configGroupsPromises.push(updateDeviceConfig(currentDevice));

  }
  await Promise.all(configGroupsPromises);

};

export const logOrConfig = async (assetId: string, type: string, criticalSwitchState: boolean) => {

  const asset = await httpClient(`${xgacMainApiUrl}/assets/${assetId}`);

  const body = { userIds: [asset.json.user._id], type, urgent: criticalSwitchState };
  return httpClient(
    `${deviceRegistrationApiUrl}/v3/deployment/device/notification`,
    { method: 'POST' },
    body,
  );

};

export const sendConfig = async (ids: string[]) => {

  const body = { userIds: ids, type: 'configuration', urgent: true };
  return httpClient(
    `${deviceRegistrationApiUrl}/v3/deployment/device/notification`,
    { method: 'POST' },
    body,
  );

};
export const removeGroupAndSendNotification = async (userIds: string[], devices: Array<any>) => {

  const getDevicePromises = [];
  const updatePromises = [];

  for (const device of devices) {

    getDevicePromises.push(httpClient(
      `${deviceRegistrationApiUrl}/v3/deployment/device/${device._id}`,
      { method: 'GET' },
    ));

  }
  const returnedDevices = await Promise.allSettled(getDevicePromises);

  for (const returnedDevice of returnedDevices) {

    if (returnedDevice.status === 'fulfilled' && returnedDevice.value.status < 400) {

      const groups = returnedDevice.value.json.group;
      const index = groups.indexOf('UseNewCloud', 0);
      if (index > -1) {

        groups.splice(index, 1);

      }
      updatePromises.push(httpClient(
        `${deviceRegistrationApiUrl}/v3/deployment/device/${returnedDevice.value.json.xgacDeviceId}`,
        { method: 'PATCH' },
        { group: groups },
      ));

    }

  }

  updatePromises.push(sendConfig(userIds));

  return Promise.allSettled(updatePromises);

};
