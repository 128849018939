import {
  ImageField,
  required,
  useExpanded,
  Identifier,
  useGetIdentity,
  InputProps,
  useRecordContext,
  Form,
  Button,
  useNotify,
  Create, FormDataConsumer,
} from 'react-admin';
import {
  Datagrid, Edit,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import { ListContextProvider, useTranslate } from 'ra-core';
import Grid2 from '@mui/material/Unstable_Grid2';
import {
  Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useState } from 'react';
import L, { LatLngBounds, LeafletEvent, Map } from 'leaflet';
import { Overlay, PointGeometry } from '@x-guard/xgac-types/xgac';
import _ from 'lodash';
import * as pdfjsLib from 'pdfjs-dist';
import * as turf from '@turf/turf';
import CircularProgress from '@mui/material/CircularProgress';
import { xgacDataProvider } from '../dataProviders/xgacDataProvider';
import { getCurrentCustomer } from '../lib/currentCustomer';
import { OverlayMap } from './maps/overlayMap';
import 'leaflet-toolbar';
import '@x-guard/xgac-leaflet-distortable-image';
import 'leaflet-distortableimage/dist/leaflet.distortableimage.css';
import 'leaflet-toolbar/dist/leaflet.toolbar.css';
import { EmptyHeader } from '../apps/bhvk/entities/button';
import { GoogleCoordinatesInput } from '../apps/bhvk/components/inputs/GoogleCoordinatesInput';
import { RaIntegerInput } from './inputs/raIntegerInput';
import { ImageInput } from './inputs/ImageInput';
import { wait } from '../lib/websockets/webSocketHelpers';

pdfjsLib.GlobalWorkerOptions.workerSrc = './assets/js/pdf.worker.js';
const Grid = Grid2;

const AnyL = L as any;

const OverlayImage = (props: InputProps) => {

  const record = useRecordContext();
  const image = _.get(record, props.source);
  return (
    <img src={image} style={{
      width: '100%',
      margin: '3px',
    }} alt="overlay"/>
  );

};
const OverlayMapSidebar = (props: {
  overlays: Overlay[];
  selectedOverlay: Identifier;
  OverlayEditForm: any;
  OverlayCreateForm: any;
  setIsCreating: (isCreating: boolean) => void;
  isCreating: boolean;
  setSelectedOverlay: (overlayId: Identifier) => void;
  isLoaded: boolean;
  map: Map | null;
}) => {

  const {
    overlays, selectedOverlay, setSelectedOverlay, isLoaded, OverlayEditForm, OverlayCreateForm, map,
  } = props;

  const translate = useTranslate();
  const [googleLocation, setGoogleLocation] = useState<PointGeometry | null>(null);

  useEffect(() => {

    if (googleLocation) {

      map?.flyTo([googleLocation.coordinates[1], googleLocation.coordinates[0]], 18, { duration: 1.5 });

    }

  }, [googleLocation]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="map-sidebar-div">
      <div className="map-sidebar-header">
        <Button
          color="primary"
          onClick={() => props.setIsCreating(true)}
          label="resources.overlays.text.create"
          sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
          variant="contained"
        />
        <GoogleCoordinatesInput
          value={googleLocation}
          setValue={setGoogleLocation}
          variant={'outlined'}
          sx={{
            backgroundColor: 'white',
            marginTop: '10px',
            color: 'black',
          }}
          label={translate('general.text.search_location')}
        />
      </div>
      <div className="map-sidebar-grid">
        <OverlayCreateForm/>
        <ListContextProvider value={{
          data: overlays,
          resource: 'overlays',
          selectedIds: [],
        } as any}>
          <Datagrid
            className={'map-sidebar-datagrid'}
            expandSingle={true}
            bulkActionButtons={false}
            empty={isLoaded ? <div></div> : (
              <div style={{
                height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
              }}>
                <CircularProgress/>
              </div>
            )}
            header={<EmptyHeader/>}
            expand={<OverlayEditForm/>}
            rowClick={'toggleSelection'}
            onToggleItem={(id) => setSelectedOverlay(id)}
            rowSx={(record: { _id: Identifier }) => ({
              display: record._id === selectedOverlay ? 'none' : undefined,
              borderBottom: '1px solid darkgray',
              padding: '0px 5px',
            })}
            cellPadding={0}
            cellSpacing={0}
          >
            <OverlayImage source="imageUrl"/>
          </Datagrid>
        </ListContextProvider>
      </div>
    </div>
  );

};
export const OverlayMapList = () => {

  const [expandedId, setExpandedId] = useState<Identifier>('');
  const [, toggleExpanded] = useExpanded('overlays', expandedId, true);
  const [map, setMap] = useState<Map | null>(null);
  const [overlays, setOverlays] = useState<Overlay[]>([]);
  const [lastSavedCustomer, setLastSavedCustomer] = useState('');
  const [saving, setSaving] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [hasNewMarker, setHasNewMarker] = useState<boolean>(false);
  const [chosenFloors, setChosenFloors] = useState<number[]>([]);
  const [filterInBounds, setFilterInBounds] = useState<boolean>(false);
  const [possibleFloors, setPossibleFloors] = useState<number[]>([]);
  const [mapBounds, setMapBounds] = useState<LatLngBounds | null>(null);

  const translate = useTranslate();
  const notify = useNotify();
  const userIdentity = useGetIdentity();
  const currentCustomer = getCurrentCustomer();

  useEffect(() => {

    map?.on('unload', () => {

      map.eachLayer((layer: any) => {

        if (layer.editing) {

          const layers = layer.editing.currentHandle?._layers ?? {};
          Object.values(layers).forEach((innerLayer: any) => innerLayer.remove());
          layer.editing.currentHandle = null;

        }
        layer.remove();

      });

    });

  }, [map]);

  useEffect(() => {

    toggleExpanded();
    if (expandedId !== '') {

      setIsCreating(false);

    }

  }, [expandedId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (isCreating) {

      setExpandedId('');

    }

  }, [isCreating]);

  useEffect(() => {

    if (!lastSavedCustomer || possibleFloors.length === 0) {

      return;

    }

    sessionStorage.setItem(`selectedFloors_${lastSavedCustomer}`, JSON.stringify(chosenFloors));

  }, [chosenFloors, lastSavedCustomer, possibleFloors]);

  useEffect(() => {

    const selectedFloors = sessionStorage.getItem(`selectedFloors_${lastSavedCustomer}`);
    if (selectedFloors) {

      setChosenFloors(JSON.parse(selectedFloors));

    }

  }, [lastSavedCustomer, possibleFloors]);

  useEffect(() => {

    if (possibleFloors.length > 0) {

      setChosenFloors(chosenFloors.filter((floor) => possibleFloors.includes(floor)));

    }

  }, [possibleFloors]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!map) {

      return;

    }
    map?.on('move', (event: LeafletEvent) => {

      setMapBounds(event.target.getBounds());

    });

  }, [map]);

  const setExpandedIdToEmpty = () => {

    setExpandedId('');

  };

  const getCurrentOverlayCorners = (overlayId: Identifier) => {

    let foundLayer: any;
    map?.eachLayer((layer: any) => {

      if (layer instanceof AnyL.DistortableImageOverlay && layer.options._id === overlayId) {

        foundLayer = layer;

      }

    });
    if (!foundLayer) {

      notify('resources.overlays.notifications.overlay_not_found', { type: 'warning' });
      setSaving(false);
      return null;

    }
    const overlayPoints = foundLayer.getCorners();
    return {
      nw: overlayPoints[0],
      ne: overlayPoints[1],
      sw: overlayPoints[2],
      se: overlayPoints[3],
    } || null;

  };
  const getOverlays = async () => {

    const overlayRequest = await xgacDataProvider.getList('overlays', {
      pagination: { page: 1, perPage: -1 },
      sort: { field: 'level', order: 'ASC' },
      filter: { imageUrl: '!null' },
    });
    return overlayRequest;

  };

  const getZonesAndSetBounds = async () => {

    const zonesRequest = await xgacDataProvider.getList('zones', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: { name: '!Opvolging wereld' },
      meta: {
        $select: 'location',
      },
    });

    const zones = zonesRequest.data;
    if (!map || !zones) {

      return;

    }
    const allBounds = zones.map((zone) => {

      return L.polygon(zone.location.coordinates[0].map((point: any) => [point[1], point[0] as any]));

    });
    map?.fitBounds(allBounds.map((bounds) => bounds?.getBounds()) as any);

  };

  const loadOverlays = async () => {

    const overlayRequest = await getOverlays();
    if (overlayRequest.data.length === 0) {

      getZonesAndSetBounds();

    }
    if (!_.isEqual(overlayRequest.data, overlays)) {

      setOverlays(overlayRequest.data);
      const uniqueFloors = _.uniq(overlayRequest.data.map((overlay) => overlay.level));
      setPossibleFloors(uniqueFloors.sort());

    }

  };
  const filteredOverlays = useMemo(() => {

    const filteredByFloor = chosenFloors.length > 0 ? overlays.filter((overlay) => chosenFloors.includes(overlay.level)) : overlays;

    if (!filterInBounds || !mapBounds) {

      return filteredByFloor;

    }

    return filteredByFloor.filter((overlay) => {

      const overlayBounds = L.polygon([
        [overlay.points.nw.lat, overlay.points.nw.lng],
        [overlay.points.ne.lat, overlay.points.ne.lng],
        [overlay.points.se.lat, overlay.points.se.lng],
        [overlay.points.sw.lat, overlay.points.sw.lng],
      ]).toGeoJSON();

      const mapBoundsGeoJson = turf.bboxPolygon([mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()]);
      return turf.booleanContains(mapBoundsGeoJson, overlayBounds)
        || turf.booleanOverlap(mapBoundsGeoJson, overlayBounds)
        || turf.booleanWithin(mapBoundsGeoJson, overlayBounds);

    });

  }, [overlays, chosenFloors, mapBounds, filterInBounds]);

  const finishCreate = () => {

    setHasNewMarker(false);
    setIsCreating(false);
    setSaving(false);

  };

  const OverlayCreate = () => {

    const saveButtonPressed = async (data: any) => {

      const needsToRefresh = data.needsToRefresh || true;
      delete data.needsToRefresh;
      if (!hasNewMarker) {

        notify('resources.overlays.notifications.invalid_overlay', { type: 'warning' });
        setSaving(false);
        return;

      }
      setSaving(true);
      const fileType = data.imageUrl?.rawFile?.type;

      if (fileType === 'application/pdf') {

        const pdf = await data.imageUrl.rawFile.arrayBuffer();
        const pdfImages = await pdfjsLib.getDocument(pdf).promise;
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const pdfImagesPromises = Array.from({ length: pdfImages.numPages }, async (_, index) => {

          const page = await pdfImages.getPage(index + 1);
          const scale = 1.5;
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          if (!context) {

            return '';

          }
          await page.render({ canvasContext: context, viewport }).promise;
          const imageBlob = await new Promise((resolve) => {

            canvas.toBlob(resolve, 'image/png');

          });
          return {
            src: canvas.toDataURL('image/png'),
            rawFile: new File([imageBlob as Blob], `page_${index}.png`, { type: 'image/png' }),
          };

        });
        const images = await Promise.all(pdfImagesPromises);
        const newData = images.map((image, index) => {

          return {
            ...data, level: ((data.level || 0) + index), imageUrl: image, needsToRefresh: false,
          };

        });
        await Promise.all(newData.map(async (newDataLevel) => {

          await saveButtonPressed(newDataLevel);

        }));
        finishCreate();
        await loadOverlays();
        return;

      }
      const image = new Image();
      image.src = data.imageUrl.src;
      map?.invalidateSize();
      const bounds = map?.getBounds();
      let marker: L.Marker | null = null;
      map?.eachLayer((layer: any) => {

        if (layer instanceof AnyL.Marker) {

          marker = layer;

        }

      });

      if (!bounds || !marker) {

        finishCreate();
        return;

      }

      image.onload = async () => {

        if (!map) {

          finishCreate();
          return;

        }
        let points: any = {};
        const layersOnMarker: any[] = [];
        map.eachLayer((layer: any) => {

          if (layer instanceof AnyL.DistortableImageOverlay) {

            if (layer.getBounds().contains(marker?.getLatLng())) {

              layersOnMarker.push(layer);

            }

          }

        });

        const layersWithSameRatio = layersOnMarker.filter(async (layer) => {

          const currentOverlay = overlays.find((overlay) => overlay._id === layer.options._id);
          if (!currentOverlay || !currentOverlay.imageUrl) {

            return false;

          }
          const currentImage = new Image();
          currentImage.src = currentOverlay?.imageUrl;
          await new Promise((resolve) => {

            currentImage.onload = resolve;

          });

          return currentImage.width / currentImage.height === image.width / image.height;

        }).sort((a, b) => {

          const aOverlay = overlays.find((overlay) => overlay._id === a.options._id);
          const bOverlay = overlays.find((overlay) => overlay._id === b.options._id);
          if (!aOverlay || !bOverlay) {

            return 0;

          }

          return (bOverlay?.level || 0) - (aOverlay?.level || 0);

        });
        if (layersWithSameRatio.length > 0) {

          const lastOverlay = overlays.find((overlay) => overlay._id === layersWithSameRatio[0].options._id);
          if (lastOverlay) {

            points = lastOverlay.points;
            data.level = lastOverlay.level + 1;

          }

        } else {

          const mapHeight = Math.ceil(map.getSize().y / 2);
          const originalImageWidth = image.width;
          const originalImageHeight = image.height;
          const aspectRatio = originalImageWidth / originalImageHeight;
          const imageHeight = mapHeight;
          const imageWidth = aspectRatio * imageHeight;

          const imageCenter = new L.LatLng(marker?.getLatLng().lat as number, marker?.getLatLng().lng as number);
          const center = map?.project(imageCenter);
          if (!center) {

            finishCreate();
            return;

          }
          const offset = L.point(imageWidth, imageHeight).divideBy(2);
          points = {
            nw: map?.unproject(center.subtract(offset)),
            ne: map?.unproject(center.add(L.point(offset.x, -offset.y))),
            sw: map?.unproject(center.add(L.point(-offset.x, offset.y))),
            se: map?.unproject(center.add(offset)),
          };

        }

        // make a polygon out of the to be added overlay
        const polygon = new L.Polygon([
          [points.nw.lat, points.nw.lng],
          [points.ne.lat, points.ne.lng],
          [points.se.lat, points.se.lng],
          [points.sw.lat, points.sw.lng],
        ]);

        // check if the polygon is too small. If the polygon is too small, zoom out the map so it is exactly 44 square meters
        const polygonSize = turf.area(polygon.toGeoJSON().geometry);
        if (polygonSize < 45) {

          const sizeDiffPercentage = 45 / polygonSize;
          const currentZoom = map?.getZoom();
          const newZoom = Math.floor(currentZoom * (1 - sizeDiffPercentage / 100));
          map?.setZoom(newZoom);
          await saveButtonPressed(data);
          return;

        }

        const updateBody = {
          ...data,
          points,
        };

        const newOverlay = await xgacDataProvider.create('overlays', { data: updateBody });
        if (needsToRefresh) {

          finishCreate();
          await loadOverlays();

          if (newOverlay) {

            setExpandedId(newOverlay.data._id);

          }

        }

      };

    };

    if (!isCreating) {

      return null;

    }
    return (
      <>
        <Card sx={{
          borderRadius: '5px', boxShadow: 1, marginBottom: '10px', marginTop: '10px',
        }}>
          <CardContent sx={{ p: '10px' }}>
            {hasNewMarker
              ? (
                <Create
                  resource="overlays"
                  actions={false}
                  title={' '}
                  disableAuthentication
                  redirect={false}
                  sx={{
                    '& .RaEdit-noActions': {
                      marginTop: 0,
                    },
                  }}
                >
                  <Form onSubmit={saveButtonPressed}>
                    <ImageInput source="imageUrl" label="resources.overlays.fields.image" sx={{ width: '50%' }}
                      validate={required()}
                      shouldUnregister
                      fullWidth
                    >
                      <FormDataConsumer>
                        {({ formData }) => {

                          const image = _.get(formData, 'imageUrl.src');
                          const imageType = _.get(formData, 'imageUrl.rawFile.type');
                          if (image && imageType === 'application/pdf') {

                            return (
                              <embed src={image}/>
                            );

                          }
                          return (
                            <ImageField source="src" title="title" fullWidth/>
                          );

                        }}
                      </FormDataConsumer>
                    </ImageInput>
                    <RaIntegerInput source="level" label="general.text.map_floors"/>
                    <div style={{ width: '100%' }}>
                      <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                        <Grid md={6}>
                          <Button color="error"
                            onClick={() => {

                              setHasNewMarker(false);
                              setIsCreating(false);

                            }}
                            label="ra.action.cancel"
                            sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
                            variant="contained"/>
                        </Grid>
                        <Grid md={6}>
                          <Button color="primary"
                            label="ra.action.save"
                            type="submit"
                            disabled={!hasNewMarker || saving}
                            sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
                            variant="contained"
                            onClick={(e) => {

                              e.stopPropagation();

                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Form>
                </Create>
              ) : (
                <div className="overlay-create-no-marker">
                  <span>{translate('resources.overlays.text.add_marker')}</span>
                </div>
              )}
          </CardContent>
        </Card>
      </>
    );

  };

  const OverlayEdit = () => {

    const [deleteOpen, setDeleteOpen] = useState(false);
    const record: (Overlay & { id: Identifier }) | undefined = useRecordContext();

    const handleDelete = async () => {

      setExpandedIdToEmpty();
      await wait(1);
      await xgacDataProvider.delete('overlays', { id: record?._id });
      setDeleteOpen(false);
      await loadOverlays();

    };
    const saveButtonPressed = async (data: any) => {

      setSaving(true);
      if (!record) {

        setSaving(false);
        return;

      }

      const points = getCurrentOverlayCorners(record?._id);
      if (!points) {

        setSaving(false);
        return;

      }
      const polygon = new L.Polygon([
        [points.nw.lat, points.nw.lng],
        [points.ne.lat, points.ne.lng],
        [points.se.lat, points.se.lng],
        [points.sw.lat, points.sw.lng],
      ]);
      const polygonSize = turf.area(polygon.toGeoJSON().geometry);
      if (polygonSize < 45) {

        notify('resources.overlays.notifications.overlay_too_small', { type: 'warning' });
        setSaving(false);
        return;

      }
      const updateBody = {
        ...data,
        points,
      };

      await xgacDataProvider.update('overlays', { id: record?._id, data: updateBody, previousData: record });
      await loadOverlays();
      setExpandedIdToEmpty();
      setSaving(false);

    };
    return (
      <>
        <Card sx={{ borderRadius: '5px', boxShadow: 1 }}>
          <CardContent sx={{ p: '10px' }}>
            <Edit
              id={record?._id}
              resource="overlays"
              actions={false}
              title={' '}
              disableAuthentication
              redirect={false}
              mutationMode="pessimistic"
              sx={{
                '& .RaEdit-noActions': {
                  marginTop: 0,
                },
              }}
            >
              <Form onSubmit={saveButtonPressed}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}>
                  <IconButton
                    onClick={() => setDeleteOpen(true)}
                    size="small"
                  >
                    <DeleteIcon/>
                  </IconButton>
                </div>
                <ImageInput source="imageUrl" label="resources.overlays.fields.image" sx={{ width: '50%' }}
                  validate={required()}
                  shouldUnregister
                  fullWidth
                >
                  <ImageField source="src" title="title" fullWidth/>
                </ImageInput>
                <RaIntegerInput source="level" label="general.text.map_floors"/>
                <div style={{ width: '100%' }}>
                  <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                    <Grid md={6}>
                      <Button color="error"
                        onClick={setExpandedIdToEmpty}
                        label="ra.action.cancel"
                        sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
                        variant="contained"/>
                    </Grid>
                    <Grid md={6}>
                      <Button color="primary"
                        label="ra.action.save"
                        type="submit"
                        disabled={saving}
                        sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
                        variant="contained"
                        onClick={(e) => {

                          e.stopPropagation();

                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Form>
            </Edit>
          </CardContent>
        </Card>
        <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
          <DialogTitle>{translate('resources.overlays.text.delete_dialog_title')}</DialogTitle>
          <DialogContent>
            <span>{translate('resources.overlays.text.delete_dialog_content')}</span>
          </DialogContent>
          <DialogActions>
            <Button
              label="ra.action.cancel"
              onClick={() => setDeleteOpen(false)}
            />
            <Button
              label="ra.action.delete"
              onClick={() => {

                handleDelete();

              }}
            />
          </DialogActions>
        </Dialog>
      </>
    );

  };

  const handleClickOnOverlay = (identifier: Identifier) => {

    setExpandedId(identifier);

  };

  useEffect(() => {

    map?.eachLayer(async (layer: any) => {

      if (layer instanceof AnyL.DistortableImageOverlay) {

        if (layer.options._id === expandedId) {

          layer.editing.enable();
          // set the zindex of the layer to the highest zindex
          layer.setZIndex(999999);

          if (!layer.isSelected()) {

            layer.select();

          }
          const bounds = map?.getBounds();
          const layerBounds = layer.getBounds();

          if (bounds && layerBounds && !bounds.contains(layerBounds)) {

            map?.flyToBounds(layer.getBounds(), { padding: [20, 20], duration: 1.5 });

          }

        } else if (layer.editing.enabled()) {

          layer.editing.disable();
          layer.deselect();
          layer.setZIndex(0);
          const foundRecord = overlays.find((overlay) => overlay._id === layer.options._id);
          if (foundRecord) {

            layer.setCorners([
              L.latLng(foundRecord.points.nw.lat, foundRecord.points.nw.lng),
              L.latLng(foundRecord.points.ne.lat, foundRecord.points.ne.lng),
              L.latLng(foundRecord.points.sw.lat, foundRecord.points.sw.lng),
              L.latLng(foundRecord.points.se.lat, foundRecord.points.se.lng),
            ]);

          }

        }

      }

    });

  }, [expandedId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (currentCustomer && currentCustomer.value !== lastSavedCustomer) {

      loadOverlays();
      setLastSavedCustomer(currentCustomer.value);

    }

  }, [currentCustomer, userIdentity]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Grid container sx={{ height: '100%', marginLeft: '-16px' }}>
        <Grid xs={9}>
          <OverlayMap
            overlays={filteredOverlays}
            handleClickOnOverlay={handleClickOnOverlay}
            map={map}
            setMap={setMap}
            selectedOverlay={expandedId}
            isCreating={isCreating}
            newMarkerPlaced={hasNewMarker}
            setNewMarkerPlaced={setHasNewMarker}
            possibleFloors={possibleFloors}
            chosenFloors={chosenFloors}
            setChosenFloors={setChosenFloors}
            filterInBounds={filterInBounds}
            setFilterInBounds={setFilterInBounds}
          />
        </Grid>
        <Grid xs={3}>
          <OverlayMapSidebar
            overlays={filteredOverlays}
            OverlayEditForm={OverlayEdit}
            selectedOverlay={expandedId}
            setSelectedOverlay={setExpandedId}
            isLoaded={true}
            OverlayCreateForm={OverlayCreate}
            setIsCreating={setIsCreating}
            isCreating={isCreating}
            map={map}
          />
        </Grid>
      </Grid>
    </>
  );

};
