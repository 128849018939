export const roleChoices = [
  { id: 'app_user', name: 'resources.users.text.roles.app_user' },
  { id: 'map_viewer', name: 'resources.users.text.roles.map_viewer' },
  { id: 'centralist', name: 'resources.users.text.roles.centralist' },
  { id: 'customer_admin', name: 'resources.users.text.roles.customer_admin' },
  { id: 'viewer', name: 'resources.users.text.roles.viewer' },
  { id: 'ats_responder', name: 'resources.users.text.roles.ats_responder' },
  { id: 'alarm_responder', name: 'resources.users.text.roles.alarm_responder' },
  { id: 'customer_admin_external_id', name: 'resources.users.text.roles.customer_admin_external_id' },
  { id: 'flic_hub_tester', name: 'resources.users.text.roles.flic_hub_tester' },
  { id: 'kioskUser', name: 'resources.users.text.roles.kioskUser' },
  { id: 'fingerprinter', name: 'resources.users.text.roles.fingerprinter' },
  { id: 'bhvk_trial', name: 'resources.users.text.roles.bhvk_trial' },
  { id: 'bhvk_admin', name: 'resources.users.text.roles.bhvk_admin' },
  { id: 'customer_admin_special_username', name: 'resources.users.text.roles.customer_admin_special_username' },
  { id: 'customer_admin_app_user_history', name: 'resources.users.text.roles.customer_admin_app_user_history' },
];

export const customerAdminRoleChoices = [
  { id: 'map_viewer', name: 'resources.users.text.roles.map_viewer' },
  { id: 'customer_admin', name: 'resources.users.text.roles.customer_admin' },
];
