import {
  Labeled, minValue, NumberInput, required, SelectInput, useTranslate,
} from 'react-admin';
import * as React from 'react';
import { timeGranularityType } from '../../lib/constants/selectChoices';

export const TimeGranularityInput = (props: any) => {

  const translate = useTranslate();

  if (!props || !props.source) {

    return null;

  }
  const validation = props.validation || [required()];
  return (
    <Labeled label={props.label || translate('resources.report-triggers.fields.config.timeSeries.granularity')}>
      <>
        <div>
          <NumberInput source={`${props.source}.value`} validate={[minValue(0), ...validation]}
            label="resources.report-triggers.fields.time_granularity.value"
            defaultValue={props.defaultNumberValue || 1}
            sx={{
              marginTop: '8px',
            }}
            disabled={props.disabled || false}
            size={'small'}
            shouldUnregister />
          <SelectInput source={`${props.source}.type`} choices={timeGranularityType} validate={validation}
            defaultValue={props.defaultValue || 'hour'}
            label="resources.report-triggers.fields.time_granularity.type"
            disabled={props.disabled || false}
            size={'small'}
            shouldUnregister
          />
        </div>
      </>
    </Labeled>
  );

};
