import { Overlay } from '@x-guard/xgac-types/xgac';
import { useEffect, useState } from 'react';
import { useGetIdentity } from 'react-admin';
import L, { Map } from 'leaflet';
import { getCurrentCustomer } from '../lib/currentCustomer';
import '@x-guard/xgac-leaflet-distortable-image';
import { xgacDataProvider } from '../dataProviders/xgacDataProvider';

const AnyL = L as any;
export const useOverlay = (map: Map | null, selectedFloors?: number[]) => {

  const currentCustomer = getCurrentCustomer();
  const [overlays, setOverlays] = useState<Overlay[]>([]);
  const [lastCustomer, setLastCustomer] = useState<string>(currentCustomer?.value || '');
  const identity = useGetIdentity();

  useEffect(() => {

    map?.on('unload', () => {

      map.eachLayer((layer: any) => {

        if (layer.editing) {

          const layers = layer.editing.currentHandle?._layers ?? {};
          Object.values(layers).forEach((innerLayer: any) => innerLayer.remove());
          layer.editing.currentHandle = null;

        }
        layer.remove();

      });

    });

  }, [map]);

  useEffect(() => {

    if (currentCustomer && currentCustomer.value !== lastCustomer) {

      setLastCustomer(currentCustomer.value);

    }

  }, [currentCustomer, identity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    const getOverlays = async () => {

      const overlayRequest = await xgacDataProvider.getList('overlays', {
        pagination: { page: 1, perPage: -1 },
        sort: { field: 'name', order: 'ASC' },
        filter: {},
      });

      setOverlays(overlayRequest.data || []);

    };
    getOverlays();

  }, [lastCustomer]);

  useEffect(() => {

    if (!map) {

      return;

    }
    map.eachLayer((layer: any) => {

      if (layer instanceof AnyL.DistortableImageOverlay) {

        if (layer.editing) {

          const layers = layer.editing.currentHandle?._layers ?? {};
          Object.values(layers).forEach((innerLayer: any) => innerLayer.remove());
          layer.editing.currentHandle = null;

        }
        layer.remove();

      }

    });
    overlays.forEach((overlay) => {

      if (selectedFloors && selectedFloors.length > 0 && !selectedFloors.includes(overlay.level)) {

        return;

      }
      const imageLayer = AnyL.distortableImageOverlay(overlay.imageUrl, {
        corners: [
          L.latLng(overlay.points.nw.lat, overlay.points.nw.lng),
          L.latLng(overlay.points.ne.lat, overlay.points.ne.lng),
          L.latLng(overlay.points.sw.lat, overlay.points.sw.lng),
          L.latLng(overlay.points.se.lat, overlay.points.se.lng),
        ],
        editable: false,
        selected: false,
        actions: [],
        className: 'leaflet-overlay-opacity',
      });
      imageLayer.toGeoJSON = () => {

        return {
          id: overlay._id,
        };

      };
      if (imageLayer) {

        try {

          imageLayer.addTo(map);

        } catch (e) {

          console.log('Error adding overlay to map', e);

        }

      }

    });

  }, [overlays, map, selectedFloors]);

  return overlays;

};
