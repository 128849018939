import { InputProps, useInput, useTranslate } from 'react-admin';
import { useEffect, useState } from 'react';
import { Checkbox, FormHelperText, InputLabel } from '@mui/material';
import * as React from 'react';

export const BooleanCheckBoxInput = (props: InputProps & { disabled?: boolean; disabledText?: string; inputLabel?: string | false; helperLabel?: string }) => {

  const { field } = useInput({ source: props.source });
  const [checked, setChecked] = useState(field.value);
  const translate = useTranslate();

  const handleChange = (event: any) => {

    setChecked(event.target.checked);
    field.onChange(event.target.checked);

  };

  useEffect(() => {

    if (field.value !== checked) {

      setChecked(field.value);

    }

  }, [field.value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ width: '100%' }}>
      {props.inputLabel !== false && (
        <InputLabel className="input-label">
          {translate(props.inputLabel || 'resources.users.text.receives_alarms')}
        </InputLabel>
      )}
      <Checkbox checked={props.disabled ? false : checked} onChange={handleChange} sx={{ paddingLeft: 0 }} disabled={props.disabled}/>
      {props.disabled && (
        <span id="phoneNumber-span" >{props.disabledText}</span>
      )}
      {!props.disabled && !props.helperLabel && (
        <span>{translate(checked ? 'general.text.yes' : 'general.text.no')}</span>
      )}
      {props.helperLabel && (
        <span style={{
          verticalAlign: 'middle',
        }}>{translate(props.helperLabel)}</span>
      )}
      {props.helperText && (
        <FormHelperText>{typeof props.helperText === 'string' ? translate(props.helperText) : props.helperText}</FormHelperText>
      )}
    </div>
  );

};
