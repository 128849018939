import {
  ArrayField,
  ArrayInput,
  ChipField,
  Datagrid,
  EditButton,
  List,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  TextField,
  TextInput,
  FormDataConsumer,
  useInput,
  useRecordContext,
  SelectArrayInput,
} from 'react-admin';
import { IfCanAccess } from '@react-admin/ra-rbac';
import * as React from 'react';
import { RoleSubject } from '@x-guard/xgac-types/xgac';
import {
  ChangeEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import {
  Autocomplete as MuiAutocomplete, FormControlLabel, Switch, TextField as MuiTextField,
} from '@mui/material';
import _ from 'lodash';
import { httpClient } from '../../../utils/httpClient';
import { XGAC_MAIN_API_URL } from '../../../config';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { CustomToolbar } from '../../../components/CustomToolBar';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

const apiUrl = XGAC_MAIN_API_URL;
const subjects = Object.keys(RoleSubject).sort().map((key) => ({ id: key, name: key }));

const actionOptions = [
  { id: 'create', name: 'create' },
  { id: 'read', name: 'read' },
  { id: 'update', name: 'update' },
  { id: 'delete', name: 'delete' },
  { id: 'manage', name: 'manage' },
  { id: 'escalate', name: 'escalate' },
];

const useGetRoleLimits = () => {

  const [limits, setLimits] = useState<any | null>({ });
  useEffect(() => {

    httpClient(`${apiUrl}/roles/permission-limits`)
      .then((res) => {

        if (res.json) {

          setLimits(res.json);

        }

      });

  }, []);
  return limits;

};

const LimitInput = (props: { limits: any; subject?: string }) => {

  const [limits, setLimits] = useState<any | null>(null);
  const [limitFields, setLimitFields] = useState<{ id: string; label: string }[]>([]);
  const { field } = useInput({ source: 'limit' || '' });
  const record = useRecordContext();
  const initialValue = field.value || record?.limit;

  useEffect(() => {

    if (initialValue) {

      setLimits(initialValue);
      const fields = _.get(initialValue, 'fields');
      if (fields) {

        const fieldOptions: { id: string; label: string }[] = fields.map((fieldString: string) => ({
          id: fieldString,
          label: fieldString,
        }));
        setLimitFields(fieldOptions);

      }

    }

  }, [initialValue]);

  if (!props.subject) return null;
  const availableLimits = props.limits[props.subject];
  const modeOptions = availableLimits?.mode?.map((mode: string) => ({ id: mode, name: mode })) || [];
  const booleanLimits = _.omit(availableLimits, ['fields', 'mode']);
  if (!availableLimits) return null;
  const fieldOptions: {
    id: string;
    label: string;
  }[] = availableLimits?.fields?.map((fieldString: string) => ({ id: fieldString, label: fieldString })) || [];

  const handleFieldsChange = (event: SyntheticEvent, value: { label: string; id: string }[]) => {

    setLimitFields(value);
    const currentValue = { ...limits };
    currentValue.fields = value.map((item: { label: string; id: string }) => item.id);
    field.onChange(currentValue);

  };

  const handleBooleanLimitChange = (event: ChangeEvent<HTMLInputElement>, value: boolean) => {

    const currentValue = { ...limits };
    if (value) {

      currentValue[event.target.id] = true;

    } else {

      delete currentValue[event.target.id];

    }
    field.onChange(currentValue);
    setLimits(currentValue);

  };

  return (
    <>
      {modeOptions.length > 0 && (
        <>
          <SelectInput source={'limit.mode'} choices={modeOptions} emptyValue={0} label="Mode" parse={(v) => {

            if (v === 0) return undefined;
            return v;

          }}/>
        </>
      )}
      {fieldOptions.length > 0 && (
        <>
          <MuiAutocomplete
            renderInput={(params) => <MuiTextField {...params}
              label={'Fields'}/>}
            options={fieldOptions}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            multiple
            defaultValue={[]}
            fullWidth
            value={limitFields || []}
            onChange={handleFieldsChange}
            sx={{
              marginBottom: '10px',
            }}/>
        </>
      )}

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        width: '100%',
      }}
      >
        {Object.keys(booleanLimits).map((booleanLimit: string) => (
          <div style={{}}>
            <FormControlLabel
              control={<Switch checked={field.value[booleanLimit] === true} onChange={handleBooleanLimitChange}
                id={booleanLimit}/>}
              label={booleanLimit}
            />
          </div>
        ))}
      </div>
    </>
  );

};
export const RoleList = () => (
  <List>
    <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
      <TextField source="name" />
      <ArrayField source="permissions">
        <SingleFieldList linkType={false}>
          <ChipField source="subject" />
        </SingleFieldList>
      </ArrayField>
      <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
      <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
      <IfCanAccess action="edit">
        <EditButton/>
      </IfCanAccess>
      <HasRoles anyOf={['developer_admin']}>
        <AuditlogButton/>
      </HasRoles>
    </Datagrid>
  </List>
);
export const RoleEdit = () => {

  const limits = useGetRoleLimits();

  return (
    <EditWithSuccess title="resources.roles.title" actions={false}>
      <SimpleForm toolbar={<CustomToolbar/>} >
        <TextInput source="name" required/>
        <ArrayInput source="permissions" >
          <SimpleFormIterator disableReordering disableClear>
            <FormDataConsumer>
              {({ scopedFormData }) => {

                return (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: '100%',
                    marginTop: '10px',
                  }}>
                    <SelectInput source={'subject'} choices={subjects || []} defaultValue={[]} required/>
                    <SelectArrayInput
                      source={'actions'}
                      choices={actionOptions}
                      defaultValue={[]}
                      label="general.text.actions"
                      required
                    />
                    {scopedFormData?.subject && (
                      <LimitInput limits={limits} subject={scopedFormData.subject}/>
                    )}
                  </div>
                );

              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </EditWithSuccess>
  );

};

export const RoleCreate = () => {

  const limits = useGetRoleLimits();

  return (
    <CreateWithSuccess title="resources.roles.title">
      <SimpleForm>
        <TextInput source="name" required/>
        <ArrayInput source="permissions" >
          <SimpleFormIterator disableReordering disableClear>
            <FormDataConsumer>
              {({ scopedFormData }) => {

                return (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: '100%',
                    marginTop: '10px',
                  }}>
                    <SelectInput source={'subject'} choices={subjects || []} defaultValue={[]} required/>
                    <SelectArrayInput
                      source={'actions'}
                      choices={actionOptions}
                      defaultValue={[]}
                      label="general.text.actions"
                      required
                    />
                    <LimitInput limits={limits} subject={scopedFormData?.subject} />
                  </div>
                );

              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </CreateWithSuccess>
  );

};
