import {
  useNotify, useRedirect, useRefresh, useResourceContext, useTranslate,
} from 'react-admin';
import { useListContext } from 'ra-core';
import { useEffect, useState } from 'react';
import { wait } from '../../../lib/websockets/webSocketHelpers';

export const RedirectToCreateAlarm = () => {

  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext();
  const translate = useTranslate();
  const { isLoading, data } = useListContext();
  const [tryCount, setTryCount] = useState<number>(0);

  useEffect(() => {

    const translatedResource = translate('resources.alarms.name', { smart_count: 2, _: resource });
    const waitAndRefresh = async () => {

      await wait(200);
      refresh();
      setTryCount(tryCount + 1);

    };

    if (!isLoading && data && data.length === 0) {

      if (tryCount > 2) {

        notify('general.text.none_yet', { type: 'warning', messageArgs: { smart_count: 1, name: translatedResource.toLowerCase() } });
        redirect('/maak-alarm');

      }
      waitAndRefresh();

    }

  }, [isLoading, data, tryCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;

};
