import {
  Datagrid, DateField, ListContextProvider, TextField, useRecordContext,
} from 'react-admin';
import HistoryIcon from '@mui/icons-material/History';
import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Dialog, DialogContent, DialogTitle, IconButton, TextField as MuiTextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslate } from 'ra-core';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ReportAuditLog } from '../../../../lib/constants/customTypes';
import { AuditOperations, reportDataProvider } from '../../../../dataProviders/reportDataProvider';
import authProvider from '../../../../utils/authProvider';
import { AuditLogPagination } from '../pagination/AuditLogPagination';
import { AuthorField } from '../fields/AuditAuthorField';

export const AuditlogButton = () => {

  const record = useRecordContext();
  const isAdmin = authProvider.isAdmin();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [auditData, setAuditData] = useState<ReportAuditLog[]>([]);
  const [actionFilter, setActionFilter] = useState<AuditOperations[]>([
    AuditOperations.create,
    AuditOperations.read,
    AuditOperations.update,
  ]);
  const [beforeOrAfterDate, setBeforeOrAfterDate] = useState<{
    date: Date;
    direction: 'before' | 'after';
  } >({
    date: new Date(),
    direction: 'before',
  });

  useEffect(() => {

    if (!record) return;

    if (!open) {

      setAuditData([]);
      setBeforeOrAfterDate({ date: new Date(), direction: 'before' });
      return;

    }
    setLoading(true);
    reportDataProvider.getAuditReport(
      beforeOrAfterDate.date,
      beforeOrAfterDate.direction,
      50,
      [],
      actionFilter,
      isAdmin,
      [record._id as string],
    ).then((data: ReportAuditLog[]) => {

      if (data) {

        setAuditData(data);

      }
      setLoading(false);

    }).catch(() => {

      setLoading(false);

    });

  }, [beforeOrAfterDate, record, open, actionFilter, isAdmin]);

  return (
    <>
      <IconButton
        disabled={!record}
        onClick={(event) => {

          event.stopPropagation();
          event.preventDefault();
          setOpen(true);

        }}
      ><HistoryIcon /></IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle className="flex-in-between">
          <span>{translate('general.text.history')}</span>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            {loading && (
              <CircularProgress size={20} style={{
                color: 'white',
              }}/>
            )}
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon/>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent onClick={(e) => {

          e.stopPropagation();
          e.preventDefault();

        }}>
          <Autocomplete
            options={Object.values(AuditOperations).filter((operation) => operation !== AuditOperations.delete)}
            value={actionFilter}
            onChange={(e, value) => setActionFilter(value as AuditOperations[])}
            multiple
            sx={{
              marginBottom: '10px',
              marginTop: '10px',
            }}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                label={translate('resources.auditlogs.text.operation')}/>
            )}
          />
          {(loading && auditData.length === 0) ? (
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <CircularProgress/>
            </div>
          ) : (
            <>
              <ListContextProvider
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
                value={{
                  data: auditData,
                }}>
                <Datagrid isLoading={loading} resource="auditLogs">
                  <DateField source='timestamp' label="general.text.timestamp" showTime={true} />
                  <AuthorField label={'resources.auditlogs.text.user'}/>
                  <TextField source={'operation'} label={'resources.auditlogs.text.operation'}/>
                </Datagrid>
                <AuditLogPagination
                  disabled={loading}
                  onFirstPage={() => setBeforeOrAfterDate({ date: new Date(), direction: 'before' })}
                  onPreviousPage={() => setBeforeOrAfterDate({ date: new Date(auditData[0].timestamp), direction: 'after' })}
                  onNextPage={() => setBeforeOrAfterDate({
                    date: new Date(auditData[auditData.length - 1].timestamp),
                    direction: 'before',
                  })}
                />
              </ListContextProvider>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );

};
