import { isEmail } from 'ra-core/dist/cjs/inference/assertions';

export const emailArrayValidation = (value: string[]) => {

  if (!value || value.length === 0) {

    return undefined;

  }
  const isValid = value.every((stringToValidate) => {

    return isEmail(stringToValidate);

  });

  return isValid ? undefined : 'resources.report-triggers.text.invalid_email';

};
