import { useRef, useState } from 'react';
import * as React from 'react';
import { useTranslate } from 'ra-core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { DeviceType } from '@x-guard/xgac-types/xgac';
import { useOutsideAlerter } from '../../../utils/useOutsideAlerter';
import { DeviceFilter } from '../../../lib/constants/customTypes';

const FilterOption = (props: {
  option: { label: string; value: string | number };
  selected: boolean;
  onAdd: (option: { label: string; value: string | number }) => void;
  onRemove: (option: { label: string; value: string | number }) => void;
}) => {

  return (
    <li onClick={(e) => {

      e.stopPropagation();
      if (props.selected) {

        props.onRemove(props.option);

      } else {

        props.onAdd(props.option);

      }

    }} className={props.selected ? 'selected' : ''}>
      <input type="checkbox" checked={props.selected}/>
      <span>{props.option.label}</span>
    </li>
  );

};
const FilterRow = (props: {
  label: string;
  buttonLabelAll: string;
  buttonLabelSome: string;
  open: boolean;
  setOpenFilter: () => void;
  onChange: (selectedOptions: number[] | string[]) => void;
  options: { label: string; value: string | number }[];
  selected: number[] | string[];
}) => {

  const listRef = useRef(null);
  const translate = useTranslate();
  return (
    <li className="device-filter-row" ref={listRef}>
      <div>
        <span className="device-filter-row-label">{translate(props.label)}</span>
        <button onClick={(e) => {

          e.stopPropagation();
          props.setOpenFilter();

        }}>
          <span>{translate(props.selected.length > 0 ? props.buttonLabelSome : props.buttonLabelAll)}</span>
          {props.open ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>}
        </button>
      </div>
      <ul style={props.open ? {} : { display: 'none' }}>
        {props.options.map((option) => (
          <FilterOption
            key={option.value}
            option={option}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            selected={props.selected.includes(option.value)}
            onAdd={() => {

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              props.onChange([...props.selected, option.value]);

            }}
            onRemove={() => {

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              props.onChange(props.selected.filter((selectedOption) => selectedOption !== option.value));

            }
            }
          />
        ))}
      </ul>
    </li>
  );

};
export const DeviceFilters = (props: {
  filters: DeviceFilter;
  onChange: (filters: DeviceFilter) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  possibleFloors: number[];
}) => {

  const translate = useTranslate();
  const filterRef = useRef(null);
  const [openFilter, setOpenFilter] = useState<null | 'floors' | 'deviceType' | 'activeStatus'>(null);
  const [innerFilters, setInnerFilters] = useState(props.filters);
  useOutsideAlerter(filterRef, () => props.setOpen(false));
  if (!props.open) {

    return null;

  }
  return (
    <div className="device-filter-div" ref={filterRef}>
      <div className="device-filter-div-inner">
        <ul onClick={() => {

          setOpenFilter(null);

        }}>
          <FilterRow
            label={'general.fields.position.address.floor'}
            buttonLabelAll={'resources.buttons.text.all_floors'}
            buttonLabelSome={'resources.buttons.text.floors_chosen'}
            open={openFilter === 'floors'}
            setOpenFilter={() => {

              if (openFilter === 'floors') {

                setOpenFilter(null);
                return;

              }
              setOpenFilter('floors');

            }}
            onChange={(selectedOptions: string[] | number[]) => setInnerFilters(
              { ...innerFilters, floors: selectedOptions.map((option) => parseInt(option as string, 10)) },
            )}
            options={props.possibleFloors.map((floor) => ({ label: `${translate('general.fields.position.address.floor')} ${floor}`, value: floor }))}
            selected={innerFilters.floors}
          />
          <FilterRow
            label={'resources.buttons.text.deviceType'}
            buttonLabelAll={'resources.buttons.text.all_devices'}
            buttonLabelSome={'resources.buttons.text.devices_chosen'}
            open={openFilter === 'deviceType'}
            setOpenFilter={() => {

              if (openFilter === 'deviceType') {

                setOpenFilter(null);
                return;

              }
              setOpenFilter('deviceType');

            }}
            onChange={(selectedOptions: string[] | number[]) => setInnerFilters(
              { ...innerFilters, deviceType: selectedOptions as any },
            )}
            options={[
              { label: translate('general.text.emergency_buttons'), value: DeviceType.Lora },
              { label: translate('general.text.gateways'), value: DeviceType.LoraGateway },
            ]}
            selected={innerFilters.deviceType || []}
          />
          <FilterRow
            label={'resources.buttons.text.active_status_label'}
            buttonLabelAll={'resources.buttons.text.all_active_status'}
            buttonLabelSome={'resources.buttons.text.active_status_chosen'}
            open={openFilter === 'activeStatus'}
            setOpenFilter={() => {

              if (openFilter === 'activeStatus') {

                setOpenFilter(null);
                return;

              }
              setOpenFilter('activeStatus');

            }}
            onChange={(selectedOptions: string[] | number[]) => setInnerFilters(
              { ...innerFilters, activeStatus: selectedOptions as any },
            )}
            options={[
              { label: translate('resources.buttons.text.active_status.active'), value: 'active' },
              { label: translate('resources.buttons.text.active_status.unpaid'), value: 'unpaid' },
              { label: translate('resources.buttons.text.active_status.demo'), value: 'demo' },
            ]}
            selected={innerFilters.activeStatus || []}
          />
        </ul>
        <div className="device-filter-buttons">
          <button onClick={() => {

            props.onChange({
              floors: [],
              deviceType: [],
              activeStatus: [],
            });
            props.setOpen(false);

          }}>{translate('resources.buttons.text.remove_filters')}</button>
          <button onClick={() => {

            props.onChange(innerFilters);
            props.setOpen(false);

          }}>{translate('resources.buttons.text.save_filters')}</button>
        </div>
      </div>
    </div>
  );

};
