import { useEffect, useState } from 'react';
import { RoleSubject } from '@x-guard/xgac-types/xgac';
import {
  Datagrid,
  DateField,
  ListContextProvider,
  TextField,
  useGetIdentity,

} from 'react-admin';
import {
  Autocomplete,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField as MuiTextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useTranslate } from 'ra-core';
import moment from 'moment';
import authProvider from '../../../utils/authProvider';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import { AuditOperations, reportDataProvider } from '../../../dataProviders/reportDataProvider';
import { ReportAuditLog } from '../../../lib/constants/customTypes';
import { AuditLogPagination } from '../components/pagination/AuditLogPagination';
import { AuthorField } from '../components/fields/AuditAuthorField';

const Grid = Grid2;

export const AuditLogPage = () => {

  const [auditData, setAuditData] = useState<ReportAuditLog[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [beforeOrAfterDate, setBeforeOrAfterDate] = useState<{
    date: Date;
    direction: 'before' | 'after';
  } >({
    date: new Date(),
    direction: 'before',
  });
  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const [resourceFilter, setResourceFilter] = useState<RoleSubject[]>([]);
  const [actionFilter, setActionFilter] = useState<AuditOperations[]>([]);
  const [exposeUsers, setExposeUsers] = useState<boolean>(false);
  const currentCustomer = getCurrentCustomer();
  const identity = useGetIdentity();
  const roles = authProvider.getRoles();
  const isDeveloperAdmin = roles?.includes('developer_admin');
  const isAdmin = authProvider.isAdmin();
  const translate = useTranslate();

  useEffect(() => {

    if (currentCustomer && currentCustomer.value !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer.value);
      setBeforeOrAfterDate({ date: new Date(), direction: 'before' });

    }

  }, [currentCustomer, identity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    setLoading(true);
    reportDataProvider.getAuditReport(
      beforeOrAfterDate.date,
      beforeOrAfterDate.direction,
      100,
      resourceFilter,
      actionFilter,
      exposeUsers,
    ).then((data: ReportAuditLog[]) => {

      if (data) {

        setAuditData(data);

      }
      setLoading(false);

    }).catch(() => {

      setLoading(false);

    });

  }, [lastSavedCustomer, beforeOrAfterDate, resourceFilter, actionFilter, exposeUsers]);

  if (!currentCustomer || !isDeveloperAdmin) {

    return null;

  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid sm={4}>
          <Card>
            <CardContent>
              <ToggleButtonGroup
                value={beforeOrAfterDate.direction}
                exclusive
                fullWidth
                color={'primary'}
                sx={{
                  marginBottom: '5px',
                }}
                onChange={(e, value) => setBeforeOrAfterDate({ date: beforeOrAfterDate.date, direction: value })}
              >
                <ToggleButton value={'before'}>Before</ToggleButton>
                <ToggleButton value={'after'}>After</ToggleButton>
              </ToggleButtonGroup>
              <MuiTextField
                type={'datetime-local'}
                value={moment(beforeOrAfterDate.date).format('YYYY-MM-DDTHH:mm')}
                fullWidth
                sx={{
                  marginBottom: '20px',
                }}
                onChange={(e) => setBeforeOrAfterDate({ date: new Date(e.target.value), direction: beforeOrAfterDate.direction })}
              />
              <Autocomplete
                options={Object.values(RoleSubject)}
                value={resourceFilter}
                onChange={(e, value) => setResourceFilter(value as RoleSubject[])}
                multiple
                sx={{
                  marginBottom: '20px',
                }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={translate('resources.auditlogs.text.resource_types')}/>
                )}
              />
              <Autocomplete
                options={Object.values(AuditOperations)}
                value={actionFilter}
                onChange={(e, value) => setActionFilter(value as AuditOperations[])}
                multiple
                sx={{
                  marginBottom: '20px',
                }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={translate('resources.auditlogs.text.operation')}/>
                )}
              />
              {isAdmin
              && <FormControlLabel
                control={<Switch checked={exposeUsers} onChange={(e) => setExposeUsers(e.target.checked)} />}
                label={translate('resources.auditlogs.text.expose_users')}
              />
              }
            </CardContent>
          </Card>
        </Grid>
        <Grid sm={8}>
          <Card>
            <ListContextProvider
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={{
                data: auditData,
              }}>
              <Datagrid isLoading={loading} data={auditData} resource="auditLogs">
                <DateField source='timestamp' label="general.text.timestamp" showTime={true} />
                <TextField source="subject" label="resources.auditlogs.text.subject"/>
                <AuthorField label={'resources.auditlogs.text.user'}/>
                <TextField source={'operation'} label={'resources.auditlogs.text.operation'}/>
              </Datagrid>
              <AuditLogPagination
                disabled={loading}
                onFirstPage={() => setBeforeOrAfterDate({ date: new Date(), direction: 'before' })}
                onPreviousPage={() => setBeforeOrAfterDate({ date: new Date(auditData[0].timestamp), direction: 'after' })}
                onNextPage={() => setBeforeOrAfterDate({
                  date: new Date(auditData[auditData.length - 1].timestamp),
                  direction: 'before',
                })}
              />
            </ListContextProvider>
          </Card>
        </Grid>
      </Grid>
    </>
  );

};
