import {
  Typography, AppBar, ListItemIcon, ListItemText, MenuItem,
} from '@mui/material';
import { useRefresh, useRedirect, useTranslate } from 'ra-core';
import {
  UserMenu, Logout, useUnselectAll, useNotify,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { useEffect } from 'react';
import * as packageDetails from '../../../package.json';
import { CustomLocaleMenuButton } from '../../components/locales/CustomLocaleMenuButton';
import { CustomLoadingIndicator } from '../../components/CustomLoadingIndicator';
import authProvider from '../../utils/authProvider';
import { MyUserSettings } from './MyUserSettings';
import ReactSelect from '../../lib/react-select/reactSelect';
import { BUILD_COMMIT_SHA, BUILD_REF_NAME } from '../../config';

export const MyAppBar = (props: any) => {

  const location = useLocation();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const resource = location.pathname.slice(1);
  const unselectAll = useUnselectAll(resource);
  const translate = useTranslate();
  const notify = useNotify();

  const isUUID = (string: string) => {

    return string.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

  };

  const changeCustomer = async (event: any) => {

    const url = window.location.pathname.split('/');
    let lastPart = url.pop();
    localStorage.setItem('lastSavedCustomer', JSON.stringify(event));
    sessionStorage.setItem('currentCustomer', JSON.stringify(event));
    unselectAll();

    if (!lastPart) {

      lastPart = '';

    }

    if (isUUID(lastPart)) {

      redirect(url.join('/'));
      return;

    }
    refresh();
    if (lastPart === 'live-map') {

      window.location.reload();

    }

    if (url.pop() === 'reports') {

      window.location.reload();

    }

  };

  const toggleCustomerAdminSimulation = () => {

    const isSimulating = sessionStorage.getItem('simulateCustomerAdmin');
    if (isSimulating) {

      sessionStorage.removeItem('simulateCustomerAdmin');

    } else {

      sessionStorage.setItem('simulateCustomerAdmin', 'true');

    }
    window.location.reload();

  };

  const defaultCustomer = () => {

    const sessionCustomer = sessionStorage.getItem('currentCustomer');
    const localCustomer = localStorage.getItem('lastSavedCustomer');

    const currentCustomer = sessionCustomer || localCustomer;
    if (!sessionCustomer && localCustomer) {

      sessionStorage.setItem('currentCustomer', localCustomer);

    }
    if (!currentCustomer) {

      const customerString = localStorage.getItem('customers');
      if (!customerString) return {};
      const customers = JSON.parse(customerString);
      sessionStorage.setItem('currentCustomer', JSON.stringify(customers[0]));
      return customers[0];

    }

    return JSON.parse(currentCustomer);

  };

  const redirectWithoutCustomerParam = () => {

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete('customer');
    redirect(newUrl.pathname + newUrl.search);

  };

  useEffect(() => {

    if (location.search) {

      const url = new URLSearchParams(location.search);
      const customer = url.get('customer');
      if (customer) {

        const customers = JSON.parse(localStorage.getItem('customers') ?? '');
        const selectedCustomer = customers.find((c: any) => c.value === customer);
        if (selectedCustomer) {

          changeCustomer(selectedCustomer).then(() => {

            redirectWithoutCustomerParam();
            window.location.reload();

          });

        } else {

          notify('general.text.customer_not_found', { type: 'warning' });
          redirectWithoutCustomerParam();

        }

      }

    }

  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppBar
      elevation={0}
      sx={{
        '& .RaAppBar-title': {
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
        padding: '5px 15px',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
      color="secondary"
      {...props}
      title={null}
    >
      <div style={{ display: 'flex', flexDirection: 'row', height: 38 }}>
        <img src="/assets/img/logo.svg" height="35" alt="X-Guard" style={{ verticalAlign: 'middle' }}/>
        <Typography
          variant="h6"
          color="inherit"
          sx={{
            display: 'inline-block',
            lineHeight: '35px',
            position: 'relative',
            top: '4px',
            paddingLeft: '15px',
          }}
        >Management Dashboard</Typography>
        <Typography
          variant="subtitle2"
          color="#EFEFEF"
          sx={{ display: 'inline-block', lineHeight: '47px', paddingLeft: '5px' }}
        >
          {BUILD_REF_NAME === 'develop'
            ? <a target={'_blank'} href={`https://github.com/X-Guard/xgac-r-management-dashboard/commit/${BUILD_COMMIT_SHA}`} rel="noreferrer" style={{ color: 'white' }}>{`${BUILD_COMMIT_SHA}`.substring(0, 7)}</a>
            : packageDetails.version}
        </Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>
        <Typography variant="h6" id="react-admin-title" />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', height: 38 }}>
        <div style={{ width: 300, marginRight: '1em', marginTop: 4 }}>
          {(localStorage.getItem('customers') && JSON.parse(localStorage.getItem('customers') ?? '').length > 1)
            && <ReactSelect
              onChange={changeCustomer}
              options={JSON.parse(localStorage.getItem('customers') ?? '') || []}
              defaultValue={defaultCustomer}
              isDisabled={(JSON.parse(localStorage.getItem('customers') ?? '') || []).length <= 1}
            />
          }
        </div>
        <UserMenu {...props}>
          {(authProvider.isAdminSimulation()
            && <>
              <MyUserSettings/>
              <MenuItem sx={{ color: 'text.secondary' }} onClick={toggleCustomerAdminSimulation}>
                <ListItemIcon>
                  <LockIcon/>
                </ListItemIcon>
                <ListItemText>{translate(authProvider.isAdmin()
                  ? 'general.text.simulate_customer_admin'
                  : 'general.text.exit_role_simulation')}
                </ListItemText>
              </MenuItem>
            </>)}
          <Logout/>
        </UserMenu>
        <CustomLocaleMenuButton/>
        <CustomLoadingIndicator/>
      </div>
    </AppBar>
  );

};
