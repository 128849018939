import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslate } from 'ra-core';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';

export const MyUserSettings = () => {

  const translate = useTranslate();
  const contextString = localStorage.getItem('context');
  if (!contextString) return null;

  const context = JSON.parse(contextString);

  const toUrl = `/users/${context.user._id}`;

  return (
    <Link to={toUrl}>
      <MenuItem sx={{ color: 'text.secondary' }}>
        <ListItemIcon>
          <PersonIcon/>
        </ListItemIcon>
        <ListItemText>{translate('general.text.my_user_settings')}</ListItemText>
      </MenuItem>
    </Link>
  );

};
